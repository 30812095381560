/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"

import { useLinkData } from "../hooks/use-link-data"

export const TransformLinkUri = (to, target, language) => {
  const normalizePath = (path) => path.replace(/\/?(\?|#|$)/, "/$1") // Trailing slash

  let internal = internalCheck(to, target)
  let seoLinks = useLinkData(to, language)

  if (internal) {
    to =
      to === "/home" || to === "home"
        ? "/"
        : to && to.startsWith("/")
          ? to
          : `/${to}`

    to =
      to === "/" + language + "/home" || to === language + "/home"
        ? "/" + language + "/"
        : to

    if (seoLinks[to.substr(1)]) {
      to = `/${seoLinks[to.substr(1)]}`

      if (to === "//") {
        to = "/"
      }
    }
    if (to.startsWith("//")) {
      to = to.slice(1)
    }
    to = normalizePath(to)
  }

  return to
}

let internalCheck = (to, target) => {
  let internal = !/(http)/.test(to)

  to = typeof to === "string" ? to : ""
  internal =
    internal &&
    !target &&
    !to.startsWith("mailto:") &&
    !to.startsWith("tel:") &&
    !to.startsWith("#")

  return internal
}

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  target,
  language,
  ...other
}) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  let internal = internalCheck(to, target)

  let internalLink = TransformLinkUri(to, target, language)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={internalLink}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} {...(target ? { target: target } : {})} {...other}>
      {children}
    </a>
  )
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.string,
}

export default Link
