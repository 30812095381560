/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { createRef, useState } from "react"

const Select = ({
  label,
  name,
  placeholder,
  required,
  options,
  flex,
  visible,
}) => {
  const [value, setValue] = useState(options[0] ? options[0].value : null)
  const [error, setError] = useState(false)
  const inputEl = createRef()
  const optionList = (options) => {
    if (!options) {
      options = []
    }
    let list = []
    options.forEach(function (option) {
      if (option.name) {
        list.push({
          value: option.value,
          name: option.name,
        })
      } else {
        list.push({
          value: option.value,
          name: option.value,
        })
      }
    })
    return list
  }

  const change = function (event) {
    setError(false)
    setValue(event.target.value)
  }

  const showError = () => {
    setError(true)
    inputEl.current.focus()
    return
  }

  const labelValueInput = (
    <input type="hidden" name={"label----" + name} value={label} />
  )

  return (
    <div
      className="formField"
      sx={{
        variant: "form.formField",
        mt: 3,
        flex: flex,
        overflow: "hidden",
        border: error ? "solid #ff3454 2px" : "",
      }}
    >
      {labelValueInput}
      <label htmlFor={name} sx={{ opacity: value ? "0" : "1", px: 2 }}>
        {label} {required ? " * " : ""}
      </label>
      <select
        sx={{
          variant: "form.input",
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
          px: 3,
        }}
        ref={inputEl}
        onChange={change}
        onInvalid={showError}
        name={name}
        id={name}
        placeholder={placeholder}
        required={required && visible}
      >
        {optionList(options).map((option, idx) => (
          <option
            key={idx}
            sx={{
              backgroundColor: "#fff",
            }}
            value={option.value}
          >
            {option.name}
          </option>
        ))}
      </select>
      <div
        sx={{
          variant: "form.arrow",
        }}
      ></div>
    </div>
  )
}

Select.defaultProps = {
  visible: true,
  options: "",
}

Select.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
  flex: PropTypes.string,
  visible: PropTypes.bool,
}

export default Select
