/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Text from "./text"
import Image from "../image"

const ImageBox = ({ filename, alt, headline, children }) => {
  return (
    <div
      sx={{
        color: "text",
        width: "100%",
        height: "100%",
        display: "flex",
      }}
    >
      <div
        sx={{
          flex: 1,
          borderRight: "solid 1px #000",
        }}
      >
        <Image
          image={filename}
          alt={alt}
          objectFit="cover"
          style={{
            display: "block",
            height: "100%",
          }}
        />
      </div>
      <div
        sx={{
          flex: 1,
        }}
      >
        <Text headline={headline}>
          <p>{children}</p>
        </Text>
      </div>
    </div>
  )
}

ImageBox.propTypes = {
  filename: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default ImageBox
