/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { breakpointNumbers, fullSize } from "../theme"

const ResponsiveImage = ({ filename, alt, sizes, objectFit, style }) => {
  const optimizedUrl = `${filename}/m/`
  const breakpointsIncludingFullsize = [...breakpointNumbers, fullSize]
  const srcSet = breakpointsIncludingFullsize.map(
    (w) => `${optimizedUrl}${w}x0 ${w}w`
  )
  const sizesOutput = sizes.map((s, i, sizes) =>
    sizes.length === i + 1 // last item
      ? s
      : `(max-width: ${breakpointsIncludingFullsize[i]}px) ${s}`
  )

  return (
    <img
      src={optimizedUrl}
      srcSet={srcSet}
      sizes={sizesOutput}
      alt={alt}
      sx={{ objectFit: objectFit, maxWidth: "100%", ...style }}
    />
  )
}

ResponsiveImage.propTypes = {
  filename: PropTypes.string.isRequired,
  alt: PropTypes.string,
  objectFit: PropTypes.string,
  style: PropTypes.object,
}

ResponsiveImage.defaultProps = {
  sizes: ["100vw", "100vw", "100vw", "100vw"],
}

export default ResponsiveImage
