/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, useEffect } from "react"
import PropTypes from "prop-types"

const Checkbox = ({
  label,
  name,
  value,
  checked,
  required,
  options,
  visible,
}) => {
  const [isChecked, setIsChecked] = useState(!!checked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  useEffect(() => {
    setIsChecked(isChecked)
  }, [isChecked])

  const checkbox = () => (
    <div>
      <input
        sx={{
          variant: "checkbox",
        }}
        type="checkbox"
        value={value}
        name={name}
        id={name}
        defaultChecked={isChecked ? "checked" : ""}
        required={required && visible}
        {...options}
      />
      <span
        className="mark"
        sx={{
          variant: "form.mark",
        }}
      ></span>
      <span
        sx={{
          variant: "form.checkmark",
          display: "none",
        }}
      ></span>
    </div>
  )
  const labelInput = (
    <label
      htmlFor={name}
      sx={{
        variant: "checkbox.label",
        display: "block",
        position: "relative",
        zIndex: 5,
        "& p": {
          display: "inline-block",
        },
      }}
    >
      {label}
    </label>
  )

  const labelValueInput = (
    <input type="hidden" name={"label----" + name} value={label.props.source} />
  )

  return (
    <div
      sx={{
        position: "relative",
        py: 2,
        flex: 1,
      }}
    >
      {checkbox()}

      {labelInput}
      {labelValueInput}
    </div>
  )
}

Checkbox.defaultProps = {
  visible: true,
}

Checkbox.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.object,
  visible: PropTypes.bool,
}

export default Checkbox
