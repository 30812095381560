/** @jsx jsx */
import { useContext } from "react"
import { jsx, useThemeUI } from "theme-ui"
import PropTypes from "prop-types"

import ModeContext from "../context/mode-context"
import Link from "./link"
import Image from "./image"
import { ab } from "../theme"

const ContentPageTwoColumn = ({
  image,
  imageAlt,
  heading,
  headlineName,
  children,
  link,
  linkText,
  linkTarget,
  disableHyphens,
  mode,
  reverse,
  currentLanguage,
  imagePaddingTop,
  headingTop,
  inMiddle,
  isBlogChild,
  date,
}) => {
  const { mode: globalMode } = useContext(ModeContext)
  const { rawColors: colors } = useThemeUI().theme
  let localeDate = new Date(date).toLocaleDateString("de-DE")

  let blogDate

  const convertToUSDate = (germanDate) => {
    const [day, month, year] = germanDate.split(".")
    const usDate = `${month}/${day}/${year}`
    return usDate
  }
  if (process.env.GATSBY_CHANGE_DATE_FORMAT_US === "true") {
    localeDate = convertToUSDate(localeDate)
  }

  const modeKey = mode ? mode : globalMode

  if (isBlogChild) {
    blogDate = (
      <span
        sx={{
          display: date ? "block" : "none",
        }}
      >
        {localeDate}
      </span>
    )
  }

  let hyphensStyling = {}
  if (disableHyphens) {
    hyphensStyling = {
      hyphens: "none",
      wordWrap: "normal",
    }
  }
  return (
    <div
      sx={{
        variant: "spacingContainer",
      }}
    >
      {headingTop && (
        <h2
          sx={{
            pb: 5,
          }}
        >
          {" "}
          {headingTop}{" "}
        </h2>
      )}
      <div
        sx={{
          display: "flex",
          flexDirection: ab({
            _: "column",
            md: reverse ? "row-reverse" : "row",
          }),
        }}
      >
        <div
          sx={{
            flex: 1,
            pt: imagePaddingTop ? 5 : 0,
          }}
        >
          <Image
            image={
              typeof image === "object"
                ? {
                    ...image,
                    sizes: "(min-width: 824px) 616px, 100vw",
                  }
                : image
            }
            sizes={["100vw", "100vw", "50vw", "50vw"]}
            alt={imageAlt}
            style={{ width: "100%", marginBottom: ab({ _: 3, md: 0 }) }}
          />
        </div>
        <div
          sx={{
            alignSelf: inMiddle ? "center" : "",
            flex: 1,
            py: 2,
            paddingLeft: ab({ _: 0, md: reverse ? 0 : 5 }),
            paddingRight: ab({ _: 0, md: reverse ? 5 : 0 }),
          }}
        >
          {blogDate}
          <h2
            id={headlineName}
            sx={{
              p: 0,
              pb: "0 !important",
              m: 0,
              ...hyphensStyling,
            }}
          >
            {heading}
          </h2>
          {children}
          {linkText && (
            <div
              sx={{
                paddingTop: 4,
              }}
            >
              {mode === "gutesiegel" ? (
                <Link
                  to={link}
                  target={linkTarget}
                  language={currentLanguage}
                  role="button"
                  tabIndex={0}
                  sx={{
                    color: "text",
                    textDecoration: "none",
                    float: "right",
                    "&:after": {
                      content: "''",
                      width: "100%",
                      height: 1,
                      backgroundColor: "#5a5d5f",
                      display: "block",
                      transition: "200ms ease-out",
                    },
                    "&:hover": {
                      "&:after": {
                        width: "0%",
                      },
                    },
                  }}
                >
                  {linkText}
                </Link>
              ) : (
                <Link
                  to={link}
                  target={linkTarget}
                  language={currentLanguage}
                  sx={{
                    variant: "styles.ghostButton",
                    border: `solid 1px`,
                    color: modeKey
                      ? colors.modes[modeKey].primary
                      : colors.modes["group"].primary,
                    px: 4,
                    py: 2,
                    "&:hover": {
                      bg: modeKey
                        ? colors.modes[modeKey].primary
                        : colors.modes["group"].primary,
                      color: "#fff",
                    },
                  }}
                >
                  {linkText}
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

ContentPageTwoColumn.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  imageAlt: PropTypes.string,
  heading: PropTypes.string.isRequired,
  headlineName: PropTypes.string,
  children: PropTypes.node,
  link: PropTypes.string,
  linkText: PropTypes.string,
  linkTarget: PropTypes.string,
  mode: PropTypes.oneOf([
    "",
    "pharma",
    "food",
    "environment",
    "career",
    "construction",
    "medical",
    "lifeScience",
    "agroChemical",
    "sustainabilityServices",
    "none",
  ]),
  reverse: PropTypes.bool,
  disableHyphens: PropTypes.bool,
  inMiddle: PropTypes.bool,
  headingTop: PropTypes.string,
}

ContentPageTwoColumn.defaultProps = {
  mode: "",
  reverse: false,
  disableHyphens: false,
}

export default ContentPageTwoColumn
