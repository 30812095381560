/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

const Iframe = ({ title, src, width, height }) => (
  <div
    sx={{
      variant: "spacingContainer",
    }}
    className="iframe-container"
  >
    <iframe
      title={title}
      src={src}
      width={width}
      height={height}
      frameborder="0"
    ></iframe>
  </div>
)

Iframe.propTypes = {
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
}

Iframe.defaultProps = {
  width: "100%",
  height: "500",
}

export default Iframe
