/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect } from "react"
import PropTypes from "prop-types"

import { ab } from "../theme"

const JobsTable = ({ anchorName, listingId, children }) => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src =
      "https://static.b-ite.com/jobs-api/loader-v1/api-loader-v1.min.js"
    script.async = false

    document.body.appendChild(script)
  }, [])

  return (
    <section
      id={anchorName ? anchorName : ""}
      sx={{
        bg: "secondaryBackground",
        variant: "guc.jobsTable",
      }}
    >
      <div
        sx={{
          variant: "innerSpacingContainer",
          pt: ab({ _: 3 }),
        }}
      >
        <h2>Offene Stellen</h2>
        <div
          sx={{
            display: children.length ? "block" : "none",
            overflow: "auto",
          }}
        >
          <table
            sx={{
              variant: "jobsTable",
            }}
          >
            <tr>
              <th>Stellenbezeichnung</th>
              <th>Ort</th>
              <th>&nbsp;</th>
            </tr>
            {children}
          </table>
        </div>
        <div data-bite-jobs-api-listing={listingId}></div>
      </div>
    </section>
  )
}

JobsTable.propTypes = {
  anchorName: PropTypes.string,
  listingId: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default JobsTable
