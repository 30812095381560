/** @jsx jsx */
import { jsx } from "theme-ui"

const ConstructionIcon = ({ style = {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199.52 194">
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1">
        <path
          id="Rechteck_65"
          data-name="Rechteck 65"
          class="cls-1"
          style={style}
          d="M74.08 119.92h55.19v19.32H74.08z"
        />
        <path
          id="Rechteck_66"
          data-name="Rechteck 66"
          class="cls-1"
          style={style}
          d="M74.08 174.68h55.19V194H74.08z"
        />
        <g id="Gruppe_183" data-name="Gruppe 183">
          <path
            id="Pfad_76"
            data-name="Pfad 76"
            class="cls-1"
            style={style}
            d="M165.73 24.92a99.77 99.77 0 0 0-145.86 134.6V99.34a.9.9 0 0 1 0-.28.88.88 0 0 0 0-.32l52.55-43.23a9.46 9.46 0 0 1 12 0l52.47 43.23a1.09 1.09 0 0 1 .12.56v47.18h-31.53v19.34H137V192.34a99.81 99.81 0 0 0 28.73-167.42"
          />
          <path
            id="Rechteck_67"
            data-name="Rechteck 67"
            class="cls-1"
            style={style}
            d="M47.51 146.48h50.72v19.32H47.51z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default ConstructionIcon
