import { useStaticQuery, graphql } from "gatsby"

const useContext = () => {
  const { sitePlugin } = useStaticQuery(graphql`
    query ContextQuery {
      sitePlugin(name: { eq: "gatsby-theme-gba" }) {
        pluginOptions
      }
    }
  `)

  return sitePlugin.pluginOptions
}

export { useContext }
