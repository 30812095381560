/** @jsx jsx */
import { jsx } from "theme-ui"
import { createRef, useState } from "react"
// import Picker from "react-datepicker"
import {
  default as Picker,
  registerLocale,
  setDefaultLocale,
} from "react-datepicker"
import PropTypes from "prop-types"
import de from "date-fns/locale/de"
import "react-datepicker/dist/react-datepicker.css"

const DatePicker = ({
  label,
  name,
  placeholder,
  required,
  options,
  flex,
  currentLanguage,
  visible,
  showTimeSelect,
  excludeDates,
  businessHours,
}) => {
  const hasBusinessHours = () => {
    let filterDays = false
    for (var i = 0; i < businessHours.length; i++) {
      filterDays = filterDays || businessHours[i].open
    }
    if (!filterDays) {
      return false
    }
    return true
  }

  const filterWeekdays = (date) => {
    const day = date.getDay()

    if (!hasBusinessHours()) {
      return true
    }
    return businessHours[day].open
  }
  const [error, setError] = useState(false)
  const [open, setOpen] = useState(false)
  let startDate = new Date()
  let maxDate = new Date()
  let day = startDate.getDay()
  startDate.setHours(hasBusinessHours() ? businessHours[day].minHour : 0)
  startDate.setMinutes(hasBusinessHours() ? businessHours[day].minMinute : 0)

  maxDate.setHours(hasBusinessHours() ? businessHours[day].maxHour : 23)
  maxDate.setMinutes(hasBusinessHours() ? businessHours[day].maxMinute : 0)

  const [date, setDate] = useState()
  const [maxTime, setMaxTime] = useState(maxDate)
  const [minTime, setMinTime] = useState(startDate)

  const handleDatePickerChange = (newDate) => {
    setError(false)
    setDate(isInBusinessHours(newDate))
  }
  const isInBusinessHours = (dateToCheck) => {
    if (!hasBusinessHours()) {
      return dateToCheck
    }

    let minDate = new Date(dateToCheck.getTime())
    let day = dateToCheck.getDay()
    minDate.setHours(businessHours[day].minHour)
    minDate.setMinutes(businessHours[day].minMinute)
    setMinTime(minDate)

    let maxDate = new Date(dateToCheck.getTime())
    maxDate.setHours(businessHours[day].maxHour)
    maxDate.setMinutes(businessHours[day].maxMinute)
    setMaxTime(maxDate)

    if (dateToCheck < minDate || dateToCheck > maxDate) {
      return minDate
    }
    return dateToCheck
  }

  const openPicker = (e) => {
    if (e.target === clickEl.current) {
      setOpen(!open)
    }
  }
  const clickEl = createRef()

  const showError = () => {
    setError(true)
    return
  }
  if (currentLanguage === "de") {
    registerLocale("de", de)
    setDefaultLocale("de")
  }
  let labelElement = ""

  if (label) {
    labelElement = (
      <label
        sx={{
          display: label ? "block" : "none",
          py: label ? 1 : 0,
        }}
        htmlFor={name}
      >
        {label}
        {required && label ? " * " : ""}
      </label>
    )
  }

  const labelValueInput = (
    <input
      type="hidden"
      name={"label----" + name}
      value={label ? label : name}
    />
  )

  let excludedDates = []

  for (let i = 0; i < excludeDates.length; i++) {
    excludedDates.push(new Date(excludeDates[i].date))
  }

  return (
    <div
      sx={{
        marginTop: 3,
        flex: flex,
      }}
    >
      {labelValueInput}
      {labelElement}
      <div
        className="formField"
        sx={{
          variant: "form.formField",
          border: error ? "solid #ff3454 2px" : "",
          "& .react-datepicker-popper": {
            zIndex: 20,
          },
          "&:before": {
            zIndex: "1",
            position: "absolute",
            width: "100%",
            height: "100%",
            opacity: 0,
            content: "''",
          },
          "& .react-datepicker__today-button": {
            borderBottomRightRadius: "5px",
            borderBottomLeftRadius: "5px",
          },
          "& .react-datepicker__time-container--with-today-button": {
            right: "-85px",
            top: "-1px",
          },
        }}
        onClick={openPicker}
        ref={clickEl}
      >
        <Picker
          sx={{
            variant: "form.input",
          }}
          todayButton={
            <div
              className="close-calender"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setOpen(false)
              }}
            >
              Ok
            </div>
          }
          open={open}
          onClickOutside={() => setOpen(false)}
          name={name}
          id={name}
          selected={date}
          locale={currentLanguage}
          closeOnScroll={(e) => {
            if (e.target === document) {
              setOpen(false)
              return true
            }
          }}
          excludeDates={excludedDates}
          maxTime={maxTime}
          minTime={minTime}
          filterDate={filterWeekdays}
          showTimeSelect={showTimeSelect}
          dateFormat={showTimeSelect ? "MMMM d, yyyy H:mm" : "MMMM d, yyyy"}
          placeholderText={
            placeholder && required ? placeholder + " * " : placeholder
          }
          required={required && visible}
          customInput={<input type="text" onInvalid={showError} />}
          {...options}
          onChange={handleDatePickerChange}
        />
      </div>
    </div>
  )
}

DatePicker.defaultProps = {
  visible: true,
  showTimeSelect: false,
  excludeDates: [],
  businessHours: [],
}

DatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.object,
  flex: PropTypes.string,
  visible: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  excludeDates: PropTypes.array,
  businessHours: PropTypes.array,
}

export default DatePicker
