/** @jsx jsx */
import { jsx } from "theme-ui"

const LeftIcon = ({ style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 500 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
  >
    <polygon
      sx={style}
      points="335.9,68.3 345.8,78.1 173.9,250 345.8,421.9 335.9,431.7 154.2,250 "
    />
  </svg>
)

export default LeftIcon
