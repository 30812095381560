/** @jsx jsx */
import { useEffect, useState } from "react"
import { useContext } from "../hooks/use-context"
import TagManager from "react-gtm-module"

const cookieBot = (noAnalytics, language) => {
  const { context } = useContext()

  let gmtId = process.env.GATSBY_GMT_ID
    ? process.env.GATSBY_GMT_ID
    : "GTM-PNWXGRM"

  const tagManagerArgs = {
    gtmId: gmtId,
  }

  const doAnalytics = () => {
    if (!noAnalytics && context !== "deploy-preview") {
      TagManager.initialize(tagManagerArgs)
    }
  }

  const delete_cookie = function (name) {
    document.cookie = name + "=;Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;"
  }

  useEffect(() => {
    if (window !== "undefined") {
      window.addEventListener(
        "CookiebotOnAccept",
        function (e) {
          if (Cookiebot.consent?.marketing) {
            doAnalytics()
          }
        },
        false
      )
    }
  })

  useEffect(() => {
    if (window !== "undefined") {
      window.addEventListener(
        "CookiebotOnDecline",
        function (e) {
          if (!Cookiebot.consent?.marketing) {
            delete_cookie("_gid")
            delete_cookie("_ga")
            delete_cookie("_dc_gtm_UA-130711147-3")
          }
        },
        false
      )
    }
  })

  useEffect(() => {
    if (window !== "undefined") {
      setTimeout(() => {
        if (Cookiebot.consent?.marketing) {
          doAnalytics()
        }
      }, 1000)
    }
  })

  useEffect(() => {
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    if (iOS) {
      document.body.scroll(0, 0)
    }
  })
}

export default cookieBot
