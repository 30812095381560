/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { FormName } from "./formContext"

const FormDetails = ({ heading, children, detailsBtnText }) => {
  const [details, setDetails] = useState([])
  const [toggle, setToggle] = useState(false)
  const formName = useContext(FormName)
  const [form] = useState(
    typeof document !== "undefined" && document.getElementsByName
      ? document.getElementsByName(formName)
      : null
  )

  useEffect(() => {
    setInterval(() => {
      const tempDetails = []
      for (let item of form) {
        let elements = item.elements
        for (let el of elements) {
          if (el.name !== "recipient") {
            tempDetails.push({
              type: el.type,
              name: el.name,
              value: el.value,
              checked: el.checked,
            })
          }
        }
      }
      setDetails(tempDetails)
    }, 500)
  }, [form])

  if (!toggle) {
    details.length = 3
  }

  function showDetails(details) {
    return details.map((field, idx) => {
      let value = ""
      let name = ""
      if (field.type === "hidden") {
        return ""
      }
      if (field.type === "checkbox" && field.checked) {
        value = field.value
        name = field.name
      } else if (field.type === "radio" && field.checked) {
        value = field.value
        name = field.name
      } else {
        value = ""
        name = ""
      }
      if (field.type !== "checkbox" && field.type !== "radio") {
        value = field.value
        name = field.name
      }

      if (!value) {
        return ""
      }
      if (name === "recipient") {
        return ""
      }
      return <div key={idx}> {name ? `${name} : ${value}` : ""}</div>
    })
  }
  return (
    <div id="form-details">
      <h4
        sx={{
          my: 2,
          p: 0,
        }}
      >
        {heading}
      </h4>
      <div>{children}</div>
      <div
        sx={{
          lineHeight: 1.4,
          "& ::first-letter": {
            textTransform: "capitalize",
          },
        }}
      >
        {showDetails(details)}
      </div>
      <div
        sx={{
          textAlign: "right",
          paddingRight: 3,
          cursor: "pointer",
          display: toggle ? "none" : "block",
        }}
        role="button"
        tabIndex="0"
        onKeyPress={() => setToggle(!toggle)}
        onClick={() => setToggle(!toggle)}
      >
        {detailsBtnText}
        <span
          sx={{
            variant: "form.arrow",
            width: 8,
            height: 8,
            position: "relative",
            right: 0,
            borderWidth: "1px",
            marginLeft: 10,
          }}
        />
      </div>
    </div>
  )
}

FormDetails.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.node,
  detailsBtnText: PropTypes.string.isRequired,
}

export default FormDetails
