/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Button from "../button"
const FormSubmit = ({ buttonText, captchaRequired }) => {
  return (
    <p className="form-submit-button">
      <Button type="submit" buttonText={buttonText} />
    </p>
  )
}

FormSubmit.propTypes = {
  buttonText: PropTypes.string.isRequired,
}

export default FormSubmit
