/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { ab } from "../../theme"
import { useState, useEffect, useRef, useContext } from "react"
import CaptchaContext from "../../context/captchaContext"

const Captcha = ({
  label,
  name,
  type,
  value,
  placeholder,
  required,
  captcha,
  wrongAnswer,
  children,
}) => {
  let { setCaptcha } = useContext(CaptchaContext)
  let refInput = useRef(0)
  const operators = ["+", "-", "*"]

  const [wrongAnswerText, setWrongAnswerText] = useState("")
  const [randomNum1, setrandomNum1] = useState(Math.ceil(Math.random() * 10))
  const [randomNum2, setrandomNum2] = useState(Math.ceil(Math.random() * 10))
  const [operator, setoperator] = useState(
    Math.floor(Math.random() * operators.length)
  )
  const [randomQuestion, setRandomQuestion] = useState(
    randomNum1 + operators[operator] + randomNum2
  )

  useEffect(() => {
    setRandomQuestion(randomNum1 + operators[operator] + randomNum2)
  }, [randomNum1, randomNum2, operator])

  useEffect(() => {
    const inputRef = refInput.current
    let form = refInput.current.closest("form")

    function checkAnswer() {
      let inputAnswer = inputRef.value

      if (eval(randomQuestion) === Number(inputAnswer)) {
        setCaptcha(true)
        setWrongAnswerText("")
      } else {
        setWrongAnswerText(wrongAnswer)
        setCaptcha(false)
      }
    }
    form.addEventListener("submit", checkAnswer)
  }, [refInput])

  return (
    <div
      className="captchaDiv"
      sx={{
        py: 3,
      }}
    >
      <label htmlFor={name}>
        {children}
        {required ? " * " : ""}
      </label>

      <div
        sx={{
          display: ab({ _: "flex", md: "flex" }),
        }}
      >
        <div
          sx={{
            px: 4,
            my: ab({ _: 3, md: 2 }),
            fontSize: 24,
            flex: 3,
            pt: 3,
            textAlign: "center",
          }}
        >
          {randomQuestion}
          <span
            sx={{
              pl: "20px",
            }}
          >
            =
          </span>
        </div>
        <input
          ref={refInput}
          sx={{
            variant: "form.formField",
            border: 0,
            px: 2,
            my: 2,
            color: "text",
            flex: 3,
            width: "100%",
          }}
          id={name}
          name={name}
          type={type}
          value={value}
          placeholder={placeholder}
          required="required"
        />
      </div>
      <p
        sx={{
          textAlign: "right",
          p: 0,
          mt: 0,
          pr: 3,
          color: "#ff3454",
        }}
      >
        {wrongAnswerText}
      </p>
    </div>
  )
}
Captcha.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  captcha: PropTypes.node.isRequired,
  children: PropTypes.node,
}

export default Captcha
