/** @jsx jsx */
import { useContext } from "react"
import { jsx, useThemeUI } from "theme-ui"
import PropTypes from "prop-types"

import ModeContext from "../context/mode-context"
import Link from "./link"
import { ab } from "../theme"

const Button = ({
  buttonText,
  link,
  linkTarget,
  download,
  style,
  type,
  onClick,
  language,
  padding,
  align,
  mode,
}) => {
  const { mode: globalMode } = useContext(ModeContext)
  const { rawColors: colors } = useThemeUI().theme

  const modeKey = mode ? mode : globalMode

  if (link) {
    return (
      <div
        sx={{
          textAlign: align ? align : "left",
          pr: "35px",
          pl: ab({ _: 3, md: 4 }),
        }}
      >
        <Link
          to={link}
          target={linkTarget}
          download={download}
          language={language}
          sx={{
            p: padding ? 4 : 0,
          }}
        >
          <button
            sx={{
              variant: "styles.ghostButton",
              px: 4,
              py: 2,
              color: modeKey ? colors.modes[modeKey].primary : "#000",
              borderColor: modeKey ? colors.modes[modeKey].primary : "",
              "&:hover": {
                bg: modeKey ? colors.modes[modeKey].primary : "",
                color: "#fff",
              },
              ...style,
            }}
          >
            {buttonText}
          </button>
        </Link>
      </div>
    )
  } else {
    return (
      <button
        type={type}
        onClick={onClick}
        sx={{
          variant: "styles.ghostButton",
          px: 4,
          py: 2,
          ...style,
        }}
      >
        {buttonText}
      </button>
    )
  }
}

Button.propTypes = {
  buttonText: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkTarget: PropTypes.string,
  download: PropTypes.bool,
  style: PropTypes.object,
  type: PropTypes.string,
  onClick: PropTypes.func,
  padding: PropTypes.bool,
  align: PropTypes.string,
  mode: PropTypes.oneOf([
    "",
    "pharma",
    "food",
    "environment",
    "career",
    "construction",
    "medical",
    "lifeScience",
    "agroChemical",
    "sustainabilityServices",
    "none",
  ]),
}

Button.defaultProps = {
  download: false,
  style: {},
}

export default Button
