/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { ab } from "../theme"
import { Fragment } from "react"

const PageTeasers = ({ children, heading, anchorName }) => {
  let headingElement

  if (heading) {
    headingElement = (
      <div>
        <h2
          sx={{
            fontWeight: 1,
            mt: 3,
            mb: 0,
            color: "text",
          }}
        >
          {heading}
        </h2>
      </div>
    )
  }
  return (
    <div sx={{ variant: "spacingContainer" }}>
      {headingElement}
      <div
        className="page-teasers"
        id={anchorName ? anchorName : ""}
        sx={{
          display: ab({ _: "block", md: "inline-grid" }),
          gridTemplateColumns: "33.3% 33.3% 33.3%",
          gridColumnGap: ab({ _: 0, md: 20 }),
          width: "100%",
          variant: "guc.pageTeasers",
        }}
      >
        <Fragment>{children}</Fragment>
      </div>
    </div>
  )
}

PageTeasers.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string,
}

export default PageTeasers
