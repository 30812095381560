/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { GatsbyImage } from "gatsby-plugin-image"

import ResponsiveImage from "./responsive-image"

const Image = ({
  image,
  alt,
  sizes,
  style,
  objectFit,
  imgStyle,
  placeholderStyle,
}) => {
  let imageElement

  if (typeof image === "object") {
    imageElement = (
      <GatsbyImage
        image={image}
        alt={alt}
        sx={style}
        objectFit={objectFit}
        imgStyle={imgStyle}
        placeholderStyle={placeholderStyle}
      />
    )
  } else {
    imageElement = (
      <ResponsiveImage
        filename={image}
        alt={alt}
        sizes={sizes}
        objectFit={objectFit || "cover"}
        style={style}
      />
    )
  }

  return imageElement
}

Image.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  alt: PropTypes.string.isRequired,
  sizes: PropTypes.array,
  style: PropTypes.object,
  objectFit: PropTypes.string,
  imgStyle: PropTypes.object,
  placeholderStyle: PropTypes.object,
}

export default Image
