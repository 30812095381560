/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { ab } from "../theme"
import Markdown from "react-markdown"
import { TransformLinkUri } from "./link"

const LkfNewsTeaser = ({ heading, date, currentLanguage, children }) => (
  <div
    sx={{
      mb: 5,
    }}
    className="lkf-news-teaser"
  >
    <span
      sx={{
        display: "inline",
        padding: 2,
        bg: "#E6E6E6",
        letterSpacing: "1px",
      }}
    >
      {date}
    </span>
    <div
      sx={{
        px: ab({ _: 0, md: 3 }),
      }}
    >
      <h3
        sx={{
          mb: 0,
          lineHeight: 1.2,
        }}
      >
        {heading}
      </h3>
      <div
        sx={{
          mt: 2,
        }}
      >
        <Markdown
          urlTransform={(uri) => {
            return TransformLinkUri(uri, "", currentLanguage)
          }}
        >
          {children}
        </Markdown>
      </div>
    </div>
  </div>
)
LkfNewsTeaser.prototype = {
  heading: PropTypes.string.isRequired,
  date: PropTypes.date,
  newsLink: PropTypes.string,
  linkTarget: PropTypes.string,
  children: PropTypes.object,
}

export default LkfNewsTeaser
