/** @jsx jsx */
import { useContext } from "react"
import { jsx, useThemeUI } from "theme-ui"
import PropTypes from "prop-types"

import ModeContext from "../context/mode-context"
import Link from "./link"
import Image from "./image"
import { ab } from "../theme"

const ContentpageTeaser = ({
  heading,
  link,
  linkText,
  linkTarget,
  children,
  image,
  imageAlt,
  linkedImage,
  download,
  mode,
  currentLanguage,
}) => {
  const { mode: globalMode } = useContext(ModeContext)
  const { rawColors: colors } = useThemeUI().theme

  const modeKey = mode ? mode : globalMode

  let imageElement = (
    <Image
      image={image}
      alt={imageAlt ? imageAlt : heading}
      sizes={["100vw", "100vw", "50vw", "50vw"]}
      style={{
        height: "100%",
        width: "100%",
      }}
    />
  )

  if (linkedImage) {
    imageElement = (
      <Link
        to={link}
        target={linkTarget}
        download={download}
        language={currentLanguage}
      >
        {imageElement}
      </Link>
    )
  }
  return (
    <div
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: ab({ _: "column", md: "row-reverse" }),
        my: 5,
      }}
    >
      <div
        sx={{
          flex: 6,
          width: "100%",
          overflow: "hidden",
        }}
      >
        {imageElement}
      </div>
      <div
        sx={{
          flex: 7,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginRight: ab({ _: 0, md: "-15%" }),
          marginTop: ab({ _: "-40%", md: 0 }),
          px: 3,
          py: 5,
          zIndex: 2,
        }}
      >
        <div
          sx={{
            bg: "#F9F9F9",
            p: 3,
            marginBottom: ab({ _: 4, md: 5 }),
          }}
        >
          <h2>{heading}</h2>
          {children}
        </div>

        {linkText && (
          <div
            sx={{
              textAlign: ab({ _: "center", md: "left" }),
              px: 3,
            }}
          >
            <Link
              to={link}
              target={linkTarget}
              download={download}
              language={currentLanguage}
              sx={{
                display: ab({ _: "block", md: "inline" }),
                variant: "styles.ghostButton",
                color: modeKey
                  ? colors.modes[modeKey].primary
                  : colors.modes["group"].primary,
                border: "solid 1px",
                py: 2,
                px: 5,
                my: ab({ _: 2, md: 4 }),
                "&:hover": {
                  color: "#fff",
                  bg: modeKey
                    ? colors.modes[modeKey].primary
                    : colors.modes["group"].primary,
                },
              }}
            >
              {linkText}
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

ContentpageTeaser.propTypes = {
  heading: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkText: PropTypes.string,
  linkTarget: PropTypes.string,
  children: PropTypes.node,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  imageAlt: PropTypes.string,
  linkedImage: PropTypes.bool,
  download: PropTypes.bool,
  mode: PropTypes.oneOf([
    "",
    "pharma",
    "food",
    "environment",
    "career",
    "construction",
    "medical",
    "lifeScience",
    "agroChemical",
    "sustainabilityServices",
    "none",
  ]),
}

ContentpageTeaser.defaultProps = {
  download: false,
  linkedImage: false,
  mode: "",
}

export default ContentpageTeaser
