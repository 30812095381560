/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect, useContext } from "react"
import PropTypes from "prop-types"

import queryString from "query-string"

import BUWaterContext from "../../context/bu-water-context"

const BUWaterWrapper = ({ children, location }) => {
  const { loadCustomer } = useContext(BUWaterContext)

  const search = location ? queryString.parse(location.search) : null

  useEffect(() => {
    if (search) {
      if (search.v) {
        loadCustomer(search.id, search.sec, search.v)
      } else {
        loadCustomer(search.id, search.sec)
      }
    }
  }, [])

  return <div>{children}</div>
}

BUWaterWrapper.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
}

export default BUWaterWrapper
