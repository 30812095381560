/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { useEffect } from "react"

import { ab } from "../theme"

const BIteTool = ({ currentLanguage, headline, description }) => {
  const defaultLang = process.env.GATSBY_DEFAULT_LANG
    ? process.env.GATSBY_DEFAULT_LANG
    : "de"
  useEffect(() => {
    const script = document.createElement("script")
    script.src =
      "https://static.b-ite.com/jobs-api/loader-v1/api-loader-v1.min.js"
    script.async = false
    // script.onload = () => this.scriptLoaded();

    document.body.appendChild(script)
  })

  return (
    <div
      sx={{
        variant: "innerSpacingContainer",
        pt: "0 !important",
        maxWidth: "1100px",
        "& .bite-container .bite-four-columns .bite-block .bite-headline": {
          color: "text",
          fontWeight: "normal",
        },
        "& .bite-container .bite-four-columns .bite-block input, .bite-container .bite-four-columns .bite-block select":
          {
            variant: "form.formField",
            p: "15px",
            border: "none",
          },
        "& .bite-container .bite-four-columns .bite-block button": {
          backgroundColor: "background",
          color: "text",
          borderColor: "text",
          borderRadius: "20px",
          fontSize: 2,
          fontWeight: "normal",
          p: "10px",
          my: ab({ md: "50px" }),
          cursor: "pointer",
          textTransform: "lowercase",
          "&::first-letter": {
            textTransform: "uppercase",
          },

          "&:hover": {
            color: "background",
            backgroundColor: "text",
          },

          "&.bite-button-reset": {
            border: "none",
            textDecoration: "underline",
            "&:hover": {
              color: "text",
              backgroundColor: "background",
            },
          },
        },
        "& .bite-container .bite-jobs-list-table ": {
          fontSize: 2,
          "& th": {
            backgroundColor: "background",
            color: "text",
            border: "none",
            pl: 0,
          },
          "& td": {
            p: "15px",
            pl: 0,
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            "&.bite-jobs-list--custom_custom_field5": {
              color: "text",
            },
          },
        },
      }}
    >
      {headline && (
        <h2
          sx={{
            variant: "styles.h2",
          }}
        >
          {headline}
        </h2>
      )}
      {description && <div>{description}</div>}
      <div
        data-bite-jobs-api-listing={
          currentLanguage === defaultLang
            ? "gba:main-listing-v5"
            : "gba:english-v5"
        }
      ></div>
    </div>
  )
}

BIteTool.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.node,
}

BIteTool.defaultProps = {
  currentLanguage: "de",
}

export default BIteTool
