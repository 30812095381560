/** @jsx jsx */
import { jsx } from "theme-ui"

export default ({ name, children }) => {
  return (
    <div sx={{ py: 3 }}>
      <input type="hidden" name={"heading----" + name} value={name} />
      {children}
    </div>
  )
}
