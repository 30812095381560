/** @jsx jsx */
import { useContext } from "react"
import { jsx, useThemeUI } from "theme-ui"
import PropTypes from "prop-types"

import ModeContext from "../context/mode-context"
import GoogleMapReact from "google-map-react"
import Marker from "./marker"
import Location from "./location"
import BlockThirdParty from "./block-third-party"
import { useEffect, useState } from "react"

const Locations = ({
  heading,
  children,
  mode,
  location,
  apiKey,
  ceneterLat,
  centerLng,
  zoomLevel,
  defaultZoomLevel,
  currentLanguage,
  hideMap,
  hideLocations,
  thirdPartyInfo,
  mapCenter,
}) => {
  const { mode: globalMode } = useContext(ModeContext)
  const { rawColors: colors } = useThemeUI().theme

  const [center, setcenter] = useState({})
  const [zoom, setZoom] = useState(Number(zoomLevel))

  const modeKey = mode ? mode : globalMode

  useEffect(() => {
    setcenter(
      mapCenter
        ? mapCenter
        : {
            lat: Number(ceneterLat),
            lng: Number(centerLng),
          }
    )
  }, [mapCenter])

  useEffect(() => {
    setZoom(zoom ? zoomLevel : defaultZoomLevel)
  }, [zoomLevel])

  const markers = () =>
    hideMap
      ? null
      : location.map((location, index) => (
          <Marker
            lat={location.latitude}
            lng={location.longitude}
            key={index}
            heading={location.heading}
            address={location.address}
            currentLanguage={currentLanguage}
            locationLink={location.locationLink}
          />
        ))

  const locationDescriptions = () =>
    hideLocations
      ? null
      : location.map((location, index) => (
          <Location
            heading={location.heading}
            subHeading={location.subHeading}
            itsHeadline={location.itsHeadline}
            address={location.address}
            tel={location.tel}
            fax={location.fax}
            email={location.email}
            otherInfo={location.otherInfo}
            latitude={location.latitude}
            longitude={location.longitude}
            key={index}
            currentLanguage={currentLanguage}
          >
            {location.body}
          </Location>
        ))

  const permitThirdParty = thirdPartyInfo.block3rdParty !== "true"

  const renderLocations = () =>
    hideLocations ? null : (
      <div
        sx={{
          display: "flex",
          flexDirection: ["column", "row"],
          flexWrap: "wrap",
          "& a": {
            color: modeKey
              ? colors.modes[modeKey].primary
              : colors.modes["group"].primary,
          },
        }}
      >
        {locationDescriptions()}
      </div>
    )

  const renderMap = (permitThirdParty) => {
    if (hideMap) {
      return null
    }
    const mapContainer = (
      <div className="map-container" sx={{ height: "50vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey }}
          defaultCenter={center}
          center={center}
          defaultZoom={zoom}
          zoom={zoom}

          //yesIWantToUseGoogleMapApiInternals
          //onGoogleApiLoaded={({ map, maps}) => handleApiLoaded(map, maps)}
        >
          {markers()}
        </GoogleMapReact>
      </div>
    )

    if (permitThirdParty) {
      return mapContainer
    }

    return (
      <BlockThirdParty
        cookieKey={thirdPartyInfo.cookieKey}
        backgroundUrl={thirdPartyInfo.backgroundUrl}
        buttonText={thirdPartyInfo.buttonText}
        buttonPermanentText={thirdPartyInfo.buttonPermanentText}
        infoText={thirdPartyInfo.infoText}
        infoUrl={thirdPartyInfo.infoUrl}
        infoUrlText={thirdPartyInfo.infoUrlText}
      >
        {mapContainer}
      </BlockThirdParty>
    )
  }

  return (
    <div sx={{ variant: "spacingContainer" }}>
      <h2
        sx={{
          variant: "sytles.h2",
          my: 4,
        }}
      >
        {heading}
      </h2>
      {renderMap(permitThirdParty)}
      {renderLocations()}
    </div>
  )
}

Locations.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  children: PropTypes.node,
  mode: PropTypes.oneOf([
    "",
    "pharma",
    "food",
    "environment",
    "career",
    "construction",
    "medical",
    "lifeScience",
    "agroChemical",
    "sustainabilityServices",
    "none",
  ]),
  location: PropTypes.array,
  apiKey: PropTypes.string.isRequired,
  ceneterLat: PropTypes.number,
  ceneterLng: PropTypes.number,
  zoomLevel: PropTypes.number,
  hideMap: PropTypes.bool,
  hideLocations: PropTypes.bool,
  thirdPartyInfo: PropTypes.object,
}

Locations.defaultProps = {
  mode: "",
  ceneterLat: 48.85,
  centerLng: 2.35,
  zoomLevel: 3,
  hideMap: false,
  hideLocations: false,
}

export default Locations
