/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import PropTypes from "prop-types"
import { ab } from "../theme"
import Image from "./image"
import { useState, useEffect, useRef } from "react"

const LkfNewsListing = ({
  heading,
  image,
  imageAlt,
  noEntries,
  noEntriesText,
  buttonMoreNews,
  buttonLessNews,
  children,
}) => {
  const [showallTeasers, setShowAllTeasers] = useState(false)
  const refDiv = useRef(null)
  const { rawColors: colors } = useThemeUI().theme

  let noEntriesInfo
  if (noEntries) {
    noEntriesInfo = <div>{noEntriesText}</div>
  }
  let img
  if (image) {
    img = (
      <Image
        image={image}
        alt={imageAlt}
        sizes={["100vw", "100vw", "50vw", "50vw"]}
        style={{ width: "100%", height: "auto" }}
      />
    )
  }
  useEffect(() => {
    let allTeasers = refDiv.current.querySelectorAll(".lkf-news-teaser")
    if (showallTeasers) {
      for (let i = 0; i < allTeasers.length; i++) {
        allTeasers[i].style.display = "block"
      }
    } else {
      for (let i = noEntries; i < allTeasers.length; i++) {
        allTeasers[i].style.display = "none"
      }
    }
  }, [children, showallTeasers])

  return (
    <div>
      <div
        sx={{
          variant: "alternatingSpacingContainer",
          color: "text",
          display: "flex",
          flexDirection: ab({ _: "column", md: "row" }),
          pb: 5,
        }}
      >
        <div
          sx={{
            flex: 1,
          }}
        >
          {img}
        </div>
        <div
          sx={{
            paddingLeft: ab({ md: 5 }),
            flex: 1,
          }}
        >
          <h2
            sx={{
              mt: ab({ _: 5, md: 0 }),
              mb: ab({ _: 4, md: 4 }),
              fontWeight: "bold !important",
              fontSize: "38px",
            }}
          >
            {heading}
          </h2>
          {noEntriesInfo}
          <div
            ref={refDiv}
            sx={{
              "& div:nth-of-type(1) span": {
                bg: colors.modes.pharma.primary,
                color: "#fff",
              },
            }}
          >
            {children}
            <button
              onClick={() => setShowAllTeasers(!showallTeasers)}
              sx={{
                variant: "styles.ghostButton",
                px: 4,
                py: 2,
              }}
            >
              {showallTeasers ? buttonLessNews : buttonMoreNews}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

LkfNewsListing.propTypes = {
  heading: PropTypes.string,
  noEntries: PropTypes.string,
  noEntriesText: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  imageAlt: PropTypes.string,
  children: PropTypes.node,
}

export default LkfNewsListing
