import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import cookieBot from "./cookieBot"
import { useSiteUrl } from "../hooks/use-site-url"

const SiteMetadata = ({
  defaultTitle,
  title,
  canonicalLink,
  imageURL,
  language,
  description,
  favicon,
  appleFavicon,
  allLanguage,
  location,
  mode,
  noAnalytics,
  enableCookieBot,
}) => {
  const siteUrl = useSiteUrl() || location.origin || "http://localhost:8000"
  const defaultLang = process.env.GATSBY_DEFAULT_LANG
    ? process.env.GATSBY_DEFAULT_LANG
    : "de"

  let imageMeta = []
  let langArry = []
  let path = ""

  if (allLanguage) {
    langArry = Object.values(allLanguage)
    path =
      language === defaultLang ? location.pathname : location.pathname.substr(3)
  }

  if (imageURL) {
    imageMeta.push(<meta key="1" name="image" content={imageURL} />)
    imageMeta.push(<meta key="2" property="og:image" content={imageURL} />)
    imageMeta.push(<meta key="3" property="og:image:width" content="512" />)
    imageMeta.push(<meta key="4" property="og:image:height" content="512" />)
  }

  let canonicalLinkMeta = []
  if (canonicalLink) {
    canonicalLinkMeta.push(
      <link key="1" rel="canonical" href={canonicalLink} />
    )
    canonicalLinkMeta.push(
      <meta key="2" property="og:url" content={canonicalLink} />
    )
  }

  let descriptionMeta = []
  if (description) {
    descriptionMeta.push(
      <meta key="1" name="description" content={description} />
    )
    descriptionMeta.push(
      <meta key="2" property="og:description" content={description} />
    )
  }

  let titleMeta = []
  if (title) {
    titleMeta.push(<meta key="1" property="og:site_name" content={title} />)
    titleMeta.push(<title key="2">{title}</title>)
  }

  let cbid = process.env.GATSBY_COOKIEBOT_CBID
    ? process.env.GATSBY_COOKIEBOT_CBID
    : "2ede1994-0979-4b00-b009-8bfb4c7185a2"
  return (
    <Helmet
      defer={false}
      defaultTitle={defaultTitle}
      titleTemplate={`%s | ${defaultTitle}`}
    >
      <html lang={language} />
      <link rel="icon" href={favicon} />
      <link rel="apple-touch-icon" href={appleFavicon} />
      <meta name="docsearch:version" content="2.0" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />

      <meta property="og:type" content="website" />
      <meta property="og:locale" content={language} />
      <meta property="product:category" content={mode} />

      {langArry.map((lang, i) => {
        i === 0 ? (lang = "de") : (lang = lang)
        return (
          <link
            rel="alternate"
            key={i}
            href={siteUrl + (lang === "de" ? "" : "/" + lang) + path}
            hrefLang={lang}
          />
        )
      })}

      <link rel="alternate" hreflang="x-default" href={siteUrl + path} />
      {enableCookieBot && cookieBot(noAnalytics, enableCookieBot)}
      {enableCookieBot && (
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid={cbid}
          type="text/javascript"
          async={true}
        ></script>
      )}

      {titleMeta}
      {descriptionMeta}
      {canonicalLinkMeta}
      {imageMeta}
      <meta name="twitter:card" content="summary" />
    </Helmet>
  )
}

SiteMetadata.propTypes = {
  defaultTitle: PropTypes.string,
  title: PropTypes.string,
  canonicalLink: PropTypes.string,
  imageURL: PropTypes.string,
  language: PropTypes.string,
  description: PropTypes.string,
  favicon: PropTypes.string,
  appleFavicon: PropTypes.string,
  allLanguage: PropTypes.object,
  location: PropTypes.object,
  mode: PropTypes.oneOf([
    "default",
    "group",
    "pharma",
    "food",
    "environment",
    "contact",
    "sustainabilityServices",
  ]).isRequired,
}

export default SiteMetadata
