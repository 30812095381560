/** @jsx jsx */
import { jsx } from "theme-ui"

const RightIcon = ({ style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 500 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
  >
    <polygon
      sx={style}
      points="164.1,431.7 154.2,421.9 326.1,250 154.2,78.1 164.1,68.3 345.8,250 "
    />
  </svg>
)

export default RightIcon
