/** @jsx jsx */
import { jsx } from "theme-ui"

const ContactBgIcon = ({ style = {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 521.8 521.78">
    <g>
      <g>
        <path
          fill="#fff"
          d="M519.48,295.51a262.64,262.64,0,0,0,2.32-34.79,259.57,259.57,0,0,0-70-177.63l-48.71-4.3H228V240.55H104.28V396h77.39l27.87,51,29.86-51h73V301.32h46.81l44.2,78.4,44.14-78.4h17.74Z"
        />
        <path
          id="svgexport-14"
          fill="#074787"
          d="M443.67,295.49l-40.29,70-40.32-70H260.2c-14.1,0-25.59-9.4-25.59-21V104c0-11.49,11.49-20.89,25.59-20.89H451.82C353.62-22.36,188.54-28.24,83.09,70S-28.23,333.25,70,438.69,333.26,550,438.7,451.81a260.81,260.81,0,0,0,80.79-156.32Zm-138.36,80a15.46,15.46,0,0,1-16.61,14.1H235.87l-26.22,47.2-26.21-47.2H127.26a15.54,15.54,0,0,1-16.61-14.1V260.19a15.46,15.46,0,0,1,16.61-14.09h84v28.4c0,24.44,22,44.39,49,44.39h45v56.6Z"
        />
      </g>
    </g>
  </svg>
)

export default ContactBgIcon
