/** @jsx jsx */
import { jsx } from "theme-ui"

const ArrowIcon = ({ style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 500 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
  >
    <polygon
      sx={style}
      points="500,243.05 26.62,243.05 162.25,107.42 152.42,97.59 0,250.01 152.42,402.41 162.25,392.58 26.61,256.95 
      500,256.95 "
    />
  </svg>
)

export default ArrowIcon
