/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import { useState } from "react"
import PropTypes from "prop-types"

import Image from "./image"

import Button from "./button"
import { ab } from "../theme"

const CareerFacesTeaser = ({
  position,
  location,
  name,
  quote,
  linkText,
  linkTextExpanded,
  image,
  imageAlt,
  currentLanguage,
  children,
}) => {
  const { rawColors: colors } = useThemeUI().theme

  const [expandBodyText, setExpandBodyText] = useState(false)

  const toggleBodyText = () => {
    setExpandBodyText(!expandBodyText)
  }

  const animationDuration = ".5s"

  const transitionWidth = {
    transition: `width ease-in-out ${animationDuration}`,
  }
  return (
    <div
      className="career-faces-teaser-container"
      sx={{
        variant: "spacingContainer",
        bg: "secondaryBackground",
        px: "0 !important",
      }}
    >
      <div
        className="info-image-container"
        sx={{
          display: ab({ md: "flex" }),
          justifyContent: "space-between",
          flexDirection: ab({ md: "row-reverse" }),
        }}
      >
        <div
          className="image-container"
          sx={{
            width: ab({ md: expandBodyText ? "40%" : "60%" }),

            bg: "#fff",
            ...transitionWidth,
          }}
        >
          <div
            sx={{
              backgroundImage: ab({ _: "", md: `url(${image})` }),
              backgroundSize: "cover",
              height: "100%",
              backgroundPosition: "top center",
              minHeight: ab({ _: "auto", md: "450px" }),
              img: {
                display: ab({ _: "", md: "none" }),
              },
            }}
          >
            <Image
              image={image}
              style={{ maxHeight: "100%", objectFit: "contain" }}
              alt={imageAlt ? imageAlt : name}
              sizes={["100vw", "100vw", "67vw", "67vw"]}
            />
          </div>
        </div>
        <div
          className="info-container"
          sx={{
            mt: ab({ _: "-8px", md: "0" }),
            width: ab({ md: "40%" }),
            bg: "secondaryBackground",
            p: 4,
            position: "relative",
            ...transitionWidth,
          }}
        >
          <div
            sx={{
              textTransform: "uppercase",
              fontSize: "14px",
              letterSpacing: "2px",
              mt: "1em",
            }}
          >
            <strong>{position}</strong>
          </div>
          <div sx={{ fontSize: "12px" }}>{location}</div>
          <h2 sx={{ mt: "1em" }}>{name}</h2>
          <div sx={{ fontSize: "14px", fontStyle: "italic" }}>
            <q>{quote}</q>
          </div>
          <div className="action" sx={{ mt: 4 }}>
            <Button
              onClick={() => {
                toggleBodyText()
              }}
              buttonText={expandBodyText ? linkTextExpanded : linkText}
              style={{
                borderColor: colors.modes.career.primary,
                color: colors.modes.career.primary,
                "&:hover": {
                  bg: "none",
                },
              }}
            />
          </div>
          <div
            sx={{
              fontSize: "14px",
              maxHeight: expandBodyText ? "1500px" : 0,
              width: ab({ md: expandBodyText ? "140%" : "100%" }),
              transition: `all ease-in-out ${animationDuration}`,
              overflow: "hidden",
              "& h2, & h3": {
                fontSize: "14px",
                fontWeight: "bold",
                mb: 0,
                "&:first-child": {
                  pt: "3em",
                },
              },
              "& p": {
                mt: 0,
              },
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

CareerFacesTeaser.propTypes = {
  position: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  linkTextExpanded: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  imageAlt: PropTypes.string,
  children: PropTypes.node,
}

export default CareerFacesTeaser
