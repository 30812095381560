/** @jsx jsx */
import { jsx } from "theme-ui"

const Phone = ({ style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="38"
    viewBox="0 0 368.47 500"
  >
    <g>
      <path
        sx={style}
        d="M368.45 434.75a23.78 23.78 0 00-7.21-18.18l-73.37-76.28-.45-.12A24.69 24.69 0 00275.9 334a29 29 0 00-21.13 2l-33.26 19.17a8 8 0 01-3.39.76 10.74 10.74 0 01-3.15-.14l-5.36-2.72a72.53 72.53 0 01-14.54-11.14 218.77 218.77 0 01-21.87-24.46 311.33 311.33 0 01-27.33-42.65 307 307 0 01-22.25-45.14 209.44 209.44 0 01-9.42-31.29 84.93 84.93 0 01-2.11-24.1 11.44 11.44 0 011.64-3.23 7.71 7.71 0 012.41-2.51l28.49-16.09A29.44 29.44 0 00156.41 140a23 23 0 002.16-16.55l-23-105.87a32.81 32.81 0 00-5.39-10.56 17.55 17.55 0 00-9.5-6.28 20.72 20.72 0 00-16.33 2.32L27 46.7a35.4 35.4 0 00-10.71 8.55A64.83 64.83 0 005.62 76a117.17 117.17 0 00-4.44 23 242 242 0 00-.85 36.52A297.5 297.5 0 007.16 185a443.09 443.09 0 0018.92 62.35 515.86 515.86 0 0036.05 74.72 491.76 491.76 0 0057.79 84.69 411.85 411.85 0 0053.78 52.43 219.33 219.33 0 0043.3 27.73 189.69 189.69 0 0035.91 12.69 41.17 41.17 0 0014.09-.49 35.39 35.39 0 0012.85-4.75L357 451.18a21.59 21.59 0 0011.45-16.43z"
      />
    </g>
  </svg>
)

export default Phone
