/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import { useState, useEffect, useContext } from "react"

import NavigationContext from "../../context/navigation-context"

import Link, { TransformLinkUri } from "../link"
import Icon from "../../assets/icon"

import Layer from "./layer"
import LayerMobile from "./layer-mobile"
import { ab } from "../../theme"
import PropTypes from "prop-types"

const NavigationItem = ({
  item,
  level,
  language,
  mode,
  selected,
  hasChild,
  location,
  setSelected,
  setSubLayer,
}) => {
  const [isCurrent, setIsCurrent] = useState(false)
  const [initialOpen, setInitialOpen] = useState(true)
  const [toggleNavMobile, setToggleNavMobile] = useState(selected)

  const { resetLevel } = useContext(NavigationContext)

  const closeSubNavi = () => {
    setTimeout(() => {
      setSubLayer(null)
    }, 300)
  }
  let itemExtras

  if (item.extras && item.extras.length) {
    itemExtras = item.extras
  }

  const currentUrl = item.url.id
    ? TransformLinkUri(item.url.id, null, language)
    : null

  const isPartiallyCurrent = location.pathname.startsWith(currentUrl)
  const isCurrentPage = currentUrl === location.pathname

  if (
    (isPartiallyCurrent || isCurrentPage || item.open) &&
    initialOpen &&
    hasChild
  ) {
    setInitialOpen(false)
    setIsCurrent(true)
    setSelected()
    resetLevel(level)
    setSubLayer(
      <Layer
        parent={item.displayName}
        parentUrl={item.url.id}
        items={item.children}
        language={language}
        level={level}
        mode={item.mode || mode}
        location={location}
        closeSubNavi={closeSubNavi}
        parentIcon={item.icon}
        itemExtras={itemExtras}
      />
    )
  }

  const openLayer = () => {
    setIsCurrent(true)
    setSubLayer(
      <Layer
        parent={item.displayName}
        parentUrl={item.url.id}
        items={item.children}
        language={language}
        level={level}
        mode={item.mode || mode}
        location={location}
        closeSubNavi={closeSubNavi}
        parentIcon={item.icon}
        itemExtras={itemExtras}
      />
    )
  }

  useEffect(() => {
    setToggleNavMobile(selected ? true : !toggleNavMobile)
  }, [selected])

  const { rawColors: colors } = useThemeUI().theme

  return (
    <div
      className="item"
      sx={{
        li: {
          px: "20px",
        },
        ".toggleIcon": {
          mr: "20px",
        },
        ".pLevel": {
          "&:after": {
            width: "100%",
          },
        },
        ".inactive": {
          maxHeight: 0,
          overflow: "hidden",
          transition: "all .3s ease-out",
          opacity: 0,
          ".mobile-navigation-layer": {
            maxHeight: 0,
            overflow: "hidden",
            transition: "all .3s ease-out ",
          },
        },
        ".active": {
          maxHeight: ab({ _: "2000px", md: 0 }),
          opacity: 1,
          overflow: ab({ _: "visible", md: "hidden" }),
          transition: "all .3s ease-in",
          ".mobile-navigation-layer": {
            maxHeight: "2000px",
            transition: "all .3s ease-out",
          },
        },
      }}
    >
      <div
        sx={{
          display: "flex",
          lineHeight: "1.8",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {item.icon && (
          <div
            sx={{
              height: ab({ _: "34px", md: "28px", lg: "34px" }),
              width: ab({ _: "34px", md: "28px", lg: "34px" }),
              mr: ab({ _: "15px", md: "10px", lg: "15px" }),

              display: "inherit",
              flexShrink: 0,
            }}
          >
            <Icon style={{ fill: colors.navigation.icon }} icon={item.icon} />
          </div>
        )}
        <Link
          onClick={(e) => {
            if (hasChild) {
              e.preventDefault()
              resetLevel(level)
              openLayer()
              setToggleNavMobile(!toggleNavMobile)
            }
          }}
          to={item.url.id || item.url.url}
          activeClassName="current"
          className={`${isPartiallyCurrent && level > 2 ? "pLevel" : ""}`}
          sx={{
            variant: level > 2 ? "navigation.hover" : "",
            fontSize: ab({ _: "14px", md: "13px", lg: "14px" }),
          }}
          language={language}
        >
          {item.displayName}
        </Link>
        {hasChild && (
          <div
            className="toggleIcon"
            sx={{
              transform: ab({
                _:
                  level > 2 && selected && toggleNavMobile
                    ? "rotate(-90deg)"
                    : "rotate(90deg)",
                md: "rotate(0)",
              }),
              height: 20,
              width: 20,
              zIndex: 0,
              transition: "all .3s",
              flexShrink: "0",
            }}
          >
            <Icon style={{ fill: colors.navigation.icon }} icon="right" />
          </div>
        )}
      </div>
      {level > 2 && hasChild && (
        <div
          className={`mobile-nav
                ${selected && toggleNavMobile ? "active" : "inactive"}
                `}
        >
          <LayerMobile
            parent={item.displayName}
            parentUrl={item.url.id}
            items={item.children}
            language={language}
            mode={mode}
            location={location}
            level={level}
            itemExtras={itemExtras}
          />
        </div>
      )}
    </div>
  )
}
NavigationItem.protoTypes = {
  item: PropTypes.array.isRequired,
  level: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  mode: PropTypes.oneOf([
    "group",
    "pharma",
    "food",
    "environment",
    "career",
    "sustainabilityServices",
  ]).isRequired,
  selected: PropTypes.bool,
  hasChild: PropTypes.bool,
  location: PropTypes.object.isRequired,
  setSelected: PropTypes.bool,
  setSubLayer: PropTypes.object.isRequired,
}

export default NavigationItem
