/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Link from "./link"
import { ab } from "../theme"

function Slide({
  children,
  subHeading,
  subHeadingText,
  heading,
  buttonLink,
  buttonText,
  buttonColor,
  linkTarget,
  body,
  currentLanguage,
  textReverse,
  textToRight,
}) {
  return (
    <div
      sx={{
        color: "#fff",
        position: "relative",
        variant: "guc.slide",
      }}
    >
      <div
        sx={{
          maxWidth: "100%",
          overflow: "hidden",
        }}
      >
        {children}
      </div>
      <div
        sx={{
          position: "relative",
          paddingBottom: "100px",
          top: 0,
          textAlign: ["center", "center", "Left"],
          width: "100%",
        }}
        className={` slide-text ${
          textToRight ? " slide-text-right" : " slide-text-left"
        }`}
      >
        <div
          className={textReverse ? "text-reverse" : ""}
          sx={{
            py: 3,
            px: 4,
            display: "flex",
            flexDirection: textReverse ? "column-reverse" : "column",
          }}
        >
          <div>
            <h6
              sx={{
                my: 1,
                p: 0,
                fontSize: 12,
                textTransform: "uppercase",
                letterSpacing: "2px",
              }}
            >
              {subHeading}
            </h6>
            <p
              sx={{
                m: 0,
                p: 0,
                fontSize: 12,
                fontWeight: "normal",
              }}
            >
              {subHeadingText}
            </p>
          </div>
          <div
            sx={{
              marginTop: ab({ _: 0, md: 10, lg: 20 }),
              fontWeight: 1,
            }}
          >
            <div
              className="slide-heading"
              sx={{
                fontSize: "34px",
                fontWeight: 1,
              }}
            >
              {heading}
            </div>
            {body && <div>{body}</div>}
            <Link
              to={buttonLink}
              target={linkTarget}
              language={currentLanguage}
              style={{
                display: buttonText ? "inline-block" : "none",
              }}
              sx={{
                variant: "styles.ghostButton",
                color: "#fff",
                border: buttonColor ? "" : "solid 1px #fff",
                backgroundColor: buttonColor ? buttonColor : "transprent",
                py: "6px",
                px: 48,
                "&:hover": {
                  borderColor: "text",
                  bg: "text",
                  color: "#fff",
                },
              }}
            >
              {buttonText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

Slide.propTypes = {
  children: PropTypes.node,
  body: PropTypes.node,
  subHeading: PropTypes.string.isRequired,
  subHeadingText: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  linkTarget: PropTypes.string,
}

export default Slide
