/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { useState, useEffect } from "react"

import { render } from "storyblok-rich-text-react-renderer"

import GlossarItem from "./glossar-item"
import Letter from "./glossar/letter"
import Text from "./glossar/text"
import ImageBox from "./glossar/image-box"
import ImageSingle from "./glossar/image-single"

import { ab } from "../theme"

const Glossar = ({ headline, items }) => {
  const maxWidth = 876
  const boxWidth = 175
  const [containerWidth, setContainerWidth] = useState(maxWidth)
  const [mobileContainerWidth, setMobileContainerWidth] = useState(maxWidth)
  const [itemsInclAnchors, setItemsIncludingAnchors] = useState(items)

  const alpha = Array.from(Array(26)).map((e, i) => i + 65)
  const alphabet = alpha.map((x) => String.fromCharCode(x))

  useEffect(() => {
    const handleResize = () => {
      // wir könnten hier wohl auch mit calc() arbeiten. Leider bietet CSS keine
      // floor func, so dass wir hier lieber auf JS setzen.

      const mobileTolerance = 35 // padding
      const tolerance = 135 // padding + Navigation
      const width = Math.min(maxWidth + tolerance, window.innerWidth)
      const mobileContainerWidth =
        Math.floor((window.innerWidth - mobileTolerance) / boxWidth) *
          boxWidth +
        1
      const containerWidth =
        Math.floor((width - tolerance) / boxWidth) * boxWidth + 1

      setMobileContainerWidth(mobileContainerWidth)
      setContainerWidth(containerWidth)
    }

    window.addEventListener("resize", handleResize)

    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  })

  useEffect(() => {
    // sort items
    itemsInclAnchors.sort((a, b) => a.key.localeCompare(b.key)) // sort by key

    const findIndex = (itemsInclAnchors, alphabet, pos) => {
      const index = itemsInclAnchors.findIndex((item) =>
        item.key.toUpperCase().startsWith(alphabet[pos])
      )

      if (index === -1 && pos <= alphabet.length) {
        return findIndex(itemsInclAnchors, alphabet, pos + 1)
      }

      return index === -1 ? itemsInclAnchors.length : index
    }

    // insert anchors
    for (let i = 0; i < alphabet.length; ++i) {
      const letter = alphabet[i]
      /*const index = itemsInclAnchors.findIndex((item) =>
        item.key.toUpperCase().startsWith(letter)
      )*/

      const index = findIndex(itemsInclAnchors, alphabet, i)

      itemsInclAnchors.splice(index, 0, {
        key: letter,
        isAnchor: true,
      })
    }

    setItemsIncludingAnchors([...itemsInclAnchors])
  }, [])

  const renderBox = (item) => {
    const text = render(item.text)

    if (item.isAnchor) {
      return <Letter char={item.key} />
    }

    if (text === null) {
      return (
        <ImageSingle
          jumpTo={item.key}
          filename={item.image.filename}
          alt={item.image.alt}
        />
      )
    }

    if (item.image.id) {
      return (
        <ImageBox
          filename={item.image.filename}
          alt={item.image.alt}
          headline={item.key}
        >
          {text}
        </ImageBox>
      )
    }

    return <Text headline={item.key}>{text}</Text>
  }

  return (
    <section
      className="glossar-container"
      sx={{
        variant: "spacingContainer",
      }}
    >
      <h2>{headline}</h2>
      <div className="toolbar">
        <ul
          sx={{
            listStyle: "none",
            p: 0,
            m: 0,
          }}
        >
          {alphabet.map((letter, index) => (
            <li
              key={index}
              sx={{
                bg: "#F2F2F2",
                display: "inline-block",
                m: "5px",
                textAlign: "center",
                height: "32px",
                width: "32px",
              }}
            >
              <a
                href={`#${letter}`}
                sx={{
                  color: "text",
                  textDecoration: "none",
                  lineHeight: "32px",
                }}
              >
                {letter}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div
        className="glossar"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          overflow: "hidden",
          width: ab({
            _: `${mobileContainerWidth}px`,
            md: `${containerWidth}px`,
          }),
          pb: "1px",
          pr: "1px",
          mt: "3em",
        }}
      >
        {itemsInclAnchors.map((item, index) => {
          return (
            <GlossarItem key={`glossar-item-${index}`} colspan={item.layout}>
              {renderBox(item)}
            </GlossarItem>
          )
        })}
      </div>
    </section>
  )
}

Glossar.propTypes = {
  headline: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
}

export default Glossar
