/** @jsx jsx */
import { jsx } from "theme-ui"

const Praparate = ({ style = {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      d="M424.8 110.2V84.5h7.8c15.6 0 28.2-12.7 28.2-28.2v-28c0-15.6-12.7-28.2-28.2-28.2H170.8c-15.6 0-28.2 12.7-28.2 28.2v28c0 15.6 12.7 28.2 28.2 28.2h7.8v25.7c-32.6 4.9-57.7 33-57.7 67v30.1H55.7C41 207.3 29 219.3 29 234v24.4c0 12.9 9.1 23.6 21.2 26.2v194.2c0 18.3 14.9 33.2 33.2 33.2h331.4c37.4 0 67.7-30.4 67.7-67.7V177.2c0-34-25.1-62.1-57.7-67zM162.5 56.3v-28c0-4.5 3.7-8.2 8.2-8.2h261.8c4.5 0 8.2 3.7 8.2 8.2v28c0 4.5-3.7 8.2-8.2 8.2H170.8c-4.6 0-8.3-3.7-8.3-8.2zm242.3 28.2v25H198.6v-25h206.2zM49 234c0-3.6 3.1-6.7 6.7-6.7h160.5c3.6 0 6.7 3.1 6.7 6.7v24.5c0 3.6-3.1 6.7-6.7 6.7H55.7c-3.7 0-6.7-3-6.7-6.7V234zm152.8 178.9h-80.4c-3 0-5.4-2.5-5.4-5.4v-56c0-3 2.5-5.4 5.4-5.4h80.4v66.8zM70.2 478.8V285.2h131.5V326h-80.4c-14 0-25.4 11.4-25.4 25.4v56c0 14 11.4 25.4 25.4 25.4h80.4v46c0 7.3-5.9 13.2-13.2 13.2H83.4c-7.3 0-13.2-5.9-13.2-13.2zM414.8 492H219c1.8-4 2.7-8.5 2.7-13.2V284.6c12.1-2.5 21.2-13.3 21.2-26.2V234c0-14.7-12-26.7-26.7-26.7h-75.4v-30.1c0-26.3 21.4-47.7 47.7-47.7h226.2c26.2 0 47.5 21.2 47.7 47.4H343.2c-26.9 0-48.7 21.8-48.7 48.7v45.2c0 5.5 4.5 10 10 10s10-4.5 10-10v-45.2c0-15.8 12.9-28.7 28.7-28.7h119.3v227.8H343.2c-15.8 0-28.7-12.9-28.7-28.7v-45.2c0-5.5-4.5-10-10-10s-10 4.5-10 10V396c0 26.8 21.8 48.7 48.7 48.7h119.3c-.2 26.1-21.5 47.3-47.7 47.3z"
      fill="#004787"
    />
    <path
      d="M304.5 320.7c5.5 0 10-4.5 10-10s-4.5-10-10-10-10 4.5-10 10 4.5 10 10 10z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#004787"
    />
  </svg>
)

export default Praparate
