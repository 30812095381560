/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

const Text = ({ headline, children }) => {
  return (
    <div
      id={headline}
      sx={{
        color: "text",
        width: "100%",
        height: "100%",
        p: "15px 20px",
      }}
    >
      <h3
        sx={{
          fontSize: "20px",
          mt: 0,
          mb: "10px",
        }}
      >
        {headline}
      </h3>
      <p
        sx={{
          fontSize: "14px",
          lineHeight: 1.3,
          mt: 0,
        }}
      >
        {children}
      </p>
    </div>
  )
}

Text.propTypes = {
  headline: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Text
