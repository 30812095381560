/** @jsx jsx */
import { jsx } from "theme-ui"

const CareerIcon = ({ style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 500 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
  >
    <svg
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
    >
      <path
        d="M249.71.28a250.13 250.13 0 00-230.07 152L180.35 71.5a16.31 16.31 0 0114.63 0l191.42 96.07a16.31 16.31 0 010 29.17l-2.92 1.47V306.7l20 52.09a3.92 3.92 0 01-3.89 3.9 49.57 49.57 0 01-26 4.45 49.55 49.55 0 01-26-4.45 3.92 3.92 0 01-3.94-3.88l19.49-51.33v-99L195.05 292.9a16.25 16.25 0 01-14.63 0L3.89 204.33c-25.43 135.75 64 266.42 199.77 291.85s266.43-64 291.86-199.77S431.5 30 295.74 4.56A249.29 249.29 0 00249.71.28zm43.61 330.41a49.82 49.82 0 01-.42 6.46A94.54 94.54 0 01265.31 398l-1.21 1.21a108.2 108.2 0 01-153 0l-1.19-1.21a94.53 94.53 0 01-27.58-60.89 49.82 49.82 0 01-.42-6.46v-42.2a13.24 13.24 0 0119.18-11.84L181.68 317a13.2 13.2 0 0011.87 0l80.58-40.37a13.25 13.25 0 0119.19 11.84z"
        sx={style}
      />
    </svg>
  </svg>
)

export default CareerIcon
