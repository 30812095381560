import React, { useEffect, useState } from "react"

const defaultState = {
  setSites: () => {},
  getSites: () => {},
  setFilters: () => {},
  getFilters: () => {},
  setLanguage: () => {},
  getLanguage: () => {},
  setSerach: () => {},
  getSerach: () => {},
  getSiteListing: () => {},
  setSiteListing: () => {},
  setPlzFilters: () => {},
  getPlzFilters: () => {},
}

const SitesContext = React.createContext(defaultState)

const SitesProvider = ({ children }) => {
  const [SitesValue, setSitesValue] = useState({})
  const [filtersValue, setFiltersValue] = useState({})
  const [filtersPlzValue, setFiltersPlzValue] = useState({})
  const [langValue, setLangValue] = useState({})
  const [searchValue, setSearchValue] = useState({})
  const [listingValue, setListingValue] = useState([])

  let countryGrouped = []

  const [query, setQuery] = useState(searchValue)
  const [queryIsValid, setQueryIsValid] = useState(false)

  useEffect(() => {
    setQuery(searchValue)
  }, [searchValue])

  useEffect(() => {
    for (const key in query) {
      if (query[key].length > 0) {
        setQueryIsValid(true)
      }
    }
  }, [query])

  function hasCommonElement(A, B) {
    return A.some((item) =>
      B.some(
        (bItem) => bItem.toLowerCase().trim() === item.toLowerCase().trim()
      )
    )
  }

  const setSerach = (value) => {
    setSearchValue(value)
  }

  const getSerach = () => {
    return searchValue
  }

  const setSites = (sites) => {
    setSitesValue(sites)
  }

  const setLanguage = (lang) => {
    setLangValue(lang)
  }

  const getLanguage = () => {
    return langValue
  }

  const setFilters = (sites) => {
    let filters = []
    let countries = []
    let services = []
    let industries = []
    let companyNames = []

    const spliteToArr = (str) => str.split(",").map((service) => service.trim())

    sites.forEach((site, i) => {
      if (site.node.content === undefined || site.node.content === "null") {
        return
      }
      let nodeLang = site.node.lang === "default" ? "de" : site.node.lang
      let content = JSON.parse(site.node.content)

      if (nodeLang === getLanguage()) {
        let country = content.country ? content.country.toLowerCase() : ""
        let companyName = content.companyName ? content.companyName : ""
        let separator = ", "
        let industry = content.industry
        if (typeof content.industry === "object") {
          industry = industry.join(separator)
        }
        industry = industry ? industry.toLowerCase() : ""

        if (country) countries.push(country)

        if (industry) {
          if (industry.includes(",")) {
            let industryArray = spliteToArr(industry)
            industries.push(...industryArray)
          } else {
            industries.push(industry)
          }
        }
        if (companyName) {
          if (industry.includes(",")) {
            let companyNameArray = spliteToArr(companyName)
            companyNames.push(...companyNameArray)
          } else {
            companyNames.push(companyName)
          }
        }
      }
    })

    countries = new Set(countries)
    services = new Set(services)
    industries = new Set(industries)
    companyNames = new Set(companyNames)

    filters.push(countries, industries, companyNames, services)
    setFiltersValue(filters)
  }
  const getSites = () => {
    return SitesValue
  }

  const getFilters = () => {
    return filtersValue
  }

  const getPlzFilters = () => {
    return filtersPlzValue
  }
  const setPlzFilters = (value) => {
    setFiltersPlzValue(value)
  }

  const siteListing = (sites) => {
    if (!sites.length) {
      return
    }

    countryGrouped = []

    sites.forEach((site, i) => {
      if (site.node.content === undefined || site.node.content === "null") {
        return
      }

      let show = false
      let showCountry = true
      let showIndustry = true
      let showCompany = true

      let className = ""
      let content = JSON.parse(site.node.content)
      let name = site.node.name
      let slug = site.node.full_slug
      if (/\/standorte\/$/.test(slug) || /\/sites\/$/.test(slug)) {
        return
      }

      let nodeLang = site.node.lang === "default" ? "de" : site.node.lang

      if (nodeLang === getLanguage()) {
        let country = content.country !== undefined ? content.country : ""
        let companyName =
          content.companyName !== undefined ? content.companyName : ""
        let logo = content.logo ? content?.logo : ""
        let industry = content.industry

        let latitude = content.latitude
        let longitude = content.longitude
        let address = content.address
        let city = content.cityName
        let linkLocation = content.linkLocation

        let separator = ", "
        if (typeof content.industry === "object") {
          industry = industry.join(separator)
        }
        industry = industry !== undefined ? industry : ""
        let tileImage = content.tileImage !== undefined ? content.tileImage : ""

        let industriesStr
        let industryArray
        if (industry && industry.includes(",")) {
          industriesStr = industry.replace(/,/g, " ")
          industryArray = industry.split(",")
        }

        if (queryIsValid) {
          if (query.countries.length) {
            if (
              !!country &&
              query.countries.includes(country.toLowerCase().trim())
            ) {
              className = " " + country
            } else {
              showCountry = false
            }
          } else {
            showCountry = true
          }

          if (query.companys.length) {
            if (!!companyName && query.companys.includes(companyName.trim())) {
              className = " " + companyName
            } else {
              showCompany = false
            }
          } else {
            showCompany = true
          }

          if (query.industries.length) {
            if (
              !!industry &&
              query.industries.includes(industry) &&
              !industryArray
            ) {
              className = " " + industry
            } else {
              showIndustry = false
            }
            if (!!industryArray) {
              let industryCheck = hasCommonElement(
                query.industries,
                industryArray
              )
              if (industryCheck) {
                className = className + " " + industriesStr
                showIndustry = true
              } else {
                showIndustry = false
              }
            }
          }
        } else {
          show = true
        }

        show = showCountry && showIndustry && showCompany
        if (show) {
          let countryKey = country.toLowerCase()
          if (!countryGrouped[countryKey]) {
            countryGrouped[countryKey] = []
          }

          let data = {
            name: name,
            slug: slug,
            industry: industry,
            country: countryKey,
            tileImage: tileImage,
            logo: logo,
            longitude: longitude,
            latitude: latitude,
            address: address,
            city: city,
            linkLocation: linkLocation,
          }
          countryGrouped[countryKey].push(data)
        }
      }

      return
    })
  }
  const setSiteListing = () => {}

  const getSiteListing = () => {
    siteListing(getSites())
    return countryGrouped
  }

  return (
    <SitesContext.Provider
      value={{
        getSites,
        getFilters,
        setSites,
        setFilters,
        getLanguage,
        setLanguage,
        getSerach,
        setSerach,
        getSiteListing,
        setSiteListing,
        setPlzFilters,
        getPlzFilters,
      }}
    >
      {children}
    </SitesContext.Provider>
  )
}
export default SitesContext
export { SitesProvider }
