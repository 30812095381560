/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { ab } from "../theme"

const ShortNews = ({ heading, children }) => (
  <div
    sx={{
      variant: "spacingContainer",
      mt: ab({ _: 5, md: 6 }),
    }}
  >
    <div
      sx={{
        display: ab({ md: "flex" }),
        color: "text",
      }}
    >
      <h2
        sx={{
          flex: 1,
          my: ab({ _: 2, md: 0 }),
          py: ab({ md: 3 }),
          paddingRight: ab({ md: 4 }),
          variant: "styles.h2",
        }}
      >
        {heading}
      </h2>
      <div
        sx={{
          flex: 1,
          my: 0,
        }}
      >
        {children}
      </div>
    </div>
  </div>
)

ShortNews.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.node,
  disableHyphens: PropTypes.bool,
}

ShortNews.defaultProps = {
  disableHyphens: false,
}

export default ShortNews
