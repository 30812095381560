/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import PropTypes from "prop-types"

const Textarea = ({ label, name, value, placeholder, required, visible }) => {
  const [error, setError] = useState(false)

  const isInvalid = () => {
    setError(true)
    return
  }

  const labelValueInput = (
    <input
      type="hidden"
      name={"label----" + name}
      value={label ? label : placeholder}
    />
  )

  return (
    <div
      sx={{
        my: 3,
      }}
    >
      {label && (
        <label
          htmlFor={name}
          sx={{
            py: label ? 1 : 0,
          }}
        >
          {label}
          {required ? " * " : ""}
        </label>
      )}
      {labelValueInput}
      <textarea
        className="formField-textarea"
        sx={{
          variant: "form.input",
          width: "100%",
          backgroundColor: "secondaryBackground",
          borderRadius: 20,
          p: 3,
          resize: "none",
          minHeight: 150,
          border: error ? "solid #ff3454 2px" : "0 none",
        }}
        name={name}
        id={name}
        type="textarea"
        value={value}
        placeholder={placeholder}
        required={required && visible}
        onInvalid={isInvalid}
      />
    </div>
  )
}

Textarea.defaultProps = {
  visible: true,
}

Textarea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  visible: PropTypes.bool,
}

export default Textarea
