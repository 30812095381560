/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Button from "./button"

const Job = ({ description, location, link }) => {
  return (
    <tr>
      <td>{description}</td>
      <td>{location}</td>
      <td>
        <Button
          buttonText="PDF ANZEIGEN"
          link={link}
          download={true}
          linkTarget="_blank"
        />
      </td>
    </tr>
  )
}

Job.propTypes = {
  description: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default Job
