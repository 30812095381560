/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import { SearchIcon } from "../../assets/icons"
import { useState } from "react"
import ProductTable from "./product-table"
import { ab } from "../../theme"

const ProductSearch = ({
  data,
  popupText1,
  popupText2,
  popupText3,
  popupText4,
  popupText5,
  viewInfo,
  productPerPage,
  explanatory,
  children,
  updateDateText,
  updateDate,
  searchInfo,
  searchTableText,
  tableHeaderArticleNo,
  tableHeaderCertificate,
  tableHeaderCharge,
  tableHeaderHeavymetal,
  tableHeaderMicroOrganism,
  tableHeaderPesticides,
  tableHeaderProduct,
  tableHeaderRemark,
  moreChargesBtnText,
  lessChargesBtnText,
  notRelevantText,
}) => {
  const [error, setError] = useState(false)
  const [input, setInput] = useState({
    value: searchTableText,
  })

  const { rawColors: colors } = useThemeUI().theme

  const handleFocus = (e) => {
    let value = e.currentTarget.value
    if (value === searchTableText) {
      setInput({ value: "" })
    }
  }
  const handleBlur = (e) => {
    let value = e.currentTarget.value
    if (value === "") {
      setInput({ value: searchTableText })
    }
  }
  const handleInputChange = (e) => {
    let value = e.currentTarget.value
    setInput({
      ...input,
      value,
    })
  }
  const getInput = () => {
    let val = input.value || ""
    return val.toLowerCase()
  }
  return (
    <div>
      <div
        sx={{
          mt: 6,
          px: ab({ _: 3, md: 5 }),
        }}
      >
        {children}
      </div>
      <div
        sx={{
          bg: colors.modes["group"].primary,
        }}
      >
        <div
          sx={{
            display: "flex",
            py: "44px",
            mt: 5,
            px: ab({ _: 3, md: 6 }),
          }}
        >
          <div
            sx={{
              mt: "15px",
              mr: ab({ _: "0px", md: "24px" }),
              textAlign: "center",
              width: ab({ _: "32px", md: "64px" }),
              height: ab({ _: "32px", md: "64px" }),
              maxWidth: ab({ _: "32px", md: "64px" }),
              color: "#fff",
            }}
          >
            <SearchIcon style={{ fill: "#fff" }} />
          </div>
          <div
            sx={{
              flex: 2,
              alignSelf: "center",
              px: ab({ _: 2, md: 4 }),
            }}
          >
            <input
              type="text"
              placeholder="Nach Produkt oder Artikel-Nr. suchen"
              onFocus={handleFocus}
              onChange={handleInputChange}
              onBlur={handleBlur}
              value={input.value}
              sx={{
                width: "100%",
                backgroundColor: "transparent",
                border: 0,
                color: "#fff",
                fontSize: ab({ _: "14px", md: "24px" }),
                lineHeight: 1.8,
                outline: "none",
                borderBottom: error ? "solid #ff3454 2px" : "1px solid #fff",
              }}
            />
          </div>
        </div>
      </div>
      <ProductTable
        searchTerm={getInput()}
        csvData={data}
        popupText1={popupText1}
        popupText2={popupText2}
        popupText3={popupText3}
        popupText4={popupText4}
        popupText5={popupText5}
        productPerPage={productPerPage}
        viewInfo={viewInfo}
        explanatory={explanatory}
        updateDateText={updateDateText}
        updateDate={updateDate}
        searchInfo={searchInfo}
        searchTableText={searchTableText}
        tableHeaderArticleNo={tableHeaderArticleNo}
        tableHeaderCertificate={tableHeaderCertificate}
        tableHeaderCharge={tableHeaderCharge}
        tableHeaderHeavymetal={tableHeaderHeavymetal}
        tableHeaderMicroOrganism={tableHeaderMicroOrganism}
        tableHeaderPesticides={tableHeaderPesticides}
        tableHeaderProduct={tableHeaderProduct}
        tableHeaderRemark={tableHeaderRemark}
        moreChargesBtnText={moreChargesBtnText}
        lessChargesBtnText={lessChargesBtnText}
        notRelevantText={notRelevantText}
      />
    </div>
  )
}

export default ProductSearch
