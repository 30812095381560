/** @jsx jsx */
import { jsx } from "theme-ui"
import { useContext } from "react"
import { ab } from "../theme"
import { ModalContext } from "../context/modal-context"
import { CloseIcon } from "../assets/icons"

import ReactDOM from "react-dom"

const Modal = () => {
  let { modalContent, handleModal, modal } = useContext(ModalContext) || ""

  if (modal) {
    return ReactDOM.createPortal(
      <div
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          position: "fixed",
          zIndex: 106,
          top: 0,
          animationName: modal ? "FadeIn" : "FadeOut",
          animationDuration: "300ms",
          animationFillMode: "forwards",
          "@keyframes FadeIn": {
            "0%": {
              width: "0vw",
              height: "0vh",
              opacity: 0,
            },
            "1%": {
              width: "100vw",
              height: "100vh",
            },
            "30%": {
              opacity: 0.5,
            },
            "100%": {
              opacity: 1,
              width: "100vw",
              height: "100vh",
            },
          },
          "@keyframes FadeOut": {
            form: {
              opacity: 1,
              width: "100vw",
              height: "100vh",
            },
            to: {
              opacity: 0,
              zIndex: -99,
              width: "0vw",
              height: "0vh",
            },
          },
        }}
        role="button"
        tabIndex="0"
        onClick={() => handleModal(false)}
      >
        <div
          sx={{
            width: ab({ _: "80%", md: "40%" }),
            position: "relative",
            left: ab({ _: "10%", md: "30%" }),
            top: "20%",
            textAlign: "center",
          }}
        >
          <div
            sx={{
              width: 32,
              height: 32,
              maxWidth: 34,
              cursor: "pointer",
              position: "absolute",
              top: 0,
              right: 0,
              bg: "#fff",
              zIndex: 2,
            }}
            onClick={() => handleModal()}
          >
            <CloseIcon />
          </div>
          <p>{modalContent}</p>
        </div>
      </div>,
      document.querySelector("#modal-root")
    )
  } else return null
}

export default Modal
