import React, { useState } from "react"
import PropTypes from "prop-types"

const defaultState = {
  level: 0,
  setMainItemIndex: () => {},
  resetLevel: () => {},
  openNavigation: () => {},
  closeNavigation: () => {},
  closeLayer: () => {},
  isSelected: () => {},
}

const NavigationContext = React.createContext(defaultState)

const NavigationProvider = ({ children }) => {
  const [mainItemIndex, setMainItemIndex] = useState(-1)
  const [level, setLevel] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const openNavigation = () => {
    setIsOpen(true)
  }

  const closeNavigation = () => {
    setMainItemIndex(-1)
    setLevel(0)
    setIsOpen(false)
  }

  const closeLayer = () => {
    setLevel(Math.max(0, level - 1))
    if (level === 0) {
      setIsOpen(false)
    }
  }

  const resetLevel = (level) => setLevel(level)
  const isSelected = (index) => index === mainItemIndex
  const currentLevel = level

  return (
    <NavigationContext.Provider
      value={{
        level,
        currentLevel,
        setMainItemIndex,
        resetLevel,
        openNavigation,
        closeNavigation,
        closeLayer,
        isSelected,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

NavigationProvider.propTypes = {
  children: PropTypes.node,
}

export default NavigationContext

export { NavigationProvider }
