/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Link from "./link"
import { ab } from "../theme"

const Footer = ({
  footerItems,
  language,
  footerSocialMediaIconsText,
  footerSocialMediaItems,
  children,
}) => {
  const cpYear = () => new Date().getFullYear()

  return (
    <footer
      sx={{
        variant: "innerSpacingContainer",
      }}
    >
      <div
        sx={{
          display: "flex",
          fontSize: 1,
          textAlign: ab({ _: "center", md: "left" }),
          flexDirection: ab({ _: "column", md: "row" }),
          justifyContent: "space-between",
        }}
      >
        {children && (
          <div
            className="footer-information"
            sx={{
              variant: "guc.footer.info",
            }}
          >
            {children}
          </div>
        )}
        <div
          sx={{
            flex: ab({ _: 2, lg: 1 }),
            paddingTop: ab({ _: 40, md: 0 }),
            color: "text",
            order: ab({ _: 3, md: 1 }),
            variant: "guc.footer.copyright",
          }}
        >
          &copy; {cpYear()} GBA Group
        </div>
        <div
          sx={{
            display:
              footerSocialMediaItems && footerSocialMediaItems.length
                ? "flex"
                : "none",
            flexDirection: ab({ _: "column", md: "row" }),
            alignSelf: ab({ _: "center", md: "flex-end" }),
            order: ab({ _: 1, md: 2 }),
            mb: ab({ _: 3, md: 0 }),
            mt: "-2px",
            ul: {
              m: 0,
              p: 0,
              pl: ab({ md: "10px" }),
              pt: ab({ _: "5px", md: "0" }),
            },
            h3: {
              m: 0,
              p: 0,
              fontWeight: "normal",
            },
            li: {
              listStyle: "none",
              display: "inline-block",
              px: "5px",
            },
            img: {
              maxWidth: "32px",
            },
          }}
        >
          <h3>{footerSocialMediaIconsText}</h3>
          <ul>
            {footerSocialMediaItems &&
              footerSocialMediaItems.map((icon, index) => {
                let link
                if (icon.link && icon.link.url && icon.image) {
                  link = (
                    <li key={index}>
                      <Link
                        language={language}
                        to={icon.link.url}
                        target="_blank"
                      >
                        <img src={icon.image} alt={icon.altText} />
                      </Link>
                    </li>
                  )
                }

                return link
              })}
          </ul>
        </div>
        <div
          sx={{
            textAlign: "right",
            flex: 4,
            order: ab({ _: 2, md: 3 }),
          }}
        >
          {footerItems &&
            footerItems.map((footerItem, index) => (
              <Link
                language={language}
                to={
                  footerItem.url
                    ? footerItem.url.linktype === "story"
                      ? footerItem.url.id
                      : footerItem.url.cached_url
                    : ""
                }
                sx={{ variant: "styles.footerlink" }}
                key={index}
              >
                {footerItem.displayName}
              </Link>
            ))}
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  footerItems: PropTypes.array,
  footerSocialMediaItems: PropTypes.array,
  footerSocialMediaIconsText: PropTypes.string,
  children: PropTypes.node,
}

Footer.defaultProps = {
  footerItems: [],
  language: "de",
}

export default Footer
