/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import PropTypes from "prop-types"
import { useState, useEffect } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { TransformLinkUri } from "./link"

import LanguageSwitcher from "./language-switcher"
import SearchTriggerConsumer from "./search-trigger-consumer"
import Link from "./link"
import { ab } from "../theme"
import Icon from "../assets/icon"

const Header = ({
  logos,
  currentTheme,
  currentMode,
  currentLanguage,
  allLanguage,
  currentURL,
  locationText,
  locationLink,
}) => {
  const defaultLang = process.env.GATSBY_DEFAULT_LANG
    ? process.env.GATSBY_DEFAULT_LANG
    : "de"

  const { rawColors: colors } = useThemeUI().theme

  const logo = logos["default"]

  const departmentLogo = logos[currentMode]
    ? logos[currentMode]
    : logos["default"]

  const [mobileVisible, setMobileVisible] = useState(true)
  const [prev, setPrev] = useState(0)
  const [current, setCurrent] = useState(0)
  let theme = currentTheme === undefined ? false : currentTheme

  useEffect(() => {
    const handleNavigation = (e) => {
      const window = e.currentTarget
      if (window.scrollY > 0) {
        setCurrent(window.scrollY)
      }
    }
    window.addEventListener("scroll", (e) => handleNavigation(e), false)
  }, [])

  useEffect(() => {
    if (prev > current) {
      setMobileVisible(true)
      setPrev(current)
    } else if (prev < current) {
      setMobileVisible(false)
      setPrev(current)
    }
  }, [prev, current])

  let homeLink =
    typeof logo.home === "object"
      ? TransformLinkUri(logo.home.id, "", currentLanguage)
      : currentLanguage === defaultLang
        ? logo.home
        : `/${currentLanguage}${logo.home}`

  let departmentLink =
    typeof departmentLogo.home === "object"
      ? TransformLinkUri(departmentLogo.home.id, "", currentLanguage)
      : currentLanguage === "de"
        ? departmentLogo.home
        : `/${currentLanguage}${departmentLogo.home}`

  let modeIcon =
    currentMode !== "group" ? (
      <span
        sx={{
          background: colors.modes[currentMode]
            ? colors.modes[currentMode].primary
            : colors.modes.group.primary,
          height: "73px",
          width: "73px",
          display: "inline-block",
          padding: "15px ",
        }}
      >
        {" "}
        <Icon
          style={{
            fill: "#fff",
            maxWidth: "100%",
            mt: "5px",
          }}
          icon={currentMode}
        />
      </span>
    ) : (
      ""
    )

  return (
    <header
      sx={{
        variant: theme ? `${theme}.header` : "",
        transition: "all 300ms",
        transform: ab({
          _: mobileVisible ? "translateY(0%)" : "translateY(-100%)",
          md: "none",
        }),
      }}
      className="page-header"
    >
      <div
        sx={{
          variant: "logo.container",
        }}
        className="page-logo-container"
      >
        <div sx={{ display: ab({ _: "inline", md: "flex" }) }}>
          <Link to={homeLink} language={currentLanguage}>
            <span
              className="logo-inner-link"
              sx={{
                variant: "logo.img",
                padding: ab({ md: "5px 20px" }),
                "@media (max-width:390px)": {
                  maxWidth: "130px",
                  transform: "scale(.8) translateX(-20px)",
                },
                "@media (max-width:340px)": {
                  maxWidth: "105px",
                  transform: "scale(.7) translateX(-30px)",
                },
              }}
            >
              <GatsbyImage
                image={logo.fixed}
                alt="Logo"
                imgStyle={{ maxWidth: "100%" }}
                sx={{
                  "[aria-hidden='true'] ": {
                    backgroundColor: "#fff !important",
                  },
                }}
              />
            </span>
          </Link>

          <div
            sx={{
              height: "60px",
              width: "60px",
              display: ab({
                _: "none",
                md: currentMode === "group" ? "none" : "block",
              }),
            }}
          >
            <Link to={departmentLink} language={currentLanguage}>
              {modeIcon}
            </Link>
          </div>
        </div>
        <div sx={{ variant: "header.navigation" }}>
          <div
            className="language-switcher"
            sx={{ variant: "header.languageSwitcher" }}
          >
            <LanguageSwitcher
              locationText={locationText}
              locationLink={locationLink}
              currentLanguage={currentLanguage}
              currentURL={currentURL}
              allLanguage={allLanguage}
            />
          </div>
          <div
            sx={{
              variant: "header.searchTrigger",
              "& >div": {
                mr: "10px",
              },
            }}
            className="search-trigger-container"
          >
            <SearchTriggerConsumer />
          </div>
        </div>
      </div>
    </header>
  )
}
Header.propTypes = {
  logos: PropTypes.object.isRequired,
  currentMode: PropTypes.oneOf([
    "default",
    "group",
    "pharma",
    "food",
    "environment",
    "contact",
    "cosmetics",
    "veterinary",
    "sustainabilityServices",
  ]).isRequired,
  currentURL: PropTypes.string.isRequired,
  locationLink: PropTypes.object,
  locationText: PropTypes.string,
}

Header.defaultProps = {
  currentMode: "default",
}

export default Header
