/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

const Quote = ({ quote, author, date }) => {
  if (date) {
    const options = { year: "numeric", month: "numeric", day: "numeric" }
    date = new Date(date).toLocaleDateString("de-DE", options)
  }

  if (author && date) {
    date = ", " + date
  }

  return (
    <div
      sx={{
        variant: "spacingContainer",
      }}
    >
      <div
        sx={{
          variant: "styles.h1",
        }}
      >
        <q>{quote}</q>
      </div>
      <div
        sx={{
          variant: "quote.author",
        }}
      >
        {author}
        {date}
      </div>
    </div>
  )
}

Quote.propTypes = {
  quote: PropTypes.string,
  author: PropTypes.string,
  date: PropTypes.string,
}

Quote.defaultProps = {
  quote: "",
  author: "",
  date: "",
}

export default Quote
