/** @jsx jsx */
import { jsx } from "theme-ui"

const FoodIcon = ({ style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 500 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
  >
    <path
      sx={style}
      d="M250 0C111.92 0 0 111.93 0 250.01c0 97 55.35 180.91 136.1 222.36-26.62-38.43-42.45-91.17-42.45-133.28 0-82.71 71.33-130.26 146.41-96.31 24.28-62.98 44.89-92.59 44.89-92.59 2.63-3.48 8.01-4.8 11.94-2.98l27.88 13.02c3.95 1.87 4.32 5.5.85 8.13 0 0-23.1 17.38-34.88 30.93-13.92 15.96-24.93 32.62-31.9 44.22 83.35-36.77 147.5 12.14 147.5 95.58 0 41.41-14.22 93.41-39.44 131.83C446.04 428.95 500 345.82 500 250.01 500 111.93 388.08 0 250 0zm-19.35 196.85c-.34 4.32-4.16 8.15-8.54 8.54 0 0-33.78 2.78-57.33-20.76-23.59-23.53-20.73-57.36-20.73-57.36.36-4.33 4.19-8.18 8.52-8.52 0 0 33.71-2.82 57.33 20.76 23.54 23.55 20.75 57.34 20.75 57.34z"
    />
  </svg>
)

export default FoodIcon
