/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import PropTypes from "prop-types"

import { ab } from "../../theme"

const Radiobutton = ({ name, label, radioButtons, required, visible }) => {
  const [error, setError] = useState(false)
  const isInvalid = () => {
    setError(true)
    return
  }

  const radioButtonList = (radios) => {
    if (!radios) {
      radios = []
    }
    let list = []
    radios.forEach(function (radio) {
      list.push(radio.value)
    })
    return list
  }
  const labelValueInput = (
    <input type="hidden" name={"label----" + name} value={label} />
  )

  return (
    <div
      sx={{ border: error ? "solid #ff3454 2px" : "0 none", borderRadius: 24 }}
    >
      {labelValueInput}
      <div sx={{ pt: 4, pr: 2 }}>
        <strong>{label}</strong>
      </div>
      <div sx={{ display: ab({ _: "block", md: "flex" }), flexWrap: "wrap" }}>
        {radioButtonList(radioButtons).map((radio, idx) => (
          <div
            key={idx}
            sx={{
              variant: "form.formField",
              backgroundColor: "trasnsparent",
              flex: 1,
              minWidth: "25%",
            }}
          >
            <label
              htmlFor={radio}
              sx={{
                display: "block",
              }}
            >
              <span
                sx={{
                  paddingLeft: 30,
                }}
              >
                <input
                  sx={{
                    position: "absolute",
                    opacity: 0,
                    width: "100%",
                    left: 0,
                    height: "80%",
                    cursor: "pointer",
                    zIndex: 5,
                    "&:checked": {
                      "& ~ span": {
                        display: "block",
                      },
                    },
                  }}
                  type="radio"
                  value={radio}
                  name={name}
                  id={name}
                  required={required && visible}
                  onInvalid={isInvalid}
                />
                <span
                  className="mark"
                  sx={{
                    variant: "form.mark",
                    top: "16px",
                    borderRadius: "50%",
                  }}
                ></span>
                <span
                  className="radio"
                  sx={{
                    variant: "form.radio",
                    display: "none",
                  }}
                ></span>
                <div
                  sx={{
                    display: "inline-block",
                    width: "calc(100% - 30px)",
                    paddingTop: "5px",
                  }}
                >
                  {radio}
                  {required
                    ? radioButtonList(radioButtons).length === 1
                      ? " * "
                      : ""
                    : ""}
                </div>
              </span>
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

Radiobutton.defaultProps = {
  visible: true,
}

Radiobutton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  visible: PropTypes.bool,
}

export default Radiobutton
