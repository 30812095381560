/** @jsx jsx */
import { jsx } from "theme-ui"

const PharmaIcon = ({ style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 500 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
  >
    <path
      sx={style}
      d="M247.25,0.02C109.18,1.53-1.5,114.69,0.02,252.76c0.71,64.6,26.07,123.01,66.77,166.93c-5.33-17.07-4.34-34.41,4.39-48.72
  	l69.97-114.77l151.96,92.64l-69.99,114.77c-9.04,14.83-24.87,23.57-43.32,26.33c23.12,6.76,47.6,10.33,72.94,10.05
  	c138.07-1.52,248.76-114.67,247.24-252.72C498.47,109.19,385.32-1.5,247.25,0.02z M378.84,208.23l-69.99,114.79L156.9,230.37
  	l69.99-114.79c19.33-31.71,69.16-36.74,111.04-11.21C379.83,129.93,398.18,176.52,378.84,208.23z"
    />
  </svg>
)

export default PharmaIcon
