/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

const AlternativeBackground = ({ children, bgColor }) => (
  <div
    className="alt-bg"
    sx={{
      backgroundColor: bgColor ? bgColor : "secondaryBackground",
      pb: 4,
      "& h2": {
        py: 5,
      },
      "& > div": {
        my: 0,
      },
      variant: "guc.alternativeBackground",
    }}
  >
    {children}
  </div>
)

AlternativeBackground.propTypes = {
  children: PropTypes.node.isRequired,
  bgColor: PropTypes.string,
}

export default AlternativeBackground
