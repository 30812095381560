/** @jsx jsx */
import { jsx } from "theme-ui"

const GroupIcon = ({ style = {} }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 500 500"
  >
    <path
      sx={{
        display: "none",
      }}
      d="M247.2,0C109.2,1.5-1.5,114.7,0,252.8c0.7,64.6,26.1,123,66.8,166.9c-5.3-17.1-4.3-34.4,4.4-48.7l70-114.8
	l152,92.6l-70,114.8c-9,14.8-24.9,23.6-43.3,26.3c23.1,6.8,47.6,10.3,72.9,10c138.1-1.5,248.8-114.7,247.2-252.7
	C498.5,109.2,385.3-1.5,247.2,0z M378.8,208.2l-70,114.8l-152-92.7l70-114.8c19.3-31.7,69.2-36.7,111-11.2
	C379.8,129.9,398.2,176.5,378.8,208.2z"
    />
    <path
      sx={{
        display: "none",
      }}
      d="M250,0C111.9,0,0,111.9,0,250c0,112.5,74.3,207.6,176.5,239c-19.1-15-34.1-35.5-42.6-59
	c-20.5-56.9,6.8-96.4,35.4-131.9c20.4-25.3,66.6-83.3,78-97.6c1.3-1.7,3.9-1.7,5.2,0c11.8,14.8,60.2,75.8,78.4,97.9
	c21.8,26.5,42.2,55.9,42.2,93c0,39.2-18.3,74.1-46.8,96.7C427.1,455.7,500,361.4,500,250C500,111.9,388.1,0,250,0z M356.4,253.7
	c-20.4-1.4-37.6-16.1-44.6-35.6c-8.5-24,2.8-40.6,14.7-55.6c8.5-10.7,27.6-35.1,32.4-41.2c0.5-0.7,1.6-0.7,2.1,0
	c4.9,6.3,25,32,32.5,41.3c9,11.2,17.5,23.6,17.5,39.2C411.1,231.7,386.3,255.8,356.4,253.7z"
    />
    <g>
      <g>
        <path
          sx={{
            ...style,
          }}
          d="M265.3,196.5c-8.6-7.1-21-7.1-29.6,0L78,328c-2,1.3-4,1.9-6,1.7c-2.2-0.3-3.9-1.2-5.2-2.7l-15.5-18.5
			c-1.3-1.7-1.9-3.6-1.8-5.9c0.2-2.2,1.1-4,2.8-5.4l179.2-149.3c5.3-4.3,11.6-6.5,18.9-6.5s13.6,2.2,18.9,6.5l179.2,149.3
			c1.7,1.3,2.6,3.1,2.7,5.4c0.2,2.3-0.4,4.2-1.7,5.9L434.2,327c-1.3,1.5-3.1,2.4-5.2,2.7h-0.8c-2.2,0-3.9-0.6-5.2-1.7L265.3,196.5z
			 M500.8,250.3C500.8,112.1,388.7,0,250.5,0S0.2,112.1,0.2,250.3c0,84.8,42.2,159.7,106.7,204.9V345.3c0-0.2,0-0.4,0.1-0.7
			c0.1-0.3,0.1-0.6,0.1-0.8l128.6-106c8.5-7,20.9-7,29.4,0l128.6,106c0.2,0.3,0.3,0.8,0.3,1.5v109.9
			C458.6,409.9,500.8,335.1,500.8,250.3"
        />
      </g>
      <g>
        <path
          sx={{
            ...style,
          }}
          d="M218.6,385.2v113.2c10.5,1.3,21.1,2.1,31.9,2.1s21.5-0.8,31.9-2.1V385.2H218.6z"
        />
      </g>
    </g>
  </svg>
)

export default GroupIcon
