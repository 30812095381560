/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { useState, useEffect } from "react"
import { groupColor } from "../theme"

const BackButton = ({ children }) => {
  const [goBack, setGoBack] = useState(false)

  useEffect(() => {
    if (goBack) {
      window.history.back()
    }
  }, [goBack])
  return (
    <div
      sx={{
        variant: "alternatingSpacingContainer",
      }}
      onClick={() => setGoBack(true)}
    >
      <span
        sx={{
          cursor: "pointer",
          color: groupColor,
          textDecoration: "underline",
        }}
      >
        {children}
      </span>
    </div>
  )
}
BackButton.propTypes = {
  children: PropTypes.string.isRequired,
}

export default BackButton
