/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

const FormInfo = ({ children }) => {
  return (
    <div
      sx={{
        mt: 4,
        flex: 1,
        "& p": {
          lineHeight: "1.7",
        },
      }}
    >
      {children}
    </div>
  )
}

FormInfo.propTypes = {
  children: PropTypes.node,
}

export default FormInfo
