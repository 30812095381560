/** @jsx jsx */
import { useContext } from "react"
import { jsx, useThemeUI } from "theme-ui"

import ModeContext from "../context/mode-context"
import PropTypes from "prop-types"
import Link from "./link"
import Image from "./image"

function FullWidthSlide({
  children,
  image,
  imageAlt,
  subHeading,
  heading,
  buttonLink,
  buttonText,
  mode,
  linkTarget,
  currentLanguage,
  disableHyphens,
  textOnTop,
}) {
  const { mode: globalMode } = useContext(ModeContext)
  const { rawColors: colors } = useThemeUI().theme

  const modeKey = mode ? mode : globalMode

  let hyphensStyling = {}
  if (disableHyphens) {
    hyphensStyling = {
      hyphens: "none",
      wordWrap: "normal",
    }
  }

  return (
    <div
      sx={{
        position: "relative",
        overflow: "hidden",
        ...hyphensStyling,
        variant: "guc.fullWidthSlide",
        mt: textOnTop ? "80px" : " 0",
      }}
    >
      <div
        sx={{
          color: "#fff",
          position: "relative",
        }}
      >
        <Image
          image={image}
          alt={imageAlt ? imageAlt : heading}
          style={{
            height: ["50vh", "100vh"],
            width: "100%",
            display: "block",
          }}
        />
      </div>
      <div
        className="fullwidth-slide--text-container"
        data-mode={mode}
        sx={{
          position: ["relative", "absolute"],
          bottom: textOnTop ? "auto" : [0, "90px"],

          left: [0, 4],
          top: textOnTop ? ["-10px", "-60px"] : [-10, "auto"],
          p: 4,
          bg: modeKey
            ? colors.modes[modeKey].primary
            : colors.modes["group"].primary,
          maxWidth: ["100%", "350px", "450px"],
          zIndex: [0, 3],
        }}
      >
        <div
          sx={{
            color: "#fff",
          }}
        >
          <h6
            sx={{
              m: 0,
              p: 0,
              fontSize: "14px",
              letterSpacing: 2,
              fontWeight: 1,
              textTransform: "uppercase",
            }}
          >
            {subHeading}
          </h6>
          <h2
            sx={{
              fontSize: 4,
              my: 1,
            }}
          >
            {heading}
          </h2>
          <div
            sx={{
              m: 0,
              p: 0,

              "& p:first-of-type": {
                fontWeight: 1,
                fontSize: "14px",
                mt: 0,
              },
            }}
          >
            {children}
          </div>
          <div
            sx={{
              marginBottom: [5, 0],
              marginTop: 4,
              display: buttonText ? "block" : "none",
            }}
          >
            <Link
              to={buttonLink}
              target={linkTarget}
              language={currentLanguage}
              sx={{
                variant: "styles.ghostButton",
                color: "#fff",
                border: "solid 1px #fff",
                py: 2,
                px: 4,
                "&:hover": {
                  bg: "#fff",
                  color: modeKey
                    ? colors.modes[modeKey].primary
                    : colors.modes["group"].primary,
                },
              }}
            >
              {buttonText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

FullWidthSlide.propTypes = {
  children: PropTypes.node,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  imageAlt: PropTypes.string,
  subHeading: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  mode: PropTypes.oneOf([
    "",
    "pharma",
    "food",
    "environment",
    "career",
    "construction",
    "medical",
    "lifeScience",
    "agroChemical",
    "sustainabilityServices",
    "none",
  ]),
  linkTarget: PropTypes.string,
  disableHyphens: PropTypes.bool,
}

FullWidthSlide.defaultProps = {
  mode: "",
  disableHyphens: false,
}

export default FullWidthSlide
