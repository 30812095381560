/** @jsx jsx */
import { jsx } from "theme-ui"

const HusServiceIcon = ({ style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 162.55 162.55"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
  >
    <g id="Ebene_1-2" data-name="Ebene 1">
      <g>
        <path
          style={style}
          d="m131.56,108.8c-15.54,0-23.55-10.25-29.98-18.49-6.78-8.67-11.4-13.99-20.76-13.99s-13.98,5.32-20.76,13.99c-6.43,8.24-14.44,18.49-29.98,18.49S6.53,98.55.09,90.31c-.02-.02-.04-.05-.05-.07.89,8.07,2.95,15.79,6.02,22.98.65,1.51,1.36,3,2.09,4.46.39.5.78,1,1.16,1.48,6.78,8.67,11.4,13.99,20.76,13.99s13.98-5.32,20.76-13.99c6.43-8.24,14.44-18.49,29.98-18.49s23.55,10.25,29.98,18.49c6.78,8.67,11.4,13.99,20.76,13.99s13.98-5.32,20.76-13.99c.39-.51.8-1.02,1.2-1.54.73-1.45,1.41-2.92,2.05-4.41,3.07-7.19,5.14-14.91,6.02-22.98-.02.03-.04.05-.06.07-6.43,8.24-14.44,18.49-29.98,18.49Z"
        />
        <path
          style={style}
          d="m30.07,61.03c9.36,0,13.98-5.32,20.76-13.99,6.43-8.24,14.44-18.49,29.98-18.49s23.55,10.25,29.98,18.49c6.78,8.67,11.4,13.99,20.76,13.99s13.98-5.32,20.76-13.99c.45-.58.91-1.17,1.38-1.76C140.42,18.45,112.78,0,80.82,0S21.21,18.45,7.93,45.28c.47.59.93,1.18,1.38,1.76,6.78,8.67,11.4,13.99,20.76,13.99Z"
        />
        <path
          style={style}
          d="m101.57,126.37c-6.78-8.67-11.4-13.99-20.76-13.99s-13.98,5.32-20.76,13.99c-6.42,8.22-14.4,18.43-29.87,18.48,4.31,3.43,8.97,6.44,13.93,8.95,11.03,5.59,23.5,8.74,36.7,8.74,1.2,0,2.4-.03,3.59-.08,8.99-.39,17.59-2.24,25.58-5.31,7.8-3,15.02-7.18,21.45-12.3-15.46-.05-23.45-10.27-29.86-18.48Z"
        />
        <path
          style={style}
          d="m131.56,72.73c-15.54,0-23.55-10.25-29.98-18.49-6.78-8.67-11.4-13.99-20.76-13.99s-13.98,5.32-20.76,13.99c-6.43,8.24-14.44,18.49-29.98,18.49-13.32,0-21.1-7.53-27.1-14.86-1.43,4.75-2.43,9.69-2.97,14.76,3.67,3.25,6.63,7.03,9.31,10.47,6.78,8.67,11.4,13.99,20.76,13.99s13.98-5.32,20.76-13.99c6.43-8.24,14.44-18.49,29.98-18.49s23.55,10.25,29.98,18.49c6.78,8.67,11.4,13.99,20.76,13.99s13.98-5.32,20.76-13.99c2.69-3.44,5.65-7.22,9.32-10.47-.54-5.08-1.54-10.01-2.97-14.76-6,7.34-13.79,14.87-27.11,14.87Z"
        />
      </g>
    </g>
  </svg>
)

export default HusServiceIcon
