/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import { useState, useContext } from "react"
import PropTypes from "prop-types"

import NavigationContext from "../../context/navigation-context"

import Link from "../link"
import Item from "./item"
import Icon from "../../assets/icon"

import { ab } from "../../theme"

const Layer = ({
  parent,
  parentUrl,
  items,
  extras,
  language,
  level,
  mode,
  location,
  closeSubNavi,
  parentIcon,
  itemExtras,
  hideParentLink,
}) => {
  const { currentLevel, closeNavigation, closeLayer } =
    useContext(NavigationContext)

  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [subLayer, setSubLayer] = useState(null)
  const [aniState, setAniState] = useState(true)

  const width = 300
  const widthMd = 225

  const positionStyle = `-${level * width}px`
  const positionStyleMd = `-${level * widthMd}px`

  const isSelected = (index) => selectedIndex === index

  const { rawColors: colors } = useThemeUI().theme

  let extrasList =
    extras && extras.length > 0 ? extras : itemExtras ? itemExtras : false

  return (
    <div
      className="navigation-layer"
      sx={{
        variant: "navigation.layer",
        display: ab({
          _: level < 3 ? "block" : "none",
          md: currentLevel >= level ? "block" : "none",
        }),
        position: "fixed",
        width: ab({ _: "auto", md: widthMd, lg: `${width}px` }),
        top: 0,
        right: ab({ _: 0, md: positionStyleMd, lg: positionStyle }),
        bottom: 0,
        left: ab({ _: 0, md: "auto" }),
        overflowY: ab({ _: "hidden", md: "visible" }),
        pb: "30px",
        backgroundColor: colors.navigation[mode],
        animationName: ab({ _: aniState ? "slideIn" : "slideOut", md: "none" }),
        animationDuration: "400ms",
        animationFillMode: "forwards",

        "& a": {
          py: "22px",
        },

        ".navigation-layer": {
          height: ab({ _: "100%", md: "auto" }),
          top: ab({ _: "0", md: "0" }),
          "& a": {
            py: "0px",
            my: "20px",
          },
          ".current": {
            fontWeight: "bold",
          },
        },

        "& ul": {
          mx: 0,
          px: 0,
        },
        a: {
          fontSize: ab({ _: "14px", md: "13px", lg: "14px" }),
          flexGrow: level === 1 ? 1 : "0",
        },
        "& li": {
          pl: ab({ _: "35px", md: "15px", lg: "35px" }),
          "& a": {
            color: "#fff",
            textDecoration: "none",
          },
        },

        fontSize: 1,

        "@keyframes slideIn": {
          "0%": {
            visibility: "hidden",
            transform: "translateX(300px)",
            opacity: 0,
          },
          "1%": {
            visibility: "visible",
          },
          "30%": {
            opacity: "1",
          },
          to: {
            visibility: "visible",
            transform: "translateX(0px)",
          },
        },

        "@keyframes slideOut": {
          "0%": {
            visibility: "visible",
            transform: "translateX(0px)",
            opacity: 1,
          },
          "70%": {
            opacity: "0",
          },
          "99%": {
            visibility: "visible",
          },
          "100%": {
            visibility: "hidden",
            transform: `translateX(300px)`,
            width: 0,
          },
        },
      }}
    >
      <div
        sx={{
          backgroundColor: colors.navigation.group,
          display: level > 1 ? ab({ _: "flex", md: "none" }) : "flex",
          p: "20px",
        }}
      >
        <div
          className="back-button"
          sx={{
            display: level > 1 ? ab({ _: "block", md: "none" }) : "none",
            alignSelf: "left",
            backgroundColor: colors.navigation.group,
          }}
        >
          <button
            onClick={() => {
              setAniState(false)
              setSelectedIndex(-1)
              closeSubNavi()
              closeLayer()
            }}
            sx={{
              all: "unset",
              color: "#fff",
              cursor: "pointer",
            }}
          >
            <span
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                sx={{
                  display: "inline-block",
                  width: "15px",
                  height: "15px",
                  mr: "6px",
                  mt: "-4px",
                }}
              >
                <Icon
                  style={{
                    fill: "#fff",
                    display: "inline",
                  }}
                  icon="left"
                />
              </span>
              <span>Zurück</span>
            </span>
          </button>
        </div>

        <div
          className="close-layer"
          sx={{
            display: level > 1 ? ab({ _: "block", md: "none" }) : "block",
            alignSelf: "right",
            flexGrow: "1",
            textAlign: "right",
          }}
        >
          <button
            onClick={() => {
              setSelectedIndex(-1)
              closeSubNavi()
              closeNavigation()
            }}
            sx={{
              all: "unset",
              color: "#fff",
              cursor: "pointer",
            }}
          >
            <span
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                sx={{
                  color: "#fff",
                }}
              >
                Close
              </span>
              <span
                sx={{
                  display: "inline-block",
                  width: "16px",
                  height: "16px",
                  ml: "10px",
                  mt: "-4px",
                }}
              >
                <Icon
                  style={{
                    fill: "#fff",
                    display: "inline",
                  }}
                  icon="close"
                />
              </span>
            </span>
          </button>
        </div>
      </div>

      <div
        sx={{
          height: "100%",
          pt: ab({ _: "20px", md: "120px" }),
          pb: "30px",
          overflow: "auto",
        }}
      >
        <ul
          sx={{
            margin: 0,
            padding: 0,
            pt: level === 1 ? "10px" : 0,
            ".parent-link": {
              py: "20px",
            },
          }}
        >
          {!hideParentLink && (
            <li
              className="parent-link"
              sx={{
                pl: "1em",
                //fontWeight: isSelected ?  "bold" :"normal",
                borderBottom: ab({ _: "1px ##2c26261f solid", md: "none" }),
              }}
            >
              <div
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <div
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    sx={{
                      display: "inline-block",
                    }}
                  >
                    <Link
                      to={parentUrl}
                      language={language}
                      activeStyle={{
                        fontWeight: "bold",
                      }}
                      activeClassName="current"
                      sx={{
                        variant: "navigation.hover",
                      }}
                    >
                      {parent}
                    </Link>
                  </span>
                </div>
                <div
                  sx={{
                    display:
                      level > 1 ? ab({ _: "block", md: "none" }) : "none",
                    alignSelf: "flex-end",
                  }}
                >
                  <button
                    onClick={() => {
                      setAniState(false)
                      setSelectedIndex(-1)
                      closeSubNavi()
                      closeLayer()
                    }}
                    sx={{
                      all: "unset",
                      color: "#fff",
                      cursor: "pointer",
                      mr: "20px",
                    }}
                  >
                    <div
                      className="toggleIcon"
                      sx={{
                        transform: ab({
                          _: level > 2 ? "rotate(0deg)" : "rotate(-90deg)",
                          md: "rotate(0)",
                        }),
                        height: 20,
                        width: 20,
                        zIndex: 0,
                        transition: "all .3s",
                        flexShrink: "0",
                      }}
                    >
                      <Icon
                        style={{ fill: colors.navigation.icon }}
                        icon="right"
                      />
                    </div>
                  </button>
                </div>
              </div>
            </li>
          )}
          {items &&
            items.length > 0 &&
            items.map((item, index) => (
              <li
                key={`layer-item-${index}`}
                className={`${isSelected ? "selected" : "notselected"}`}
                sx={{
                  pl: "1em",
                  backgroundColor: isSelected(index)
                    ? colors.navigation[item.mode]
                    : "transparent",
                  "&:hover": {
                    backgroundColor: colors.navigation[item.mode],
                  },
                }}
                onClick={() => setSelectedIndex(index)}
              >
                <Item
                  item={item}
                  level={level + 1}
                  language={language}
                  mode={mode}
                  selected={isSelected(index)}
                  hasChild={!!(item.children && item.children.length > 0)}
                  location={location}
                  setSelected={() => setSelectedIndex(index)}
                  setSubLayer={setSubLayer}
                  extras={extras}
                />
              </li>
            ))}
        </ul>
        {subLayer}
        {extrasList && (
          <ul
            className="extra-navigation"
            sx={{
              mt: "2rem",
              pt: "2rem",
              "::before": {
                content: "''",
                position: "relative",
                left: "35px",
                zIndex: -1,
                transform: "translateY(-1.5rem)",
                height: "2px",
                width: "80px",
                bg: "#fff",
                display: "block",
              },
            }}
          >
            {extrasList.map((item, index) => (
              <li key={`layer-extra-item-${index}`}>
                <span
                  sx={{
                    display: "inline-block",
                  }}
                >
                  <Item
                    item={item}
                    level={level + 1}
                    language={language}
                    mode={mode}
                    selected={isSelected(index)}
                    hasChild={false}
                    location={location}
                    setSelected={() => setSelectedIndex(index)}
                    setSubLayer={setSubLayer}
                  />
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

Layer.propTypes = {
  parent: PropTypes.string,
  parentUrl: PropTypes.string,
  items: PropTypes.array,
  extras: PropTypes.array,
  language: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  mode: PropTypes.oneOf([
    "group",
    "pharma",
    "food",
    "environment",
    "career",
    "sustainabilityServices",
  ]).isRequired,
  location: PropTypes.object.isRequired,
  closeSubNavi: PropTypes.func,
}

Layer.defaultProps = {
  closeSubNavi: () => {},
}

export default Layer
