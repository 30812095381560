/** @jsx jsx */
import { useContext } from "react"
import { jsx, useThemeUI } from "theme-ui"
import PropTypes from "prop-types"

import ModeContext from "../context/mode-context"
import { ab } from "../theme"

const ThreeColumnText = ({
  heading1,
  heading2,
  heading3,
  text1,
  text2,
  text3,
  hasBackground,
  mode,
}) => {
  const { mode: globalMode } = useContext(ModeContext)
  const { rawColors: colors } = useThemeUI().theme

  const modeKey = mode ? mode : globalMode

  return (
    <div
      sx={{
        variant: "spacingContainer",
        display: "flex",
        color: "text",
        justifyContent: "space-around",
        flexDirection: "column",
      }}
    >
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          "& > div": {
            flexBasis: ab({
              _: "calc(100% - 20px)",
              md: "calc(100% - 20px)",
              lg: "calc(33.3% - 20px)",
            }),

            "& > h2": {
              lineHeight: "40px",
              px: 2,
            },
          },
          "& >div:nth-child(2n-1)": {
            bg: hasBackground
              ? modeKey
                ? colors.modes[modeKey].primary
                : colors.modes["group"].primary
              : "",
            color: hasBackground ? "background" : "",
            mt: ab({ _: "30px", lg: "0px" }),
            textAlign: "center",
          },
          "& >div:nth-child(2n+1)": {
            order: ab({ _: 0, lg: 0 }),
            display:
              !heading1.trim() && !heading2.trim() && !heading3.trim()
                ? "none"
                : "block",
          },
          "& >div:nth-child(2n+2)": {
            order: ab({ _: 0, lg: 2 }),
            variant: hasBackground ? "styles.threeColumnBg" : "",
          },
          "& >div:nth-child(2n+3)": {
            order: ab({ _: 0, lg: 1 }),
          },
          "& >div:nth-child(2n+4)": {
            order: ab({ _: 0, lg: 3 }),
          },
        }}
      >
        <div>
          <h2
            sx={{
              my: ab({ _: 2, md: 0 }),
              py: ab({ md: 3 }),
              paddingRight: ab({ md: 4 }),
              variant: "styles.h2",
              height: ab({ _: "auto", md: heading1 ? "auto" : "70px" }),
            }}
          >
            <span
              sx={{
                display: heading1 ? "block" : "none",
              }}
            >
              {heading1}
            </span>
          </h2>
        </div>
        <div
          sx={{
            my: 0,
          }}
        >
          {text1}
        </div>

        <div
          sx={{
            visibility: heading2 === "" ? "hidden" : "visible",
          }}
        >
          <h2
            sx={{
              my: ab({ _: 2, md: 0 }),
              py: ab({ md: 3 }),
              paddingRight: ab({ md: 4 }),
              variant: "styles.h2",
              height: ab({ _: "auto", md: heading2 ? "auto" : "70px" }),
            }}
          >
            <span
              sx={{
                display: heading2 ? "block" : "none",
              }}
            >
              {heading2}
            </span>
          </h2>
        </div>
        <div
          sx={{
            my: 0,
            visibility: heading2 === "" ? "hidden" : "visible",
          }}
        >
          {text2}
        </div>

        <div
          sx={{
            visibility: heading3 === "" ? "hidden" : "visible",
          }}
        >
          <h2
            sx={{
              my: ab({ _: 2, md: 0 }),
              py: ab({ md: 3 }),
              paddingRight: ab({ md: 4 }),
              variant: "styles.h2",
              height: ab({ _: "auto", md: heading3 ? "auto" : "70px" }),
            }}
          >
            <span
              sx={{
                display: heading3 ? "block" : "none",
              }}
            >
              {heading3}
            </span>
          </h2>
        </div>
        <div
          sx={{
            my: 0,
            visibility: heading3 === "" ? "hidden" : "visible",
          }}
        >
          {text3}
        </div>
      </div>
    </div>
  )
}
ThreeColumnText.propTypes = {
  heading1: PropTypes.string,
  heading2: PropTypes.string,
  heading3: PropTypes.string,
  text1: PropTypes.node,
  text2: PropTypes.node,
  text3: PropTypes.node,
  hasBackground: PropTypes.bool,
  children: PropTypes.node,
}

export default ThreeColumnText
