/** @jsx jsx */
import { jsx } from "theme-ui"

const CosmeticsIcon = ({ style = {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.62 68.06">
    <g data-name="Ebene 2">
      <g data-name="Ebene 1">
        <g data-name="Ebene 2">
          <g data-name="Ebene 1">
            <path
              sx={style}
              data-name="Pfad 8"
              d="M68.62 34.32A34.31 34.31 0 1 0 9.15 57.64L13.64 45l6.52 2.31 6.74-19-2.27-.8.3-.85 2.27.8.3-.85-2.27-.8.3-.85 2.27.8.25-.71-2.27-.8.3-.85 2.27.8.3-.85-2.27-.8.3-.85 2.27.8.3-.85-2.25-.87.3-.85 2.27.8.3-.85-2.27-.8.3-.85 2.27.8.3-.85-1.7-.6.3-.86 1.7.6.3-.85-.85-.3.3-.85 3.12 1.1-.3.85-.85-.3-.3.85 1.7.6-.3.86-1.7-.6-.3.85 2.27.8-.3.85-2.27-.8-.3.85 2.27.8-.3.85-2.27-.8-.3.85 2.27.8-.3.86-2.28-.8-.3.85 2.27.8-.3.85-2.27-.8-.25.7 2.27.81-.3.85-2.28-.85-.3.85 2.27.8-.3.86-2.27-.8-6.73 19 6.52 2.31-5.8 16.39a34.6 34.6 0 0 0 5.82 1.6l11-31a1.5 1.5 0 0 1 1.92-.9l.57.2.5-1.42A1.5 1.5 0 0 1 44 34l7.66 2.71a1.5 1.5 0 0 1 .9 1.92L52.09 40l.57.2a1.5 1.5 0 0 1 .9 1.92L44.77 67a34.3 34.3 0 0 0 23.85-32.68"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default CosmeticsIcon
