/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { useContext } from "react"
import Image from "./image"
import { ModalContext } from "../context/modal-context"

import Link from "./link"

import Input from "./form/input"

const ShopItem = ({
  image,
  imageAlt,
  children,
  heading,
  pdfLink,
  linkText,
  placeholderText,
  min,
  showInput,
  dispalyImage,
  anzahlText,
}) => {
  let { handleModal } = useContext(ModalContext) || ""

  let pdf
  let input

  let imageElement = (
    <Image
      image={
        typeof image === "object"
          ? {
              ...image,
              sizes: "(min-width: 824px) 300px, 300px",
              aspectRatio: 1,
            }
          : image
      }
      sizes={["100vw", "50vw", "34vw", "34vw"]}
      alt={imageAlt}
    />
  )
  let modalImg = (
    <Image
      image={
        typeof image === "object"
          ? {
              ...image,
              sizes: "(min-width: 824px) 800px, 800px",
              aspectRatio: 1,
            }
          : image
      }
      alt={imageAlt}
    />
  )

  if (showInput) {
    input = (
      <Input
        name={heading}
        emailLabel={heading}
        type="number"
        placeholder={placeholderText}
        label=""
        min={min}
        shopItem={true}
      />
    )
  }

  if (linkText) {
    pdf = (
      <Link
        to={pdfLink}
        target="_blank"
        sx={{
          textDecoration: "underline",
        }}
      >
        <strong>{linkText}</strong>
      </Link>
    )
  }

  return (
    <div
      sx={{
        display: heading ? "block" : "none",
        position: "relative",
        paddingBottom: "65px",
      }}
    >
      <div
        onClick={() => handleModal(modalImg)}
        sx={{
          mb: 3,
          display: dispalyImage ? "none" : "block",
        }}
      >
        {imageElement}
      </div>

      <h2> {heading} </h2>
      <div> {children} </div>
      <p>{pdf}</p>

      <p
        sx={{
          display: min === 0 ? "none" : "block",
        }}
      >
        <strong>
          {anzahlText}: {min}
        </strong>
      </p>
      <div
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
        }}
      >
        {input}
      </div>
    </div>
  )
}
ShopItem.prototype = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  imageAlt: PropTypes.string,
  children: PropTypes.node,
  heading: PropTypes.string.isRequired,
  pdfLink: PropTypes.string,
  linkText: PropTypes.string,
  min: PropTypes.number,
  dispalyImage: PropTypes.bool,
  showInput: PropTypes.bool,
}

export default ShopItem
