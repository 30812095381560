/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { useState, useEffect, useRef } from "react"
import Arrow from "./arrow.js"

function Slider({ sliderName, children }) {
  const [slideIndex, setIndex] = useState(0)
  const [slide, setSlide] = useState(children)
  const sliderElement = useRef(null)

  useEffect(() => {
    setSlide(children)
  }, [children])

  const nextSlide = () => {
    if (slideIndex < childNo()) {
      let i = slideIndex + 1
      setIndex(i)
      addActiveClass(i)
    } else {
      setIndex(0)
      addActiveClass(0)
    }
  }
  const prevSlide = () => {
    if (slideIndex < 1) {
      setIndex(childNo())
      addActiveClass(childNo())
    } else {
      let i = slideIndex - 1
      setIndex(i)
      addActiveClass(i)
    }
  }

  const childNo = () => {
    return children.length - 1
  }

  const getSlide = (slideIndex) => {
    return slide[slideIndex]
  }

  const addActiveClass = (i) => {
    const getId = sliderElement.current.getElementsByClassName("slide-" + i)[0]
    getId.parentElement
      .querySelectorAll(".active")
      .forEach((e) => e.classList.remove("active"))
    getId.classList.add("active")
  }

  const dots = () =>
    children.map((child, index) => (
      <li
        className={"slide-" + index + " " + (index === 0 ? "active" : "")}
        key={index}
        role="button"
        tabIndex="0"
        onKeyPress={(e) => {
          setIndex(index)
          addActiveClass(index)
        }}
        onClick={(e) => {
          setIndex(index)
          addActiveClass(index)
        }}
        sx={{
          border: "solid 2px #fff",
          p: "5px",
          m: "5px",
          borderRadius: "50%",
          cursor: "pointer",
        }}
      />
    ))

  return (
    <div
      sx={{
        position: "relative",
        variant: "guc.slider",
      }}
      ref={sliderElement}
    >
      <div>
        <div>{getSlide(slideIndex)}</div>

        <div
          role="button"
          tabIndex="0"
          onKeyPress={prevSlide}
          onClick={prevSlide}
          sx={{
            position: "absolute",
            bottom: 20,
            left: 4,
            zIndex: 10,
            width: ["40px"],
            cursor: "pointer",
            svg: {
              fill: "#fff",
            },
          }}
        >
          <Arrow />
        </div>
      </div>

      <div
        sx={{
          bottom: 20,
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <ul
          id="dots"
          sx={{
            m: 0,
            p: 0,

            "& > li": {
              display: "inline-block",
              width: 2,
              transition: "all 200ms ease-in-out",
            },
            "& > li.active": {
              width: 40,
              bg: "#fff",
              borderRadius: "8px",
              transition: "all 200ms ease-in-out 70ms",
            },
          }}
        >
          {dots()}
        </ul>
      </div>

      <div
        role="button"
        tabIndex="0"
        onKeyPress={nextSlide}
        onClick={nextSlide}
        sx={{
          position: "absolute",
          bottom: 25,
          transform: "rotate(180deg)",
          right: 4,
          zIndex: 10,
          width: ["40px"],
          cursor: "pointer",
          svg: {
            fill: "#fff",
          },
        }}
      >
        <Arrow />
      </div>
    </div>
  )
}

Slider.propTypes = {
  sliderName: PropTypes.string,
  children: PropTypes.node,
}

export default Slider
