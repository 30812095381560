/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Link from "./link"
import Button from "./button"

import { ab } from "../theme"

const BlockThirdParty = ({
  children,
  cookieKey,
  backgroundUrl,
  buttonText,
  buttonPermanentText,
  infoText,
  infoUrl,
  infoUrlText,
}) => {
  const [blocked, setBlocked] = useState(true)

  const setCookie = (cookieKey, exdays = 7) => {
    const now = new Date()
    now.setTime(now.getTime() + exdays * 24 * 60 * 60 * 1000)

    document.cookie = `third-party-permission-${cookieKey}=true; expires=${now.toUTCString()}; path=/`
  }

  const allow = () => {
    setBlocked(false)
  }

  const allowPermanent = () => {
    setBlocked(false)
    setCookie(cookieKey)
  }

  const style = {
    color: "#fff",
    borderColor: "#fff",
  }

  useEffect(() => {
    if (
      document.cookie.indexOf(`third-party-permission-${cookieKey}=true`) >= 0
    ) {
      setBlocked(false)
    }
  }, [cookieKey])

  if (blocked) {
    return (
      <div
        className="block-third-party-container"
        sx={{
          background: `linear-gradient(rgba(0,0,0,.15), rgba(0,0,0,.15)), url(${backgroundUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          color: "#fff",
          py: 5,
          px: 3,
          variant: ab({ md: "spacingContainer" }),
        }}
      >
        <div
          className="info"
          sx={{
            textAlign: "center",
          }}
        >
          <p sx={{ fontSize: 3 }}>
            <strong>{infoText}</strong>
          </p>
          <Link to={infoUrl} sx={{ color: "#fff" }} target="_blank">
            {infoUrlText}
          </Link>
        </div>
        <div
          className="actions"
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
          }}
        >
          <div className="allow-third-party" sx={{ padding: 2 }}>
            <Button
              buttonText={buttonText}
              onClick={() => allow()}
              style={style}
            />
          </div>
          <div className="allow-third-party-permanent" sx={{ padding: 2 }}>
            <Button
              buttonText={buttonPermanentText}
              onClick={() => allowPermanent()}
              style={style}
            />
          </div>
        </div>
      </div>
    )
  }

  return <div>{children}</div>
}

BlockThirdParty.propTypes = {
  children: PropTypes.node,
  cookieKey: PropTypes.string.isRequired, // eg: iframe, youtube
  backgroundUrl: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonPermanentText: PropTypes.string.isRequired,
  infoText: PropTypes.string.isRequired,
  infoUrl: PropTypes.string.isRequired,
  infoUrlText: PropTypes.string.isRequired,
}

BlockThirdParty.defaultProps = {
  cookieKey: "key",
}

export default BlockThirdParty
