/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

import ShopContext from "../context/shop-context"
import EditorContext from "../context/editor-context"
import LoadingSpinner from "./loading-spinner"

const ShopWrapper = ({ children, loginUrl }) => {
  const { isLoggedIn } = useContext(ShopContext)
  const isEditor = useContext(EditorContext)

  const loading = !isEditor && !isLoggedIn

  useEffect(() => {
    if (!isEditor && !isLoggedIn) {
      navigate(loginUrl)
    }
  })

  return (
    <Fragment>
      <LoadingSpinner hide={!loading} />
      <div
        sx={{
          display: loading ? "none" : "block",
        }}
        className="shop-wrapper"
      >
        {children}
      </div>
    </Fragment>
  )
}

ShopWrapper.propTypes = {
  children: PropTypes.node,
  loginUrl: PropTypes.string,
}

ShopWrapper.defaultProps = {
  loginUrl: "/gba-shop/login/",
}

export default ShopWrapper
