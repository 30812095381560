/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import PropTypes from "prop-types"
import ReactPlayer from "react-player/lazy"

import { ab } from "../theme"

const Video = ({ video, children, reverse, poster }) => {
  const [playing, setPlaying] = useState(false)

  const clickPreviewAction = () => {
    setPlaying(true)
  }

  return (
    <div
      sx={{
        variant: "spacingContainer",
      }}
    >
      <div
        sx={{
          display: ab({ md: "flex" }),
          flexDirection: reverse ? "row-reverse" : "row",
          color: "text",
        }}
      >
        <div
          sx={{
            flex: 1,
            my: 0,
            py: ab({ md: 3 }),
            paddingRight: ab({ md: reverse ? 0 : 4 }),
            paddingLeft: ab({ md: reverse ? 4 : 0 }),
            "@media (max-width:550px)": {
              ".react-player__preview": {
                backgroundSize: "contain !important",
                backgroundRepeat: "no-repeat",
              },
            },
          }}
        >
          <ReactPlayer
            url={video}
            width="100%"
            controls={true}
            light={poster}
            playing={playing}
            onClickPreview={clickPreviewAction}
          />
        </div>
        <div
          sx={{
            flex: 1,
            my: 0,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
Video.propTypes = {
  video: PropTypes.string.isRequired,
  children: PropTypes.node,
  reverse: PropTypes.bool,
  poster: PropTypes.string,
}

export default Video
