/** @jsx jsx */
import { jsx } from "theme-ui"

const HUSAboutIcon = ({ style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 162.55 162.55"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
  >
    <g id="Ebene_1-2" data-name="Ebene 1">
      <g>
        <path
          sx={style}
          d="m120.88,139.92c1-.73,10.71-7.23,24.86-9.15-9.04,11.76-21.22,20.97-35.3,26.38-7.99,3.08-16.59,4.92-25.58,5.31-1.19.05-2.39.08-3.59.08-13.21,0-25.68-3.15-36.7-8.74-7.88-3.99-15.02-9.23-21.16-15.46-2.39-2.42-4.62-4.98-6.68-7.68,7.93,1.03,16.64,3.81,25.9,9.5h0c13.89,8.47,27.33,11.43,38.89,11.43h.08c21.93-.05,36.95-10.16,39.15-11.69l.05.06.07-.05Z"
        />
        <path
          sx={style}
          d="m153.69,108.94c1.29,0,2.62.05,3.97.14-.49,1.4-1.04,2.78-1.63,4.14-.88,2.04-1.83,4.04-2.87,6-21.66.21-36.45,10.18-38.62,11.69l-.05-.07-.07.04c-1.17.86-14.42,9.73-32.8,9.69-9.82-.02-21.17-2.43-33.5-9.99h0c-13.85-8.45-27.24-11.42-38.77-11.44-1.02-1.93-1.96-3.91-2.83-5.92-.59-1.38-1.14-2.77-1.64-4.19,1.39-.1,2.82-.16,4.27-.15,9.82.01,21.16,2.42,33.5,9.99,11.35,6.92,22.4,10.16,32.37,11.12.89.1,1.78.19,2.68.24v-.05c1.31.08,2.59.12,3.85.12h.08c21.94-.05,36.96-10.18,39.15-11.7l.05.07s.03-.01.07-.05c1.17-.85,14.43-9.73,32.8-9.68Z"
        />
        <path
          sx={style}
          d="m162.55,81.27c0,5.83-.61,11.51-1.78,17-2.43-.25-4.79-.36-7.07-.36-21.94.06-36.96,10.18-39.15,11.7l-.05-.07-.07.04c-1.17.86-14.42,9.73-32.8,9.69-1.38,0-2.8-.06-4.25-.16-18.48-1.72-33.01-15.68-32.98-33.64.04-18.47,14.98-33.41,33.45-33.45,15.22,0,28.07,10.19,32.11,24.1.6,2.04.99,4.17,1.18,6.36h-11.95l18.15,18.91,18.9-18.91h-14.02c-.22-3.25-.77-6.41-1.65-9.44-5.38-18.53-22.46-32.04-42.72-32.05-24.56,0-44.47,19.91-44.47,44.48,0,6.61,1.47,12.77,4.06,18.29-10.07-4.3-19.69-5.93-28.28-5.92-2.56,0-5.01.15-7.37.4-1.16-5.47-1.78-11.16-1.78-16.98C0,36.38,36.38,0,81.27,0s81.27,36.38,81.27,81.27Z"
        />
      </g>
    </g>
  </svg>
)

export default HUSAboutIcon
