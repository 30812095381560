/** @jsx jsx */
import { jsx } from "theme-ui"

const ContactIcon = ({ style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 500 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
  >
    <path
      sx={style}
      d="M424.9,283.3l-38.6,67l-38.6-67h-98.5c-13.5,0-24.5-9-24.5-20.1V99.9c0-11,11-20,24.5-20h183.5  C387.2,30.9,322.3,0,250,0C111.9,0,0,111.9,0,250c0,138.1,111.9,250,250,250c126.8,0,231.2-94.4,247.5-216.7L424.9,283.3  L424.9,283.3z M292.4,359.9c0,7.5-7.1,13.5-15.9,13.5h-50.6l-25.1,45.2l-25.1-45.2h-53.8c-8.8,0-15.9-6.1-15.9-13.5V249.5  c0-7.5,7.1-13.5,15.9-13.5h80.5v27.2c0,23.4,21.1,42.5,46.9,42.5h43.1V359.9L292.4,359.9z"
    />
  </svg>
)

export default ContactIcon
