/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"

const ToggleButton = ({ idx, moreChargesBtnText, lessChargesBtnText }) => {
  const [toggleButtonText, setToggleButtonText] = useState(false)
  let toggleTr = (e) => {
    setToggleButtonText(!toggleButtonText)
    let tr = document.querySelectorAll(`.toggleTr-${idx}`)
    tr.forEach(function (el) {
      el.classList.toggle(`toggleTr`)
      el.classList.toggle("active")
    })
  }

  return (
    <div
      sx={{
        textAlign: "center",
      }}
    >
      <button
        onClick={toggleTr}
        sx={{
          color: "#004787",
          background: "transparent",
          fontSize: "12px",
          px: 3,
          py: 1,
          border: "solid 1px",
          borderRadius: "25px",
          cursor: "pointer",
          mt: 3,
          outline: 0,
          width: "130px",
        }}
      >
        {toggleButtonText ? lessChargesBtnText : moreChargesBtnText}
      </button>
    </div>
  )
}
export default ToggleButton
