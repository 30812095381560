/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { useContext, useEffect } from "react"
import SitesContext from "../../context/site-context"
import { ab } from "../../theme"
import SitesMap from "./sites-map"

const SitesParent = ({
  sites,
  childern,
  currentLanguage,
  centerLng,
  ceneterLat,
  zoomLevel,
  apiKey,
  thirdPartyInfo,
  mapSearchRadius,
}) => {
  let { setSites, setFilters, setLanguage, getLanguage } =
    useContext(SitesContext)

  useEffect(() => {
    setLanguage(currentLanguage)
  })

  useEffect(() => {
    setFilters(sites)
    setSites(sites)
  }, [sites, getLanguage()])

  return (
    <div>
      <SitesMap
        currentLanguage={currentLanguage}
        centerLng={centerLng}
        ceneterLat={ceneterLat}
        zoomLevel={zoomLevel}
        apiKey={apiKey}
        thirdPartyInfo={thirdPartyInfo}
        mapSearchRadius={mapSearchRadius}
      />
      <div
        sx={{
          display: ab({ _: "block", md: "grid" }),
          gridTemplateColumns: "2fr 7fr",
        }}
      >
        {childern}
      </div>
    </div>
  )
}

SitesParent.propTypes = {
  children: PropTypes.node,
  sites: PropTypes.object,
}

export default SitesParent
