/** @jsx jsx */
import { jsx } from "theme-ui"

const Application = ({ style = {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142.8 142.8">
    <g>
      <g>
        <path
          d="M71.4 142.8A71.4 71.4 0 010 71.4 71.4 71.4 0 0171.4 0a71.4 71.4 0 0171.4 71.4 71.4 71.4 0 01-71.4 71.4z"
          style={style}
        />
        <g clip-path="url(#clip-path)">
          <g>
            <g>
              <path
                id="Pfad_142"
                data-name="Pfad 142"
                style={{ fill: "#fff" }}
                d="M45.9 30.25h50.22C99 33.69 117.75 51 120.26 54.41v79.41a6.74 6.74 0 01-6.74 6.74H45.9a6.74 6.74 0 01-6.74-6.74V37a6.74 6.74 0 016.74-6.74z"
              />
              <g id="Gruppe_182" data-name="Gruppe 182">
                <g>
                  <path
                    style={{ fill: "#fff" }}
                    sx={style}
                    d="M122 52.44l-24-24a2.39 2.39 0 00-1.69-.7H46.05a9.58 9.58 0 00-9.58 9.58v95.85a9.6 9.6 0 009.58 9.59h67.1a9.61 9.61 0 009.59-9.59V54.14a2.41 2.41 0 00-.74-1.7zM98.77 36l15.79 15.78h-11A4.81 4.81 0 0198.77 47zM118 133.23a4.81 4.81 0 01-4.8 4.79H46.05a4.81 4.81 0 01-4.79-4.8V37.36a4.8 4.8 0 014.79-4.79H94V47a9.59 9.59 0 009.59 9.58H118z"
                  />
                  <path
                    id="Pfad_144"
                    data-name="Pfad 144"
                    style={style}
                    d="M106 94.88H72.41a2.4 2.4 0 00-.14 4.79H106a2.4 2.4 0 000-4.79z"
                  />
                  <path
                    style={style}
                    d="M53.24 56.54H82a2.4 2.4 0 10.14-4.8h-28.9a2.4 2.4 0 10-.14 4.8z"
                  />
                  <path
                    style={style}
                    d="M106 80.5H53.24a2.4 2.4 0 000 4.79H106a2.4 2.4 0 10.15-4.79z"
                  />
                  <path
                    style={style}
                    d="M50.84 68.52a2.39 2.39 0 002.4 2.39H106a2.4 2.4 0 000-4.79H53.24a2.4 2.4 0 00-2.4 2.39z"
                  />
                  <path
                    style={style}
                    d="M85.11 114.73a97.76 97.76 0 01-9.73 8.57 35.54 35.54 0 01-1.17-3.48c-1-3.23-1.74-5.77-4.2-5.77-2.1 0-3.05 1.92-4.48 4.82a26.24 26.24 0 01-2.69 4.76 5.67 5.67 0 01-2.38-1.57c2.64-3.41 4.76-7.36 4.76-10.4a7.07 7.07 0 00-6.95-7.19 1 1 0 00-.24 0c-2.83 0-7.19 4.11-7.19 9.58a15.09 15.09 0 003.25 8 23.13 23.13 0 01-2.29 2 2.39 2.39 0 102.87 3.95 24.83 24.83 0 002.62-2.29 9.23 9.23 0 005.53 2.77c3.24 0 5.16-3.69 6.83-7.08 1.11 3.66 2.18 7.08 5.15 7.08.8 0 3.23 0 13.66-10.27a2.4 2.4 0 00-3.36-3.42zM57.3 118.3a8.7 8.7 0 01-1.67-4.25 6.19 6.19 0 012.4-4.8 2.26 2.26 0 012.4 2.11 1.41 1.41 0 010 .29 14.41 14.41 0 01-3.13 6.65z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Application
