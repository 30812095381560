/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import SitesContext from "../../context/site-context"
import { useEffect, useContext, useState } from "react"
import { colors, ab } from "../../theme"
import Link from "./../link"
import Icon from "../../assets/icon"
import Image from "../image"

const SitesListing = ({
  currentLanguage,
  noEntiresText,
  heading,
  btnText,
  tileDefaultImage,
  noEntriesText,
}) => {
  const { getSiteListing, getPlzFilters, getSerach } = useContext(SitesContext)
  const [noEntries, setNoEntries] = useState(false)

  let searchPlz = getSerach().ortPlz
  const plzFilter = getPlzFilters()

  let grouped = searchPlz ? plzFilter : getSiteListing()

  useEffect(() => {
    if (Object.entries(grouped).length === 0) {
      setNoEntries(true)
    } else {
      setNoEntries(false)
    }
  }, [grouped])

  return (
    <div className="sites">
      <div
        sx={{
          mt: ab({ _: "30px", md: "62px" }),
        }}
      >
        {Object.entries(grouped)
          .sort()
          .map(([groupName, group]) => {
            const filteredGroup = group.filter(
              (site) => site.city !== undefined
            )
            filteredGroup.sort((a, b) => a.city.localeCompare(b.city))
            return (
              <div key={groupName}>
                <h5
                  sx={{
                    fontSize: "10px",
                    fontWeight: "normal",
                    textAlign: "center",
                    span: {
                      background: "#fff",
                      display: "inline-block",
                      position: "relative",
                      zIndex: 10,
                      textTransform: "capitalize",
                      padding: "0 10px",
                    },
                    "::before": {
                      display: "block",
                      position: "relative",
                      borderBottom: " 1px solid",
                      content: "''",
                      top: "10px",
                    },
                  }}
                >
                  <span>{groupName}</span>
                </h5>
                <div
                  sx={{
                    display: "grid",
                    gridTemplateColumns: ab({
                      _: "",
                      md: "repeat(2, 1fr)",
                      lg: "repeat(3, 1fr)",
                    }),
                    gap: "15px",
                  }}
                >
                  {filteredGroup.sort().map((site) => {
                    let icons = !!site.industry
                      ? site.industry.split(",")
                      : ["departments"]
                    icons = icons.map((item) => item.trim())
                    icons = icons.sort()
                    let tileImage = site.tileImage
                      ? site.tileImage
                      : tileDefaultImage

                    let siteLink = site.linkLocation
                      ? site.linkLocation.id
                      : site.slug
                    return (
                      <Link
                        to={siteLink}
                        language={currentLanguage}
                        sx={{
                          textDecoration: "none",
                        }}
                      >
                        <div
                          sx={{
                            bg: colors.modes["group"].primary,
                            backgroundRepeat: "no-repeat",
                            display: "flex",
                            flexDirection: "column",
                            minHeight: "300px",
                            justifyContent: "space-between",
                            position: "relative",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              px: "10px",
                              position: "relative",
                              zIndex: "5",
                              flexGrow: 1,
                              alignItems: "center",
                            }}
                          >
                            <div
                              sx={{
                                display: "flex",
                              }}
                            >
                              {icons.map((icon, i) => {
                                if (icon === "water") {
                                  icon = "wasser"
                                }
                                if (icon === "Bausubstanz") {
                                  icon = "construction"
                                }
                                if (icon === "Life Science") {
                                  icon = "lifeScience"
                                }
                                if (icon === "Agro Chemical") {
                                  icon = "agroChemical"
                                }
                                return (
                                  <div
                                    sx={{
                                      width: "50px",
                                      minHeight: "60px",
                                      pt: "20px",
                                      px: "5px",
                                    }}
                                  >
                                    <Icon
                                      key={i}
                                      style={{
                                        fill: "#fff",
                                      }}
                                      icon={icon.trim()}
                                    />
                                  </div>
                                )
                              })}
                            </div>
                            {site.logo && (
                              <div
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                <Image
                                  image={site.logo}
                                  alt={site.name}
                                  style={{
                                    height: "auto",
                                    maxWidth: "100px",
                                  }}
                                />
                              </div>
                            )}

                            <h4
                              sx={{
                                fontWeight: "normal",
                                p: 0,
                                m: 0,
                                fontWeight: "600",
                                color: "#fff",
                                fontSize: 3,
                                textAlign: "center",
                              }}
                            >
                              {site.city ? site.city : site.name}
                            </h4>
                            <span
                              sx={{
                                color: "#fff",
                                fontSize: "14px",
                                textTransform: "capitalize",
                              }}
                            >
                              {site.country}
                            </span>
                          </div>
                          <div
                            sx={{
                              position: "absolute",
                              opacity: ".6",
                            }}
                          >
                            <Image
                              image={tileImage}
                              alt={site.name}
                              style={{
                                height: "auto",
                                width: "100%",
                              }}
                            />
                          </div>
                          <div
                            className="link-box"
                            sx={{
                              bg: colors.modes["group"].primary,
                              color: "#fff",
                              textAlign: "center",
                              position: "relative",
                              zIndex: "5",
                              minHeight: "0px",
                              textDecoration: "none",
                              "&:hover": {
                                background: "#efefef",
                                a: {
                                  color: colors.modes["group"].primary,
                                },
                              },
                            }}
                          >
                            <Link
                              to={siteLink}
                              language={currentLanguage}
                              sx={{
                                display: ab({ _: "block", md: "block" }),
                                // variant: "styles.ghostButton",
                                color: "#fff",
                                py: "20px",
                                px: "40px",
                                textDecoration: "none",
                              }}
                            >
                              {btnText}
                            </Link>
                          </div>
                        </div>
                      </Link>
                    )
                  })}
                </div>
              </div>
            )
          })}
      </div>
      <div
        sx={{
          display: noEntries ? "block" : "none",
        }}
      >
        {noEntriesText}
      </div>
    </div>
  )
}

SitesListing.propTypes = {
  noEntriesText: PropTypes.string,
  children: PropTypes.node,
}

export default SitesListing
