/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import { useState, Fragment } from "react"
import PropTypes from "prop-types"

import {
  InstantSearch,
  Hits,
  Pagination,
  Snippet,
  Highlight,
  RefinementList,
  connectStateResults,
  connectSearchBox,
  connectRefinementList,
} from "react-instantsearch-dom"

import { ab } from "../theme"
import Link from "./link"
import { SearchIcon } from "../assets/icons"
import { liteClient } from "algoliasearch/lite"
import Button from "./button"
import SearchToggleRefinement from "./search-toggle-refinement"

const Search = ({
  active,
  indexName,
  refineLanguage,
  refineMode,
  appId,
  searchKey,
  placeholder,
  closeAction,
  children,
  optionText,
}) => {
  const [searchInput, setSearchInput] = useState("")
  const { rawColors: colors } = useThemeUI().theme

  const renderContentPreview = (hit) => {
    if (hit.description) {
      return <Snippet attribute="description" hit={hit} />
    }

    return <Snippet attribute="content" hit={hit} />
  }

  const renderModeTitle = (hit) => {
    switch (hit.category) {
      case "pharma":
        return "GBA Group Pharma"
      case "environment":
        return "GBA Group Environment"
      case "food":
        return "GBA Group Food"
      case "cosmetics":
        return "GBA Group Cosmetics"
      case "veterinary":
        return "GBA Group Veterinary"
      case "medical":
        return "GBA Group Medical Devices"
      case "wasser":
        return "GBA Group Wasser"
      case "construction":
        return "GBA Group Gebäudeschadstoffe & Bausubstanz"
      case "agroChemicals":
        return "Agrochemicals"
      case "lifeScience":
        return "GBA Group Life Sciences"
      case "career":
        return "Deine Zukunft bei der GBA Group"
      case "sustainabilityServices":
        return "GBA Group Sustainability Services"
      default:
        return "GBA Group"
    }
  }
  const Hit = ({ hit }) => {
    return (
      <article
        sx={{
          mt: 3,
        }}
      >
        <span
          sx={{
            fontSize: "16px",
            fontWeight: "normal",
            display: "inline-block",
          }}
        >
          {" "}
          {renderModeTitle(hit)}{" "}
        </span>
        <h2
          sx={{
            mt: 1,
            mb: 0,
          }}
        >
          <Link
            onClick={() => closeAction(false)}
            to={hit.url}
            language={"" + refineLanguage}
            sx={{
              color: hit.category
                ? colors.modes[hit.category].primary
                : colors.modes.group.primary,
              textDecoration: "none",
              fontWeight: "600",
            }}
          >
            <Highlight attribute="title" hit={hit} />
          </Link>
        </h2>
        <p>{renderContentPreview(hit)}</p>
        <div
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Link
            onClick={() => closeAction(false)}
            language={"" + refineLanguage}
            sx={{
              color: "text",
              textDecoration: "none",
              borderBottom: "solid 1px",
              borderColor: "text",
              lineHeight: 1.2,
            }}
            to={hit.url}
          >
            Anzeigen
          </Link>
        </div>
      </article>
    )
  }

  const Results = connectStateResults(
    ({ searchState: state, searchResults: res, children }) => {
      if (!res || res.query === "") {
        return null
      }

      return res && res.nbHits > 0
        ? children
        : `No results for '${state.query}'`
    }
  )

  const SearchBox = ({ currentRefinement, refine }) => (
    <div
      sx={{
        pt: 2,
        borderColor: "text",
        display: "flex",
        color: "text",
        boxShadow: "0px 0px 8px 2px #00000040",
        borderRadius: "30px",
        p: "10px 20px",
        mt: 2,
        "&::placeholder": {
          color: "#b1b1b1",
        },
      }}
    >
      <div
        sx={{
          display: ab({ _: "none", sm: "block" }),
          flex: 1,
          textAlign: "center",
          width: 32,
          height: 32,
          maxWidth: 34,
          mr: 3,
          mt: 2,
        }}
      >
        <SearchIcon
          css={{
            width: 34,
          }}
        />
      </div>
      <input
        type="search"
        value={currentRefinement}
        onChange={(event) => {
          refine(event.currentTarget.value)
        }}
        autoFocus
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        maxLength="512"
        placeholder={placeholder}
        sx={{
          flexGrow: 1,
          border: "none",
          p: ab({ md: 1 }),
          fontSize: ab({ md: 5 }),
          maxWidth: ab({ _: "100%", md: "auto" }),
          "&:focus": {
            outline: 0,
          },
        }}
        data-cy-identifier="search-input"
      />

      <span
        sx={{
          bg: "#fff",
          padding: "0 0 0 38px",
          marginLeft: "-40px",
        }}
      ></span>
    </div>
  )
  const CustomSearchBox = connectSearchBox(SearchBox)

  const Stats = connectStateResults(
    ({ searchResults: res }) =>
      res &&
      res.query !== "" &&
      res.nbHits > 0 &&
      `${res.nbHits} result${res.nbHits > 1 ? `s` : ``} für ${res.query}`
  )

  const ModeRefinementList = ({ currentRefinement, refine }) => {
    const toggleSearchMode = (mode) => {
      const modeIndex = currentRefinement.indexOf(mode)

      if (modeIndex > -1) {
        return currentRefinement.filter((currentMode) => currentMode !== mode)
      } else {
        return [...currentRefinement, mode]
      }
    }

    const buttonActive = (mode) => {
      return !!(currentRefinement.indexOf(mode) > -1)
    }

    const makeButtonStyle = (mode, inactiveColor) => {
      return {
        backgroundColor: buttonActive(mode) ? inactiveColor : "#fff",
        ":hover": {
          backgroundColor: buttonActive(mode) ? inactiveColor : "#fff",
          color: buttonActive(mode) ? "#fff" : "#ccc",
        },
      }
    }

    return (
      <div className="refinements-controls">
        <ul
          sx={{
            m: 0,
            p: 0,
            mb: 2,
            display: "flex",
            flexWrap: "wrap",
            "& li": {
              listStyle: "none",
              minWidth: ab({ sm: "23%" }),
              marginBottom: ab({ _: "10px" }),
              mr: ab({ _: 2, md: "10px" }),
              "& button": {
                border: "solid 1px #ccc",
                borderRadius: "30px",
                color: "#ccc",
                fontSize: ab({ _: 0, md: 2 }),
                px: ab({ _: 2, md: 4 }),
                width: ab({ md: "100%" }),
              },
              "&.active": {
                "& button": {
                  color: "#fff",
                  border: 0,
                },
              },
            },
          }}
        >
          <li className={buttonActive("group") ? "active" : ""}>
            <Button
              onClick={() => refine(toggleSearchMode("group"))}
              buttonText="GBA Group"
              style={makeButtonStyle("group", "primary")}
            />
          </li>
          <li className={buttonActive("pharma") ? "active" : ""}>
            <Button
              onClick={() => refine(toggleSearchMode("pharma"))}
              buttonText="Pharma"
              style={makeButtonStyle("pharma", colors.modes.pharma.primary)}
            />
          </li>
          <li className={buttonActive("food") ? "active" : ""}>
            <Button
              onClick={() => refine(toggleSearchMode("food"))}
              buttonText="Food"
              style={makeButtonStyle("food", colors.modes.food.primary)}
            />
          </li>
          <li className={buttonActive("environment") ? "active" : ""}>
            <Button
              onClick={() => refine(toggleSearchMode("environment"))}
              buttonText="Environment"
              style={makeButtonStyle(
                "environment",
                colors.modes.environment.primary
              )}
            />
          </li>
          <li className={buttonActive("wasser") ? "active" : ""}>
            <Button
              onClick={() => refine(toggleSearchMode("wasser"))}
              buttonText="Wasser"
              style={makeButtonStyle("wasser", colors.modes.wasser.primary)}
            />
          </li>
          <li className={buttonActive("construction") ? "active" : ""}>
            <Button
              onClick={() => refine(toggleSearchMode("construction"))}
              buttonText="Gebäudeschadstoffe & Bausubstanz"
              style={makeButtonStyle(
                "construction",
                colors.modes.construction.primary
              )}
            />
          </li>
          <li className={buttonActive("cosmetics") ? "active" : ""}>
            <Button
              onClick={() => refine(toggleSearchMode("cosmetics"))}
              buttonText="Cosmetics"
              style={makeButtonStyle(
                "cosmetics",
                colors.modes.cosmetics.primary
              )}
            />
          </li>

          <li className={buttonActive("veterinary") ? "active" : ""}>
            <Button
              onClick={() => refine(toggleSearchMode("veterinary"))}
              buttonText="Veterinary"
              style={makeButtonStyle(
                "veterinary",
                colors.modes.veterinary.primary
              )}
            />
          </li>

          <li className={buttonActive("medical") ? "active" : ""}>
            <Button
              onClick={() => refine(toggleSearchMode("medical"))}
              buttonText="Medical Devices"
              style={makeButtonStyle("medical", colors.modes.medical.primary)}
            />
          </li>
          <li
            className={buttonActive("sustainabilityServices") ? "active" : ""}
          >
            <Button
              onClick={() => refine(toggleSearchMode("sustainabilityServices"))}
              buttonText="Sustainability Services"
              style={makeButtonStyle(
                "sustainabilityServices",
                colors.modes.sustainabilityServices.primary
              )}
            />
          </li>
        </ul>
      </div>
    )
  }

  const CustomRefinementList = connectRefinementList(ModeRefinementList)

  if (!active) {
    return null
  } else {
    const searchClient = liteClient(appId, searchKey)

    return (
      <InstantSearch searchClient={searchClient} indexName={indexName}>
        <div className="search-panel">
          <div className="search-panel__results">
            <div className="refinements" style={{ display: "none" }}>
              <RefinementList
                attribute="lang"
                defaultRefinement={refineLanguage}
              />
            </div>
            <CustomSearchBox
              className="searchbox"
              translations={{
                placeholder: "",
              }}
            />
            {children}
            <SearchToggleRefinement
              buttonText={
                refineLanguage[0] === "de"
                  ? "Suche verfeinern"
                  : "Refine Search"
              }
            >
              <Fragment>
                <p className="option-text">{optionText}</p>

                <CustomRefinementList
                  attribute="category"
                  defaultRefinement={refineMode}
                />
              </Fragment>
            </SearchToggleRefinement>
            <div
              className="result-list"
              sx={{
                pb: 5,
              }}
            >
              <Results>
                <div
                  sx={{
                    py: 3,
                  }}
                >
                  <Stats />
                </div>
                <div
                  className="search-hits"
                  sx={{
                    "& ul": {
                      margin: 0,
                      padding: 0,
                      listStyleType: "none",
                    },
                  }}
                >
                  <Hits hitComponent={Hit} />
                  <div
                    sx={{
                      "& ul": {
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 3,
                        "& li": {
                          px: 2,
                          "&.ais-Pagination-item--selected": {
                            fontWeight: "bold",
                          },
                          "& a": {
                            color: "text",
                            textDecoration: "none",
                          },
                        },
                      },
                    }}
                  >
                    <Pagination showLast />
                  </div>
                </div>
              </Results>
            </div>
          </div>
        </div>
      </InstantSearch>
    )
  }
}

Search.propTypes = {
  active: PropTypes.bool.isRequired,
  indexName: PropTypes.string.isRequired,
  refineLanguage: PropTypes.array.isRequired,
  refineMode: PropTypes.array.isRequired,
  appId: PropTypes.string.isRequired,
  searchKey: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  closeAction: PropTypes.func.isRequired,
  children: PropTypes.node,
  optionText: PropTypes.string.isRequired,
}

export default Search
