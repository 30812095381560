/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, useEffect } from "react"
import PropTypes from "prop-types"

import DepartmentSelect from "./form/department-select"

const ContactViaMail = ({ heading, label, mailListing, required }) => {
  const [mailTarget, setMailTarget] = useState()
  const [mailText, setMailText] = useState()

  const selectDepartmentHandler = (id) => {
    const selectedDepartment = mailListing.find((dept) => dept.id === id)

    if (!selectedDepartment) {
      setMailTarget("")
      return
    }

    setMailTarget(selectedDepartment.email)
    if (selectedDepartment.emailText) {
      setMailText(selectedDepartment.emailText)
    } else {
      setMailText(selectedDepartment.email)
    }
  }

  useEffect(() => {
    if (mailListing.length) {
      const firstMail = mailListing[0]
      setMailTarget(firstMail.email)
      if (firstMail.emailText) {
        setMailText(firstMail.emailText)
      } else {
        setMailText(firstMail.email)
      }
    }
  }, [mailListing])

  return (
    <div>
      {heading && (
        <h3
          sx={{
            fontSize: "1.5rem",
            fontWeight: "normal",
            my: "10px",
          }}
        >
          {heading}
        </h3>
      )}
      <DepartmentSelect
        departments={mailListing}
        onChangeHandler={selectDepartmentHandler}
        required={required}
      />
      {mailTarget && (
        <div
          sx={{
            mt: 2,
            pl: 1,
          }}
        >
          {label}:{" "}
          <a href={`mailto:${mailTarget}`}>
            {mailText ? mailText : mailTarget}
          </a>
        </div>
      )}
    </div>
  )
}

ContactViaMail.propTypes = {
  heading: PropTypes.string,
  label: PropTypes.string.isRequired,
  mailListing: PropTypes.array.isRequired,
  required: PropTypes.bool,
}

export default ContactViaMail
