import React, { useState } from "react"
import PropTypes from "prop-types"

const defaultState = {
  mode: "group",
}

const ModeContext = React.createContext(defaultState)

const ModeProvider = ({ children }) => {
  const [mode] = useState("")

  return (
    <ModeContext.Provider
      value={{
        mode,
      }}
    >
      {children}
    </ModeContext.Provider>
  )
}

ModeContext.propTypes = {
  children: PropTypes.node,
}

export default ModeContext

export { ModeProvider }
