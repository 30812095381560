/** @jsx jsx */
import { jsx } from "theme-ui"

const Cts = ({ style = {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
    <g>
      <g>
        <path
          sx={style}
          d="m71.78,114.73h97.93c2.45,0,4.45-2.01,4.45-4.46s-2-4.46-4.45-4.46h-97.93c-2.45,0-4.45,1.96-4.45,4.46s2,4.46,4.45,4.46Z"
        />
        <path
          sx={style}
          d="m71.78,135.58h97.93c2.45,0,4.45-2,4.45-4.46s-2-4.46-4.45-4.46h-97.93c-2.45,0-4.45,1.96-4.45,4.46s2,4.46,4.45,4.46Z"
        />
        <path
          sx={style}
          d="m71.78,156.44h56.96c2.45,0,4.45-2.01,4.45-4.46s-2-4.46-4.45-4.46h-56.96c-2.45,0-4.45,2-4.45,4.46s2,4.46,4.45,4.46Z"
        />
        <path
          sx={style}
          d="m125,0C55.96,0,0,56,0,125.07c0,42.61,21.31,80.22,53.83,102.8V74.14c0-4.95,3.96-8.91,8.91-8.91h4.59v13.68c0,4.72,3.87,8.6,8.59,8.6h89.64c4.72,0,8.59-3.88,8.59-8.6v-13.68h4.59c4.94,0,8.91,3.97,8.91,8.91v73.97l-60.25,60.11c-5.21,5.17-8.33,11.9-8.77,18.94-.45,7.31,2,14.08,6.99,19.07,1.5,1.5,3.17,2.74,4.95,3.78,11.76-.52,23.09-2.67,33.79-6.22l23.3-23.22v12.72c37.27-21.65,62.35-61.99,62.35-108.21C250,56,194.04,0,125,0Zm40.25,78.59h-89.02v-13.37h89.02v13.37Zm49.65,102.17l-7.13-7.17c-.89-.89-2.32-.89-3.16-.04-.89.89-.89,2.32,0,3.16l7.17,7.22-10.55,10.52-7.17-7.22c-.89-.85-2.27-.85-3.16,0-.89.89-.89,2.27,0,3.16l7.17,7.17-10.42,10.43-.13.13-7.17-7.22c-.89-.89-2.27-.85-3.16,0-.85.89-.85,2.27,0,3.16l7.17,7.17-10.55,10.52-7.17-7.17c-.85-.89-2.27-.89-3.16,0-.85.85-.85,2.27,0,3.12l7.17,7.22-10.55,10.52-7.17-7.17c-.85-.89-2.27-.89-3.12-.04-.89.89-.89,2.32-.04,3.16l7.13,7.17c-7.48,7.13-18.57,7.8-24.98,1.38-3.12-3.16-4.68-7.49-4.36-12.25.31-4.81,2.49-9.49,6.15-13.19l53.97-53.78,10.11-10.07,23.6,23.7-6.46,6.42Zm16.66-7.66c-1.43,1.43-3.7,1.43-5.12.04l-27.12-27.22c-1.43-1.43-1.38-3.7.04-5.12l3.74-3.74c.71-.71,1.65-1.07,2.58-1.07s1.87.36,2.54,1.07l27.12,27.18c1.38,1.43,1.38,3.74-.04,5.17l-3.74,3.7Z"
        />
      </g>
    </g>
  </svg>
)

export default Cts
