/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import { useState, useContext } from "react"
import PropTypes from "prop-types"

import NavigationContext from "../../context/navigation-context"

import Link from "../link"
import Icon from "../../assets/icon"
import Layer from "./layer"

import { ab } from "../../theme"

const MainNavigationItem = ({
  text,
  link,
  language,
  icon,
  mode,
  index,
  children,
  extras,
  location,
  closeSubNavi,
  hideParentLink,
  theme,
  currentPage,
}) => {
  const [isCurrent, setIsCurrent] = useState(false)
  const [layerOpen, setLayerOpen] = useState(false)
  const [childIsOpen, setChildIsOpen] = useState(false)

  const { setMainItemIndex, isSelected, resetLevel, openNavigation } =
    useContext(NavigationContext)

  const selected = isSelected(index)

  const defaultLang = process.env.GATSBY_DEFAULT_LANG
    ? process.env.GATSBY_DEFAULT_LANG
    : "de"

  const currentState = ({ isCurrent, isPartiallyCurrent, href, location }) => {
    const isRoot = href === "/" || href === `/${language}/`
    const isBlogRoot = href === "/blog/" || href === `/${language}/blog/`
    const langPrefix = language === defaultLang ? "" : `/${language}`
    const modeToPath = mode === "group" ? "/" : `/${mode}`

    if (location.pathname.includes(`/blog/`)) {
      if (location.pathname.includes(`blog/central-laboratory/`)) {
        if (text === "Central Lab Services") {
          setIsCurrent(true)
          return { className: "current" }
        }
      }
      if (location.pathname.includes(`blog/Clinical-Trial-Supply/`)) {
        if (text === "Clinical Trial Supply") {
          setIsCurrent(true)
          return { className: "current" }
        }
      }
      if (
        text === "GBA Group" &&
        (location.pathname === "/blog/" ||
          location.pathname.includes(`blog/group/`))
      ) {
        setIsCurrent(true)
        return { className: "current" }
      }
    } else {
      if (children) {
        children.forEach((child) => {
          if (child.open) setChildIsOpen(true)
        })
      }

      if (isCurrent || currentPage) {
        setIsCurrent(true)
        return { className: "current" }
      }

      if (!isRoot && isPartiallyCurrent) {
        setIsCurrent(true)
        return { className: "partially-current" }
      }

      // Special Case root => ohne diese Implementierung wird das Root Main Item immer als aktiv angezeigt.
      if (location.pathname.includes("/gba-group/") && isPartiallyCurrent) {
        setIsCurrent(true)
        return { className: "partially-current" }
      }

      if (
        !isRoot &&
        location.pathname !== "/blog/" &&
        (location.pathname.includes(`/blog${modeToPath}`) ||
          location.pathname.includes(`/events${modeToPath}`)) &&
        href.startsWith(`${langPrefix}${modeToPath}`)
      ) {
        setIsCurrent(true)
        return { className: "current" }
      }

      if (
        isRoot &&
        location.pathname !== "/blog/" &&
        location.pathname.includes("/blog/") &&
        !location.pathname.match(/\/blog\/(pharma|environment|food)/i)
      ) {
        setIsCurrent(true)
        return { className: "current" }
      }
    }
  }

  const layerComponent = selected ? (
    <Layer
      parent={text}
      parentUrl={link}
      items={children}
      extras={extras}
      language={language}
      level={1}
      mode={mode}
      selected={selected}
      location={location}
      hideParentLink={hideParentLink}
    />
  ) : null

  const { rawColors: colors } = useThemeUI().theme
  return (
    <div
      sx={{
        variant: "navigation.mainItemDiv",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& > a": {
          textDecoration: "none",
        },
        backgroundColor: isCurrent || childIsOpen ? "#fff" : null,
        "::after": {
          display: isCurrent || childIsOpen ? "inline-block" : "none",
          content: '"..."',
          position: "absolute",
          bottom: ab({ _: "-2px", md: "0px" }),
          left: 0,
          right: 0,
          textAlign: "center",
          color:
            selected || isCurrent || childIsOpen
              ? colors.navigation[mode]
              : colors.text,
          variant: "guc.navigation.mainItemAfter",
        },
      }}
    >
      <Link
        onClick={(e) => {
          if (children.length) {
            e.preventDefault()
            resetLevel(1)
            setMainItemIndex(index)
            openNavigation()
          }
        }}
        to={link}
        language={language}
        getProps={currentState}
      >
        {icon && (
          <div
            sx={{
              variant: "navigation.iconContainer",
              textAlign: "center",
            }}
          >
            <Icon
              style={{
                variant: "navigation.icon",
                fill:
                  selected || isCurrent || childIsOpen
                    ? colors.navigation[mode]
                    : colors.text,
              }}
              icon={icon}
            />
          </div>
        )}
        <div
          className="main-navigation-item-text"
          sx={{
            textAlign: "center",
            fontSize: ab({ _: "9px", md: "11px" }),
            color: theme ? colors.modes.group.primary : colors.text,
            variant: "guc.mainNavigationItem",
          }}
        >
          {text}
        </div>
      </Link>
      {children && layerComponent}
    </div>
  )
}

MainNavigationItem.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.array,
  extras: PropTypes.array,
  location: PropTypes.object.isRequired,
}

export default MainNavigationItem
