/** @jsx jsx */
import { useContext } from "react"
import { jsx, useThemeUI } from "theme-ui"
import PropTypes from "prop-types"

import ModeContext from "../context/mode-context"
import Image from "./image"
import { ab } from "../theme"
import Link from "./link"

const ContactTeaser = ({
  currentLanguage,
  children,
  image,
  imageAlt,
  subHeading,
  buttonText,
  link,
  linkTarget,
  reverse,
  mode,
}) => {
  const { mode: globalMode } = useContext(ModeContext)
  const { rawColors: colors } = useThemeUI().theme

  const modeKey = mode ? mode : globalMode

  let imageElement = (
    <Image
      image={image}
      alt={imageAlt ? imageAlt : heading}
      sizes={["100vw", "100vw", "50vw", "50vw"]}
      style={{
        height: "100%",
        width: "100%",
      }}
    />
  )

  return (
    <div
      className="contact-teaser-container"
      sx={{
        pt: 5,
        display: "flex",
        flexDirection: reverse
          ? ab({ _: "column-reverse", md: "row" })
          : ab({ _: "column", md: "row" }),
        flexGrow: 1,
        "& > div": {
          width: ab({ _: "", md: "50%" }),
          display: "flex",
        },
        "&  a": {
          color: "#fff",
        },
      }}
    >
      <div>{imageElement}</div>

      <div
        sx={{
          flexDirection: "column",
          bg: modeKey
            ? colors.modes[modeKey].primary
            : colors.modes["group"].primary,
          py: 4,
          px: ab({ _: 4, md: 4, lg: 5 }),
          color: "#fff",
        }}
      >
        <h6
          sx={{
            fontSize: "14px",
            letterSpacing: 2,
            my: 2,
            textTransform: "uppercase",
            fontWeight: 1,
          }}
        >
          {subHeading}
        </h6>
        <div
          sx={{
            " & h3": {
              margin: "0",
              p: "0",
              fontSize: "22px",
              fontWeight: "normal",
            },
            " & h3 + p": {
              mt: 0,
            },
          }}
        >
          {children}
        </div>

        {buttonText && (
          <div>
            <Link
              to={link}
              target={linkTarget}
              language={currentLanguage}
              sx={{
                display: ab({ _: "block", md: "inline" }),
                variant: "styles.ghostButton",
                color: "#fff",
                border: "solid 1px",
                py: 2,
                px: 4,
                my: 2,
                textTransform: "capitalize",
                "&:hover": {
                  bg: "#fff",
                  color: modeKey
                    ? colors.modes[modeKey].primary
                    : colors.modes["group"].primary,
                },
              }}
            >
              {buttonText}
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

ContactTeaser.propTypes = {
  subHeading: PropTypes.string.isRequired,
  link: PropTypes.string,
  buttonText: PropTypes.string,
  children: PropTypes.node,
  linkTarget: PropTypes.string,
  reverse: PropTypes.bool,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  imageAlt: PropTypes.string,
}

export default ContactTeaser
