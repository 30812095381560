/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { ab } from "../theme"

const LogoGallery = ({ heading, children }) => (
  <div
    sx={{
      variant: "alternatingSpacingContainer",
      color: "text",
    }}
  >
    <h2
      sx={{
        variant: "bigblogteaser.h2",
        marginBottom: 0,
      }}
    >
      {heading}
    </h2>
    <div
      sx={{
        textAlign: "center",
        "& > div:nth-of-type(2n +1)": {
          paddingLeft: ab({ _: "0 !important", md: "16px !important" }),
        },
        "& > div:nth-of-type(2n)": {
          paddingRight: ab({ _: "0 !important", md: "16px !important" }),
        },
        "& > div:nth-of-type(4n +1)": {
          paddingLeft: ab({ md: "0 !important" }),
        },
        "& > div:nth-of-type(4n)": {
          paddingRight: ab({ md: "0 !important" }),
        },
      }}
    >
      {children}
    </div>
  </div>
)

LogoGallery.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default LogoGallery
