/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { ModalProvider } from "../context/modal-context"

import { ab } from "../theme"

const Shop = ({ children }) => (
  <div>
    <div
      className="shop-items"
      sx={{
        maxWidth: "100%",
        variant: "spacingContainer",
        display: "grid",
        gridTemplateColumns: ab({
          _: "1fr",
          sm: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr) !important",
        }),
        gridColumnGap: ab({ _: 0, sm: "60px" }),
        gridRowGap: ab({ _: "80px", sm: "60px" }),
      }}
    >
      <ModalProvider>{children}</ModalProvider>
    </div>
    <div
      id="modal-root"
      sx={{
        position: "absolute",
        left: 0,
      }}
    ></div>
  </div>
)

Shop.prototype = {
  children: PropTypes.node,
}

export default Shop
