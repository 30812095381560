/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, useContext } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

import { FormName } from "./form/formContext"
import BUWaterContext from "../context/bu-water-context"

import { ab } from "../theme"
import Button from "./button"

const Form = ({
  formName,
  heading,
  subHeading,
  buttonText,
  successHeading,
  successMessage,
  successBtnText,
  redirectAfterSubmit,
  children,
  authenticationRequired,
  currentLanguage,
}) => {
  const [submitted, setSubmitted] = useState(false)
  const { customer, sec, version } = useContext(BUWaterContext)

  const apiUrl = version
    ? `https://ojqzdmsnzu2mi5u3dgg2f54u4u0bxrmr.lambda-url.eu-central-1.on.aws?id=${customer.id}&sec=${sec}&v=${version}`
    : `https://ojqzdmsnzu2mi5u3dgg2f54u4u0bxrmr.lambda-url.eu-central-1.on.aws?id=${customer.id}&sec=${sec}`

  const handleSubmit = (e) => {
    e.preventDefault()

    const formData = new FormData(e.target)
    const plainFormData = Object.fromEntries(formData.entries())
    const formDataJsonString = JSON.stringify(plainFormData)

    fetch(apiUrl, {
      method: "POST",
      headers: {
        accept: "application/json",
      },
      body: formDataJsonString,
    })
      .then((response) => {
        if (response.ok) {
          setSubmitted(true)
        } else {
          alert(response.statusText)
        }
      })
      .catch((error) => alert(error))
  }

  const returnAfterSubmit = () => {
    if (redirectAfterSubmit) {
      navigate(redirectAfterSubmit)
    } else {
      setSubmitted(false)
    }
  }

  const uniqueFormName = `${formName}-${currentLanguage}`

  if (!submitted) {
    return (
      <div
        sx={{
          variant: "spacingContainer",
          maxWidth: "1100px",
          "p.form-submit-button button": {
            color: "#000 !important",
          },
        }}
      >
        <div>
          <div
            sx={{
              fontSize: "34px",
              letterSpacing: 1,
            }}
          >
            {heading}
          </div>
          <div sx={{ marginBottom: 3 }}>{subHeading}</div>
        </div>
        <form
          name={uniqueFormName}
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value={uniqueFormName} />
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="language" value={currentLanguage} />

          <FormName.Provider value={uniqueFormName}>
            {children}
          </FormName.Provider>
        </form>
      </div>
    )
  } else {
    return (
      <div
        sx={{
          variant: "spacingContainer",
        }}
      >
        <div
          sx={{
            maxWidth: ab({ lg: "50%" }),
          }}
        >
          <h1
            sx={{
              px: ab({ _: "0 !important", md: "0 !important" }),
            }}
          >
            {successHeading}
          </h1>
          {successMessage}
          <p>
            <Button onClick={returnAfterSubmit} buttonText={successBtnText} />
          </p>
        </div>
      </div>
    )
  }
}

Form.propTypes = {
  formName: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  successHeading: PropTypes.string.isRequired,
  successMessage: PropTypes.node.isRequired,
  successBtnText: PropTypes.string.isRequired,
  authenticationRequired: PropTypes.bool,
  currentLanguage: PropTypes.string.isRequired,
  children: PropTypes.node,
}

Form.defaultProps = {
  buttonText: "Senden",
  successBtnText: "Weiter",
  authenticationRequired: false,
}

export default Form
