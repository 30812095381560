import React from "react"
import PropTypes from "prop-types"
import ThemeContext from "../context/theme-context"

import CallToActionArea from "./call-to-action-area"

const CallToActionAreaConsumer = ({
  heading,
  subHeading,
  buttonLink,
  buttonText,
  children,
  mode,
  download,
  triggerSearchDialog,
  linkTarget,
  currentLanguage,
  disableHyphens,
}) => {
  if (triggerSearchDialog) {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <CallToActionArea
            heading={heading}
            subHeading={subHeading}
            buttonLink={buttonLink}
            buttonText={buttonText}
            download={download}
            mode={mode}
            triggerSearchDialog={triggerSearchDialog}
            linkTarget={linkTarget}
            toggleSearchDialog={theme.toggleSearchVisibility}
            currentLanguage={currentLanguage}
            disableHyphens={disableHyphens}
          >
            {children}
          </CallToActionArea>
        )}
      </ThemeContext.Consumer>
    )
  }

  return (
    <CallToActionArea
      heading={heading}
      subHeading={subHeading}
      buttonLink={buttonLink}
      buttonText={buttonText}
      download={download}
      mode={mode}
      triggerSearchDialog={triggerSearchDialog}
      linkTarget={linkTarget}
      currentLanguage={currentLanguage}
      disableHyphens={disableHyphens}
    >
      {children}
    </CallToActionArea>
  )
}

CallToActionAreaConsumer.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
  children: PropTypes.node,
  download: PropTypes.bool,
  mode: PropTypes.oneOf([
    "",
    "pharma",
    "food",
    "environment",
    "career",
    "construction",
    "medical",
    "lifeScience",
    "agroChemical",
    "sustainabilityServices",
    "none",
  ]),
  triggerSearchDialog: PropTypes.bool,
  linkTarget: PropTypes.string,
  disableHyphens: PropTypes.bool,
}

CallToActionArea.defaultProps = {
  mode: "",
  download: false,
  triggerSearchDialog: false,
  disableHyphens: false,
}

export default CallToActionAreaConsumer
