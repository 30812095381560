/** @jsx jsx */
import { jsx } from "theme-ui"
import Image from "./image"
import PropTypes from "prop-types"

const Testimonial = ({ image, imageAlt, name, children }) => {
  let imageElement = (
    <Image
      image={
        typeof image === "object"
          ? {
              ...image,
              sizes: "(min-width: 824px) 300px, 300px",
            }
          : image
      }
      sizes={["25vw", "25vw", "25vw", "25vw"]}
      style={{
        borderRadius: "999px",
      }}
      alt={imageAlt}
    />
  )
  return (
    <div
      sx={{
        textAlign: "center",
        width: "auto",
        "& p": {
          m: 0,
        },
      }}
    >
      <div
        sx={{
          maxWidth: "260px",
          textAlign: "center",
          m: "auto",
        }}
      >
        {imageElement}
      </div>
      <h3
        sx={{
          mt: 0,
          mb: 0,
        }}
      >
        {name}
      </h3>
      {children}
    </div>
  )
}

Testimonial.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  imageAlt: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string,
}

export default Testimonial
