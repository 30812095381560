/** @jsx jsx */
import { jsx } from "theme-ui"

const Envelope = ({ style = {} }) => (
  <svg
    width="38"
    height="38"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 325.35"
  >
    <path
      sx={style}
      d="M250 197.23L487.46 6.1A28.15 28.15 0 00470.14 0H29.85a28.17 28.17 0 00-17.32 6.1z"
    />
    <path
      sx={style}
      d="M499 300.83a34.29 34.29 0 001-7.68v-257L357.8 150.64z"
    />
    <path
      sx={style}
      d="M333.36 170.31L250 237.4l-83.37-67.09L22.15 324.12a27.54 27.54 0 007.7 1.23h440.31a27.6 27.6 0 007.71-1.23z"
    />
    <path sx={style} d="M142.2 150.64L0 36.18v257a34.36 34.36 0 001 7.68z" />
  </svg>
)

export default Envelope
