/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import { useState } from "react"
import PropTypes from "prop-types"
import { ab } from "../theme"

const Timeline = ({ year, colLeft, colRight, toggleOn }) => {
  const [toggle, setToggle] = useState(toggleOn)
  const { rawColors: colors } = useThemeUI().theme

  return (
    <div
      sx={{
        px: ab({ _: 3, md: 5 }),
        display: "grid",
        gridTemplateColumns: ab({ _: "150px auto auto ", md: "1fr 1px 1fr" }),
        gridTemplateAreas: ab({
          _: ` "header header header "
                "line contain1 contain1 "
                "line contain2 contain2"`,
          md: ` "header header header "
                "contain1 line contain2"`,
        }),
        "& div": {
          pl: ab({ _: 0, md: 5 }),
          pr: ab({ _: 4, md: 5 }),
        },
        "& img": {
          maxWidth: "100%",
        },
      }}
    >
      <h2
        sx={{
          textAlign: ab({ _: "left", md: "center" }),
          m: 0,
          gridArea: "header",
        }}
      >
        <span
          sx={{
            ml: ab({ _: "20px", md: "auto" }),
            bg: toggle ? colors.modes.group.primary : colors.background,
            color: toggle ? colors.background : colors.modes.group.primary,
            px: ab({ _: "26px", md: 4 }),
            py: 2,
            borderRadius: "32px",
            cursor: "pointer",
            display: "inline-block",
            border: "solid 1px",
            borderColor: colors.modes.group.primary,
            ":hover": {
              bg: colors.modes.group.primary,
              color: colors.background,
              "& span": {
                display: "none",
              },
            },
          }}
          onClick={() => setToggle(!toggle)}
        >
          {year}
          <span
            className="form-arrow"
            sx={{
              variant: "form.arrow",
              display: toggle ? "none" : "inline-block",
              position: "relative",
              borderColor: colors.modes.group.primary,
              transform: "rotate(138deg)",
              right: "-5px",
              width: "12px",
              height: "12px",
              top: "-2px",
              borderWidth: "2px",
            }}
          ></span>
        </span>
      </h2>

      <div
        sx={{
          gridArea: "contain1",
          marginLeft: ab({ _: -4, md: 0 }),
          pt: ab({ _: 5, md: 4 }),
          maxHeight: toggle ? "100%" : 0,
          overflow: "hidden",
          transition: "max-height ease-out .4s",
          "& h2": {
            margin: 0,
          },
        }}
      >
        {colLeft}
      </div>

      <div
        sx={{
          gridArea: "contain2",
          marginLeft: ab({ _: -4, md: 0 }),
          pt: ab({ _: 5, md: 4 }),
          mb: ab({ _: 5, md: 4 }),
          maxHeight: toggle ? "100%" : 0,
          overflow: "hidden",
          transition: "max-height ease-out .4s",
          "& h2": {
            margin: 0,
          },
        }}
      >
        {colRight}
      </div>
      <div
        sx={{
          gridArea: "line",
          bg: colors.modes.group.primary,
          width: "2px !important",
          p: "0px !important",
          ml: ab({ _: "74px", md: 0 }),
          minHeight: "100px",
        }}
      ></div>
    </div>
  )
}
Timeline.propTypes = {
  year: PropTypes.string.isRequired,
  colLeft: PropTypes.node,
  colRight: PropTypes.node,
}
export default Timeline
