/** @jsx jsx */
import { jsx } from "theme-ui"

const Cls = ({ style = {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 236.55">
    <g>
      <path
        sx={style}
        d="m125,0C55.96,0,0,55.96,0,125c0,48.72,27.88,90.93,68.55,111.55v-.26c0-7.89,6.39-14.28,14.28-14.28h100.57c4.96-.01,9.34,2.51,11.91,6.34,33.01-22.5,54.69-60.4,54.69-103.36C250,55.96,194.04,0,125,0Zm42.98,148s0,0,0,.01c1.95,3.43.75,7.79-2.68,9.73l-18.07,10.46s-.02.01-.03.02c-3.42,1.97-7.78.79-9.75-2.62l-2.89-5.03.39-.21s25.88-14.92,30.1-17.39l2.93,5.03Zm-44.56-76.24c3.42-1.97,7.78-.79,9.75,2.62l30.53,52.84s.01.02.02.03c1.97,3.42.79,7.78-2.62,9.75-7.35,4.25-22.39,12.89-29.74,17.17-.01,0-.02.01-.03.02-3.42,1.97-7.78.79-9.75-2.62l-7.14-12.5c4.58-1.81,8.49-5.01,11.17-9.14,10.64-15.24-1.57-37.49-19.96-36.99-5.4,0-10.64,1.84-14.85,5.21-1.57-3.36-.32-7.36,2.89-9.21h0c.14-.04,25.6-14.78,29.7-17.17.01,0,.02-.01.03-.02Zm-34.48,44.47c1.32-21.6,32.77-21.21,33.45.61-.82,22.56-33.6,21.96-33.45-.61Zm-6.64-53.52l25.24-14.67s.02-.01.03-.02c3.42-1.97,7.78-.79,9.75,2.62,1.54,2.64,4.11,6.11,2.96,9.32-.35,1.24-1.03,2.36-1.96,3.25l1.39,2.43-28.81,16.82-1.5-2.53c-3.06.62-6.17-.83-7.68-3.57l-2.07-3.86s-.02-.03-.02-.04c-1.95-3.43-.76-7.79,2.67-9.74Zm92.54,144.42h-34.88l2.57,7.75h-52.16l1.18-3.57h-.14c-14.65-4.15-26.85-14.32-33.58-27.97-13.12-26.64-2.16-58.87,24.48-71.99-.43,1.78-.63,3.6-.61,5.43-.02,5.61,1.97,11.04,5.61,15.32-6.52,4.55-11.2,11.28-13.19,18.97-4.62,17.83,6.08,36.03,23.9,40.65,1.37-3.62,4.84-6.01,8.71-6h68.12c5.92,0,10.71,4.8,10.71,10.71s-4.8,10.71-10.71,10.71Z"
      />
    </g>
  </svg>
)

export default Cls
