/** @jsx jsx */
import { jsx } from "theme-ui"
import { useContext } from "react"

import BUWaterContext from "../../context/bu-water-context"

const BuWaterName = () => {
  const { customer } = useContext(BUWaterContext)

  return (
    <div
      sx={{
        mt: 4,
        flex: 1,
      }}
    >
      <p
        sx={{
          lineHeight: "1.7",
        }}
      >
        <strong>{customer.email}</strong>
      </p>
    </div>
  )
}

export default BuWaterName
