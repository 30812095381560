/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import Link from "./link"
import SpecialHeader from "./special-header"
import { ab } from "../theme"

const BlogListing = ({
  numPages,
  baseLink,
  currentPage,
  noEntries,
  noEntriesText,
  headline,
  pageTitle,
  slogan,
  bloksAfter,
  children,
  first,
  last,
  prev,
  next,
}) => {
  let paging = []
  let noEntriesInfo
  if (noEntries) {
    noEntriesInfo = <div>{noEntriesText}</div>
  }
  let goToFirstPageButton
  let goToLastPageButton
  let prevButton
  let nextButton

  const renderBloksAfter = bloksAfter ? bloksAfter : null

  if (numPages > 1) {
    goToFirstPageButton = (
      <Link to={`${baseLink}`} title={first}>
        ❮❮
      </Link>
    )
    goToLastPageButton = (
      <Link to={`${baseLink}${numPages}`} title={last}>
        ❯❯
      </Link>
    )
    if (currentPage !== 1) {
      prevButton = (
        <Link
          to={
            currentPage === 2 ? `${baseLink}` : `${baseLink}${currentPage - 1}`
          }
          title={prev}
          rel="prev"
        >
          ❮{" "}
        </Link>
      )
    }

    if (currentPage !== numPages) {
      nextButton = (
        <Link to={`${baseLink}${currentPage + 1}`} rel="next" title={next}>
          ❯
        </Link>
      )
    }

    let pagingStart = 0
    let pagingEnd = numPages
    let pagingLimit = 7
    let pagingPreviewWidth = 3
    if (numPages > pagingLimit) {
      if (currentPage >= pagingLimit - pagingPreviewWidth) {
        pagingStart = currentPage - pagingPreviewWidth - 1
        pagingEnd = currentPage + pagingPreviewWidth
        pagingStart =
          pagingEnd > numPages
            ? pagingStart - pagingEnd + numPages
            : pagingStart
        pagingEnd = pagingEnd > numPages ? numPages : pagingEnd
      } else {
        pagingEnd = pagingLimit
      }
    }
    for (var i = pagingStart; i < pagingEnd; i++) {
      paging.push(
        <Link
          key={i}
          to={i === 0 ? `${baseLink}` : `${baseLink}${i + 1}`}
          className={currentPage === i + 1 ? "current" : ""}
        >
          {i + 1}
        </Link>
      )
    }
  }
  return (
    <div>
      <SpecialHeader pageTitle={pageTitle} slogan={slogan} />
      <div
        sx={{
          variant: "alternatingSpacingContainer",
          backgroundColor: "secondaryBackground",
          color: "text",
        }}
      >
        <h2>{headline}</h2>

        {noEntriesInfo}
        <div>{children}</div>
        <div
          sx={{
            textAlign: "center",
            pt: 5,
            "& a": {
              variant: "styles.linkbtmbrd",
              display: "inline-block",
              px: 2,
            },
            "& a.current": {
              "&:after": {
                content: "''",
                width: "100%",
                height: 2,
              },
            },
          }}
        >
          <span sx={{ display: ab({ _: "none", md: "inline" }) }}>
            {goToFirstPageButton}
          </span>
          <span sx={{ display: ab({ _: "none", md: "inline" }) }}>
            {prevButton}
          </span>
          {paging}

          <div
            sx={{
              textAlign: "center",
              marginTop: 2,
              display: ab({ _: "block", md: "inline" }),
            }}
          >
            <span sx={{ display: ab({ _: "inline", md: "none" }) }}>
              {prevButton}
            </span>
            {nextButton}
          </div>
          <span sx={{ display: ab({ _: "none", md: "inline" }) }}>
            {goToLastPageButton}
          </span>
        </div>
      </div>
      {renderBloksAfter}
    </div>
  )
}

BlogListing.propTypes = {
  slogan: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  numPages: PropTypes.number.isRequired,
  baseLink: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  noEntries: PropTypes.bool.isRequired,
  noEntriesText: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  bloksAfter: PropTypes.node,
  children: PropTypes.node,
  first: PropTypes.string.isRequired,
  last: PropTypes.string.isRequired,
  prev: PropTypes.string.isRequired,
  next: PropTypes.string.isRequired,
}

export default BlogListing
