/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

const LoadingSpinner = ({ hide }) => (
  <div
    sx={{
      display: hide ? "none" : "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      top: "0",
      right: "0",
      bottom: "0",
      left: "0",
    }}
    className="fullsize-overlay"
  >
    <Helmet>
      <body style={{ width: "100vw", height: "100vh" }} />
    </Helmet>
    <img
      sx={{
        width: "60px",
        height: "60px",
      }}
      src={"/spinner.gif"}
      alt="loading"
    />
  </div>
)

LoadingSpinner.propTypes = {
  hide: PropTypes.bool,
}

LoadingSpinner.defaultProps = {
  hide: false,
}

export default LoadingSpinner
