/** @jsx jsx */
import { jsx } from "theme-ui"

const HusContactIcon = ({ style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 162.55 162.55"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
  >
    <g id="Ebene_1-2" data-name="Ebene 1">
      <path
        sx={style}
        d="m81.27,0C36.38,0,0,36.38,0,81.27c0,11.34,2.32,22.14,6.51,31.94,1.67,3.89,3.63,7.64,5.87,11.2,3.16,5.05,6.87,9.71,11.03,13.93,6.14,6.23,13.28,11.47,21.16,15.46,11.03,5.59,23.5,8.74,36.7,8.74,1.2,0,2.4-.03,3.59-.08,8.99-.39,17.59-2.24,25.58-5.31,15.85-6.1,29.31-17.02,38.58-30.96,2.71-4.08,5.06-8.43,7.02-12.98,4.19-9.8,6.51-20.6,6.51-31.94C162.55,36.38,126.16,0,81.27,0Zm-16.35,53.59h69.66l-41.37,28.36-28.14-19.29c1.07-2.87,1.08-6.11-.14-9.07h0Zm35.95,77.99c-.56,1.8-1.72,3.13-3.41,3.94l-18.33,8.72c-27.42-6.92-63.18-67.62-55.23-95.65l16.72-11.51c1.55-1.07,3.28-1.4,5.12-.99,1.83.41,3.26,1.46,4.2,3.09l9.17,15.88c1.76,3.06.76,6.97-2.25,8.8l-10.15,6.18c2.79,13.52,14.93,34.55,25.24,43.72l10.43-5.7c3.1-1.69,6.99-.6,8.75,2.45l9.17,15.88c.94,1.63,1.14,3.39.58,5.18Zm38.9-18.91c0,2.61-2.14,4.75-4.75,4.75h-33.11l-5.68-9.85c-3.36-5.82-10.77-7.9-16.66-4.67l-6.6,3.61c-7.74-8.37-16.09-22.83-19.46-33.71l6.42-3.91c.67-.41,1.29-.87,1.85-1.38l29.73,20.38c.97.7,2.32.76,3.37.04l44.78-30.7h0c.09.36.13.73.13,1.11v54.33Z"
      />
    </g>
  </svg>
)

export default HusContactIcon
