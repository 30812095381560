/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import { ab } from "../../theme"
import ReactDOM from "react-dom"
import React, { useState, useEffect, useRef } from "react"
import Info from "./info-popup"
import ToggleButton from "./toggle-button"
import Arrow from "../../assets/left-icon"
import { DownloadIcon } from "../../assets/icons"
import Markdown from "react-markdown"

const ProductTable = ({
  searchTerm,
  csvData,
  popupText1,
  popupText2,
  popupText3,
  popupText4,
  viewInfo,
  productPerPage,
  explanatory,
  updateDateText,
  updateDate,
  searchTableText,
  tableHeaderArticleNo,
  tableHeaderCertificate,
  tableHeaderCharge,
  tableHeaderHeavymetal,
  tableHeaderMicroOrganism,
  tableHeaderPesticides,
  tableHeaderProduct,
  tableHeaderRemark,
  moreChargesBtnText,
  lessChargesBtnText,
  notRelevantText,
}) => {
  const { rawColors: colors } = useThemeUI().theme
  const [currentPage, setCurrentPage] = useState(1)
  const [paging, setPaging] = useState([])
  const [numPages, setNumPages] = useState(1)
  const dataInstance = useRef(null)
  const dataDiv = useRef(null)

  const [windowSize, setWindowSize] = useState({
    width: undefined,
  })
  const [mobileDisplay, setMobileDisplay] = useState(false)
  const [currentCol, setCurrentCol] = useState(1)
  const [isSticky, setIsSticky] = useState(false)

  const [tableIsBuilding, setTableIsBuilding] = useState(false)
  const [popupText1Expanded, setPopupText1Expanded] = useState(false)
  const [popupText2Expanded, setPopupText2Expanded] = useState(false)
  const [popupText3Expanded, setPopupText3Expanded] = useState(false)
  const [popupText4Expanded, setPopupText4Expanded] = useState(false)

  const scrollToTop = () => {
    dataDiv.current.scrollIntoView()
  }

  const clickPagination = (i) => {
    setTableIsBuilding(true)
    setCurrentPage(i)
    scrollToTop()
  }
  let perPage = productPerPage
  let goToFirstPageButton
  let goToLastPageButton
  let prevButton
  let nextButton
  let filterData = []

  let pagingStart = 0
  let pagingEnd = numPages
  let pagingLimit = 4
  let pagingPreviewWidth = 1
  const data = csvData
  const keys = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"]
  const dumpTR = () => {
    let tds = []

    let td1 = React.createElement("td", {}, "Broncho-in-form® Immun direkt")
    tds.push(td1)
    let td2 = React.createElement("td", {}, "2000.1826")
    tds.push(td2)

    let td3 = React.createElement("td", {}, "")
    tds.push(td3)

    let td4 = React.createElement("td", {}, "✓")
    tds.push(td4)

    let td5 = React.createElement("td", {}, "✓")
    tds.push(td5)

    let td6 = React.createElement("td", {}, notRelevantText)
    tds.push(td6)

    let td7 = React.createElement("td", {}, "Bestanden")
    tds.push(td7)

    let td8 = React.createElement("td", {}, "")
    tds.push(td8)

    let tr = React.createElement(
      "tr",
      {
        className: "notVisible",
      },
      tds
    )

    return tr
  }

  useEffect(() => {
    setCurrentPage(1)
    let node = document.getElementById("table-data")
    if (searchTerm === searchTableText.toLowerCase()) {
      searchTerm = ""
    }

    data.forEach((ele) => {
      if (
        ele.Produkte.toLowerCase().includes(searchTerm) ||
        ele.name.toLowerCase().includes(searchTerm) ||
        ele.nameHypen.toLowerCase().includes(searchTerm) ||
        ele.Artikelnummer.toLowerCase().includes(searchTerm)
        //ele.Chargennummer.includes(searchTerm) ||
        //ele.Chargennummer2.includes(searchTerm)
      ) {
        filterData.push(ele)
      }
    })

    filterData.sort((a, b) => {
      let re = new RegExp("^" + searchTerm, "i")
      return re.test(a.name)
        ? re.test(b.name)
          ? a.name.localeCompare(b.name)
          : -1
        : 1
    })
    let tBody = buildTable()
    if (filterData.length === 0) {
      let tBody2 = dumpTR()
      ReactDOM.render(tBody2, node)
    } else {
      ReactDOM.render(tBody, node)
    }
  }, [searchTerm, data])

  useEffect(() => {
    let rows = document.querySelectorAll(".mainTr")
    setNumPages(Math.ceil(filterData.length / perPage)) //tr toggle is added in buildTable
  }, [filterData.data, searchTerm, filterData.length, perPage])

  useEffect(() => {
    let pages = []
    if (numPages > pagingLimit) {
      if (currentPage >= pagingLimit - pagingPreviewWidth) {
        pagingStart = currentPage - pagingPreviewWidth - 1
        pagingEnd = currentPage + pagingPreviewWidth
        pagingStart =
          pagingEnd > numPages
            ? pagingStart - pagingEnd + numPages
            : pagingStart
        pagingEnd = pagingEnd > numPages ? numPages : pagingEnd
      } else {
        pagingEnd = pagingLimit
      }
    }

    for (let i = pagingStart; i < pagingEnd; i++) {
      pages.push(
        <span
          key={i}
          title={i + 1}
          onClick={() => clickPagination(i + 1)}
          className={currentPage === i + 1 ? "current" : ""}
        >
          {i + 1}
        </span>
      )
    }
    setPaging(pages)
  }, [numPages, currentPage, searchTerm])

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: document.body.clientWidth,
      })
    }
    if (windowSize.width < 825) {
      setMobileDisplay(true)
    }
    window.addEventListener("resize", handleResize)

    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [numPages, windowSize.width])

  let build

  const buildTable = () => {
    let col = []
    for (let i = 0; i < data.length; i++) {
      for (let key in data[i]) {
        if (col.indexOf(key) === -1) {
          col.push(key)
        }
      }
    }

    let tdToggleData = (i, k, l) => {
      let alltds = []
      for (let j = k; j < l; j++) {
        let td
        if (j === l - 3) {
          td = React.createElement(
            "td",
            {},
            filterData[i][col[j]] === ""
              ? notRelevantText
              : filterData[i][col[j]] &&
                  filterData[i][col[j]].toLowerCase() === "x"
                ? "✓"
                : filterData[i][col[j]]
          )
        } else if (j === l - 1) {
          td = React.createElement(
            "td",
            {},
            <a
              href={filterData[i][col[l - 1]] ? filterData[i][col[l - 1]] : "#"}
              target="_blank"
              role="button"
              tabIndex={0}
              sx={{
                display: filterData[i][col[l - 1]].trim(" ")
                  ? "inline-block"
                  : "none",
                textDecoration: "none",
                color: "#004787",
                width: "20px",
                mt: 2,
              }}
            >
              <DownloadIcon />
            </a>
          )
        } else {
          td = React.createElement(
            "td",
            {},
            filterData[i][col[j]] && filterData[i][col[j]].toLowerCase() === "x"
              ? "✓"
              : filterData[i][col[j]]
          )
        }
        alltds.push(td)
      }
      return alltds
    }
    let trs = []
    for (let i = 0; i < filterData.length; i++) {
      let tds = []
      for (let j = 0; j < 8; j++) {
        if (j === 2) {
          let td = React.createElement(
            "td",
            {},
            <div>
              {" "}
              {filterData[i][col[j]]}
              {filterData[i]["Charge-2"] !== "" ? (
                <ToggleButton
                  idx={i}
                  moreChargesBtnText={moreChargesBtnText}
                  lessChargesBtnText={lessChargesBtnText}
                />
              ) : (
                ""
              )}{" "}
            </div>
          )
          tds.push(td)
        }

        if (j === 5) {
          let td = React.createElement(
            "td",
            {},
            <span>
              {" "}
              {filterData[i][col[j]] === ""
                ? notRelevantText
                : filterData[i][col[j]] &&
                    filterData[i][col[j]].toLowerCase() === "x"
                  ? "✓"
                  : filterData[i][col[j]]}
            </span>
          )
          tds.push(td)
        }

        if (j === 7) {
          let td = React.createElement(
            "td",
            {},
            <a
              href={filterData[i][col[7]] ? filterData[i][col[7]] : "#"}
              target="_blank"
              role="button"
              tabIndex={0}
              sx={{
                display: filterData[i][col[7]].trim(" ")
                  ? "inline-block"
                  : "none",
                textDecoration: "none",
                color: "#004787",
                width: "20px",
                mt: 2,
              }}
            >
              <DownloadIcon />
            </a>
          )
          tds.push(td)
        } else if (j !== 2 && j !== 5 && j !== 8) {
          let td = React.createElement(
            "td",
            { key: `tr_` + j.toString() },

            filterData[i][col[j]] && filterData[i][col[j]].toLowerCase() === "x"
              ? "✓"
              : filterData[i][col[j]]
          )
          tds.push(td)
        }
      }
      let trToggle_2 = []
      let trToggle_3 = []
      let trToggle_4 = []
      let trToggle_5 = []
      let trToggle_6 = []
      let trToggle_7 = []
      let trToggle_8 = []
      let trToggle_9 = []

      let emptyTd = React.createElement("td", { className: "emptyTd" }, "")

      trToggle_2.push(emptyTd, emptyTd, tdToggleData(i, 8, 14))
      trToggle_3.push(emptyTd, emptyTd, tdToggleData(i, 14, 20))
      trToggle_4.push(emptyTd, emptyTd, tdToggleData(i, 20, 26))
      trToggle_5.push(emptyTd, emptyTd, tdToggleData(i, 26, 32))
      trToggle_6.push(emptyTd, emptyTd, tdToggleData(i, 32, 38))
      trToggle_7.push(emptyTd, emptyTd, tdToggleData(i, 38, 44))
      trToggle_8.push(emptyTd, emptyTd, tdToggleData(i, 44, 50))
      trToggle_9.push(emptyTd, emptyTd, tdToggleData(i, 50, 56))

      let tr = React.createElement(
        "tr",
        {
          key: "tr__" + keys[i] + i.toString(),
          className: ` mainTr mainTr-${i % 2 ? "odd" : "even"} ${
            i > perPage - 1 ? "hide" : "show"
          }  ${i}`,
        },
        tds
      )

      let tr_2 = React.createElement(
        "tr",
        {
          className: `toggleTr-${i} toggleTr first toggle ${
            i % 2 ? "odd" : "even"
          }`,
        },
        trToggle_2
      )

      let tr_3 =
        filterData[i]["Charge-3"] !== ""
          ? React.createElement(
              "tr",
              {
                className: `toggleTr-${i} toggleTr toggle ${
                  i % 2 ? "odd" : "even"
                }`,
              },
              trToggle_3
            )
          : ""
      let tr_4 =
        filterData[i]["Charge-4"] !== ""
          ? React.createElement(
              "tr",
              {
                className: `toggleTr-${i} toggleTr toggle ${
                  i % 2 ? "odd" : "even"
                }`,
              },
              trToggle_4
            )
          : ""
      let tr_5 =
        filterData[i]["Charge-5"] !== ""
          ? React.createElement(
              "tr",
              {
                className: `toggleTr-${i} toggleTr toggle ${
                  i % 2 ? "odd" : "even"
                }`,
              },
              trToggle_5
            )
          : ""
      let tr_6 =
        filterData[i]["Charge-6"] !== ""
          ? React.createElement(
              "tr",
              {
                className: `toggleTr-${i} toggleTr toggle ${
                  i % 2 ? "odd" : "even"
                }`,
              },
              trToggle_6
            )
          : ""
      let tr_7 =
        filterData[i]["Charge-7"] !== ""
          ? React.createElement(
              "tr",
              {
                className: `toggleTr-${i} toggleTr toggle ${
                  i % 2 ? "odd" : "even"
                }`,
              },
              trToggle_7
            )
          : ""
      let tr_8 =
        filterData[i]["Charge-8"] !== ""
          ? React.createElement(
              "tr",
              {
                className: `toggleTr-${i} toggleTr toggle ${
                  i % 2 ? "odd" : "even"
                }`,
              },
              trToggle_8
            )
          : ""
      let tr_9 =
        filterData[i]["Charge-9"] !== ""
          ? React.createElement(
              "tr",
              {
                className: `toggleTr-${i} toggleTr toggle ${
                  i % 2 ? "odd" : "even"
                }`,
              },
              trToggle_9
            )
          : ""

      trs.push(tr, tr_2, tr_3, tr_4, tr_5, tr_6, tr_7, tr_8, tr_9)
    }
    return trs
  }

  if (numPages > 1) {
    goToFirstPageButton = () => clickPagination(1)
    goToLastPageButton = () => clickPagination(numPages)

    prevButton = () => {
      if (currentPage !== 1) {
        setTableIsBuilding(true)
        setCurrentPage(currentPage - 1)
        scrollToTop()
        return currentPage
      }
      return
    }

    nextButton = () => {
      if (currentPage !== numPages) {
        setTableIsBuilding(true)
        setCurrentPage(currentPage + 1)
        scrollToTop()
        return currentPage
      }
      return
    }
  }

  const showHideCols = (colNo) => {
    let tds = document.querySelectorAll(`#product-table td, #product-table th`)

    for (let i = 0; i < tds.length; i++) {
      let cellIdx = tds[i].cellIndex
      if (cellIdx === 0 || cellIdx === colNo) {
        tds[i].classList.add("show-col")
        tds[i].classList.remove("hide-col")
      } else {
        tds[i].classList.add("hide-col")
        tds[i].classList.remove("show-col")
      }
    }
  }

  const showNextCol = () => {
    if (currentCol >= 0 && currentCol <= 6) {
      setCurrentCol(currentCol + 1)
      showHideCols(currentCol + 1)
    }
  }
  const showPrevCol = () => {
    if (currentCol > 1 && currentCol <= 7) {
      setCurrentCol(currentCol - 1)
      showHideCols(currentCol - 1)
    }
  }
  const Spinner = ({ hide }) => (
    <div
      sx={{
        display: hide ? "none" : "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "60px 0",
      }}
    >
      <img
        sx={{
          width: "40px",
          height: "40px",
        }}
        src={"/spinner.gif"}
        alt="loading"
      />
    </div>
  )

  useEffect(() => {
    setTimeout(() => {
      let rows = document.querySelectorAll(".mainTr")
      rows.forEach(function (el, i) {
        if (i >= currentPage * perPage - perPage && i < currentPage * perPage) {
          el.classList.add("show")
          el.classList.remove("hide")
        } else {
          el.classList.add("hide")
          el.classList.remove("show")
        }
        setTableIsBuilding(false)
      })
    }, 1000)
  }, [filterData, currentPage, perPage])

  useEffect(() => {
    let tr = document.querySelectorAll(`tr.toggle.active`)
    tr.forEach(function (el) {
      el.classList.remove("active")
      el.classList.add(`toggleTr`)
    })
  }, [currentPage])

  const handleScroll = () => {
    return setIsSticky(dataDiv.current.getBoundingClientRect().top <= 0)
  }

  const onTouchScroll = () => {
    return setIsSticky(dataDiv.current.getBoundingClientRect().top <= 0)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    window.addEventListener("touchmove", onTouchScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
      window.removeEventListener("touchmove", onTouchScroll)
    }
  })

  //render method
  return (
    <div
      ref={dataDiv}
      sx={{
        px: ab({ _: 0, md: 3 }),
        pt: ab({ _: 0, md: 4 }),
        pb: 5,
        bg: colors.modes.gutesiegel.primary,
        position: "relative",
        overflow: ab({ _: "hidden", md: "unset" }),
        "& .fixed": {
          position: "fixed !important",
          top: "60px",
        },
      }}
    >
      <div
        className={mobileDisplay && isSticky ? "fixed" : ""}
        sx={{
          display: ab({ _: "block", md: "none" }),
          pt: 4,
          position: "sticky",
          top: "0px !important",
          height: "60px",
          background: "#e5edf2",
          zIndex: "100",
          width: "100% !important",
        }}
      >
        <div
          sx={{
            width: "50%",
            float: "right",
            display: "flex",
            justifyContent: "space-between",
            mt: "-1px",
            span: {
              display: "inline-block",
              backgroundColor: "#F2F6F9",
              maxWidth: 28,
              width: 28,
              p: "4px",
              transition: "all ease-in-out .1s",
              lineHeight: 1,
              borderRight: "solid 1px #ccc",
              cursor: "pointer",
            },
          }}
        >
          <span
            role="button"
            tabIndex={0}
            sx={{ opacity: currentCol > 1 ? "1" : ".3" }}
            onClick={showPrevCol}
          >
            <Arrow />
          </span>
          <em
            sx={{
              fontStyle: "normal",
              fontSize: "11px",
              lineHeight: 2.6,
              color: colors.modes["group"].primary,
            }}
          >
            {" "}
            {viewInfo}
          </em>
          <span
            role="button"
            tabIndex={0}
            sx={{
              transform: "rotate(-180deg)",
              pt: "10px",
              opacity: currentCol < 7 ? "1" : ".3",
            }}
            onClick={showNextCol}
          >
            <Arrow />
          </span>
        </div>
      </div>
      <table
        id="product-table"
        ref={dataInstance}
        sx={{
          display: ab({ _: "table-caption", md: "table" }),
          width: ab({ _: windowSize.width * 5 + "px", md: "100%" }),
          margin: "0 auto",
          borderCollapse: "collapse",
          lineHeight: "1.4",
          minHeight: tableIsBuilding ? 0 : "250px",

          "& tr.show": {
            display: "table-row",
          },
          "& tr.hide": {
            display: "none !important",
          },
          "& tr.notVisible": {
            visibility: "hidden",
            opacity: 0,
          },
          "& tr.toggle.odd": {
            backgroundColor: "rgb(242, 246, 249)",
          },
          "& tr.toggle": {
            backgroundColor: "rgb(220, 230, 239)",
          },

          "& .toggle td:nth-of-type(n+3) ": {
            borderTop: "solid 1px #ccc",
          },
          "& tr.toggleTr": {
            visibility: "collapse",
            height: "0",
            display: "none",
            transition: "all .3s ease-in",
            td: {
              width: "100%",
            },
          },
          "& tbody": {
            width: "100%",
          },
          "& td": {
            px: 1,
            py: 3,
            verticalAlign: "middle",
            textAlign: "center",
            width: ab({
              _: windowSize.width / 2 + "px !important",
              md: "",
              lg: "auto",
            }),
          },

          "& th:nth-of-type(1)": {
            position: "relative",
            pr: "30px",
            width: ab({
              _: windowSize.width / 2 + "px !important",
              md: "",
              lg: "auto",
            }),
            minWidth: ab({ _: "50%", md: "235px" }),
            maxWidth: ab({ _: "", md: "284px" }),
          },
          "& th:nth-of-type(2), & td:nth-of-type(2)": {
            maxWidth: ab({ _: "", md: "120px" }),
            minWidth: ab({ _: "50%", md: "110px" }),
            zIndex: 2,
          },
          "& th:nth-of-type(3)": {
            maxWidth: ab({ _: "", md: "225px" }),
            minWidth: ab({ _: "50%", md: "170px" }),
          },
          "& th:nth-of-type(4)": {
            maxWidth: ab({ _: "", md: "155px" }),
            minWidth: ab({ _: "50%", md: "125px" }),
          },
          "& th:nth-of-type(5)": {
            maxWidth: ab({ _: "", md: "145px" }),
            minWidth: ab({ _: "50%", md: "105px" }),
          },
          "& th:nth-of-type(6)": {
            maxWidth: ab({ _: "", md: "110px" }),
            minWidth: ab({ _: "50%", md: "50px" }),
          },
          "& th:nth-of-type(7)": {
            maxWidth: ab({ _: "", md: "130px" }),
            minWidth: ab({ _: "50%", md: "60px" }),
            "& >div": {
              left: "12px",
            },
          },
          "& th:nth-of-type(8)": {
            maxWidth: ab({ _: "", md: "90px" }),
            minWidth: ab({ _: "50%", md: "70px" }),
            textAlign: "center",
            hyphens: "none",
          },
          "& th:nth-of-type(9)": {
            maxWidth: ab({ _: "", md: "100px" }),
            minWidth: ab({ _: "50%", md: "100px" }),
          },
          "& th:nth-of-type(10)": {
            maxWidth: ab({ _: "", md: "150px" }),
            minWidth: ab({ _: "", md: "150px" }),
          },
          "& th:nth-of-type(n+2)": {
            left: ab({ _: mobileDisplay && isSticky ? "50%" : "", md: "auto" }),
          },
          "& .mainTr-odd": {
            backgroundColor: "rgb(242, 246, 249)",
          },
          "& .mainTr-even": {
            backgroundColor: "rgb(220, 230, 239)",
          },

          "& td:nth-of-type(1)": {
            textAlign: "left",
            pl: "15px",
            hyphens: "none",
          },
          "& .hide-col": {
            width: 0,
            height: "0px !important",
            display: "none !important",
            overflow: "hidden !important",
            margin: "0 !important",
            padding: "0 !important",
            border: "none !important",
            minWidth: 0,
          },
          "& .show-col": {
            width: ab({ _: windowSize.width / 2 + "px", md: "auto" }),
            height: "auto",
            display: "table-cell",
          },
          "& th.show-col": {
            display: ab({
              _: isSticky ? "flex" : "table-cell",
              md: "table-cell",
            }),
          },
        }}
      >
        <thead
          sx={{
            position: "sticky",
            insetBlockStart: ab({ _: "60px", md: "0" }),
            zIndex: "100",
            top: ab({ _: "60px", md: "0" }),
          }}
        >
          <tr
            sx={{
              color: "#fff",
              "& th": {
                px: ab({ _: 3, md: 1 }),
                py: ab({ _: 3, md: 4 }),
                pt: ab({ _: 3, md: 4 }),
                pr: ab({ _: 4, md: 0 }),
                backgroundColor: colors.modes.group.primary,
                verticalAlign: ab({ _: "middle", md: "bottom" }),
                position: "relative",
                hyphens: "auto",
                minWidth: "100px",
                textAlign: "center",
                fontSize: ab({ _: "14px", md: "14px" }),
                minHeight: ab({ _: "90px", md: "auto" }),
                display: ab({
                  _: isSticky ? "flex" : "table-cell",
                  md: "table-cell",
                }),
                justifyContent: "center",
                alignItems: "center",
                width: ab({
                  _: windowSize.width / 2 + "px !important",
                  md: "",
                  lg: "auto",
                }),
                " div > p": {
                  display: "inline",
                },
              },
            }}
          >
            <th className={mobileDisplay && isSticky ? "fixed" : ""}>
              <div>{tableHeaderProduct}</div>
            </th>
            <th className={mobileDisplay && isSticky ? "fixed" : ""}>
              <div>{tableHeaderArticleNo}</div>
            </th>
            <th
              className={mobileDisplay && isSticky ? "fixed" : ""}
              sx={{
                ".text": {
                  top: ab({ _: "45px", md: "25px" }),
                },
              }}
            >
              <div
                sx={{
                  cursor: "pointer",
                }}
                role="button"
                onClick={() => setPopupText4Expanded(!popupText4Expanded)}
              >
                {tableHeaderCharge}{" "}
                <Info text={popupText4} expand={popupText4Expanded} />
              </div>
            </th>
            <th className={mobileDisplay && isSticky ? "fixed" : ""}>
              <div
                sx={{
                  cursor: "pointer",
                }}
                role="button"
                onClick={() => {
                  setPopupText1Expanded(!popupText1Expanded)
                }}
              >
                <Markdown source={tableHeaderHeavymetal} />

                <Info text={popupText1} expand={popupText1Expanded} />
              </div>
            </th>
            <th className={mobileDisplay && isSticky ? "fixed" : ""}>
              <div
                sx={{
                  cursor: "pointer",
                }}
                role="button"
                onClick={() => {
                  setPopupText2Expanded(!popupText2Expanded)
                }}
              >
                <Markdown source={tableHeaderMicroOrganism} />
                <Info text={popupText2} expand={popupText2Expanded} />
              </div>
            </th>
            <th className={mobileDisplay && isSticky ? "fixed" : ""}>
              <div
                sx={{
                  cursor: "pointer",
                }}
                role="button"
                onClick={() => {
                  setPopupText3Expanded(!popupText3Expanded)
                }}
              >
                <Markdown source={tableHeaderPesticides} />
                <Info text={popupText3} expand={popupText3Expanded} />
              </div>
            </th>
            <th className={mobileDisplay && isSticky ? "fixed" : ""}>
              <div>{tableHeaderRemark}</div>
            </th>
            <th className={mobileDisplay && isSticky ? "fixed" : ""}>
              <div>{tableHeaderCertificate}</div>
            </th>
          </tr>
        </thead>
        <tbody
          id="table-data"
          sx={{
            display: tableIsBuilding ? "none" : "auto",
          }}
        ></tbody>
      </table>
      <Spinner hide={!tableIsBuilding} />

      <div
        sx={{
          fontSize: "12px",
          fontStyle: "italic",
          py: 2,
          span: {
            float: "right",
            px: 3,
          },
        }}
      >
        <span>
          {" "}
          {updateDateText} {updateDate}
        </span>{" "}
        <br />
        {explanatory}
      </div>
      {/* Pagination */}

      <div
        className="pagination"
        sx={{
          textAlign: "center",
          pt: 5,
          "& span": {
            variant: "styles.linkbtmbrd",
            display: "inline-block",
            px: 2,
            cursor: "pointer",
          },
          "& span.current": {
            "&:after": {
              content: "''",
              width: "100%",
              height: 2,
            },
          },
        }}
      >
        <div
          sx={{ display: ab({ _: "none", md: "inline" }) }}
          onClick={goToFirstPageButton}
          role="button"
          tabIndex={0}
        >
          <span>❮❮</span>
        </div>
        <div
          role="button"
          tabIndex={0}
          sx={{ display: ab({ _: "none", md: "inline" }) }}
          onClick={prevButton}
        >
          <span>❮</span>
        </div>
        <div sx={{ display: ab({ _: "block", md: "inline" }) }}>{paging}</div>
        <div
          role="button"
          tabIndex={0}
          onClick={nextButton}
          sx={{
            textAlign: "center",
            marginTop: 2,
            display: ab({ _: "none", md: "inline" }),
          }}
        >
          <span>❯</span>
        </div>
        <div
          role="button"
          tabIndex={0}
          onClick={goToLastPageButton}
          sx={{ display: ab({ _: "none", md: "inline" }) }}
        >
          <span>❯❯</span>
        </div>
      </div>
    </div>
  )
}

export default ProductTable
