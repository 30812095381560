/** @jsx jsx */
import { jsx } from "theme-ui"

const DepartmensIcon = ({ style = {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 199.98">
    <g data-name="Ebene 2">
      <path
        sx={style}
        d="M100 0A100 100 0 0 0 2.78 123.44a17.62 17.62 0 0 1 32.14 3.89l24.8-3.55a24.69 24.69 0 0 1 5.87-16l-28.76-30a10.43 10.43 0 1 1 3.85-3.48l28.63 30a24.7 24.7 0 0 1 19.74-4.74l10.11-29.64a14 14 0 0 1 3.64-25.28l-2.5-17.12a6.51 6.51 0 1 1 4.42-.64l2.52 17.25a14 14 0 0 1 12.21 19.4l32.4 21.37a7.89 7.89 0 1 1-2.93 4.89l-32.7-21.58a13.87 13.87 0 0 1-9.61 3.84h-.76l-10.15 29.7a24.75 24.75 0 0 1 9.17 36l22.71 20.82a12.62 12.62 0 1 1-4.51 5.34l-22.91-21a24.7 24.7 0 0 1-20.07 5.22l-5.33 20a5.64 5.64 0 1 1-3.72-1l5.33-20a24.81 24.81 0 0 1-15.64-16.43l-25.21 3.61A17.61 17.61 0 0 1 16 149.81a18.53 18.53 0 0 1-3-.62A100 100 0 1 0 100 0Z"
        data-name="Ebene 1"
      />
    </g>
  </svg>
)

export default DepartmensIcon
