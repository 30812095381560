/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import PropTypes from "prop-types"

import { ab } from "../../theme"

const InputTable = ({ addButtonText, fields, maxRows }) => {
  let blankInput = {}
  for (let i = 0; i < fields.length; i++) {
    blankInput[fields[i]["name"]] = ""
  }
  let inputTableField = []

  for (let i = 0; i < maxRows; i++) {
    inputTableField[i] = { ...blankInput }
  }

  const [visibleRows, setVisibleRows] = useState(2)

  const addRow = () => {
    if (visibleRows < maxRows) {
      setVisibleRows(visibleRows + 1)
    }
  }
  let labels = []
  for (let i = 0; i < fields.length; i++) {
    labels.push(
      <div
        key={i}
        sx={{
          display: ab({ _: "none", md: "block" }),
          flex: fields[i]["flex"],
        }}
      >
        {fields[i]["label"]}
      </div>
    )
  }

  return (
    <div className="input-table">
      <div
        sx={{
          display: ab({ md: "flex" }),
          justifyContent: "space-between",
          marginTop: 3,
          "& div": {
            marginRight: 2,
          },
        }}
      >
        {labels}
      </div>
      <div sx={{ "& > div:first-of-type": { borderTop: "none" } }}>
        {inputTableField.map((element, idx) => {
          let inputs = []
          for (let i = 0; i < fields.length; i++) {
            inputs.push(
              <div key={i} sx={{ flex: fields[i]["flex"] }}>
                <div sx={{ display: ab({ md: "none" }) }}>
                  {fields[i]["label"]}
                </div>
                <input
                  name={`${fields[i]["name"]}-${idx}`}
                  type="text"
                  placeholder={fields[i]["placeholder"]}
                  sx={{ width: "100%", variant: "form.input" }}
                />
                <input
                  type="hidden"
                  name={`label----${fields[i]["name"]}-${idx}`}
                  value={fields[i]["label"]}
                />
              </div>
            )
          }

          return (
            <div
              key={idx}
              sx={{
                display: ab({
                  _: idx <= visibleRows ? "block" : "none",
                  md: idx <= visibleRows ? "flex" : "none",
                }),
                justifyContent: "space-between",
                alignContent: "stretch",
                py: ab({ _: 3, md: 0 }),
                borderTop: ab({ _: "1px solid", md: "none" }),
                "& div": {
                  marginRight: ab({ md: 2 }),
                },
                "& div:last-of-type": {
                  marginRight: 0,
                },
                "& input": {
                  backgroundColor: "secondaryBackground",
                  py: 3,
                  textIndent: 15,
                  borderRadius: "20px",
                  border: 0,
                  marginRight: 2,
                  minWidth: 0,
                  my: 2,
                  "&:last-child": {
                    marginRight: 0,
                  },
                },
              }}
            >
              {inputs}
            </div>
          )
        })}
      </div>
      <div
        sx={{
          display: visibleRows < maxRows - 1 ? "block" : "none",
          textAlign: "right",
          cursor: "pointer",
          p: 3,
        }}
        role="button"
        tabIndex="0"
        onKeyPress={addRow}
        onClick={addRow}
      >
        <span>+ </span>
        {addButtonText}
      </div>
    </div>
  )
}

InputTable.propTypes = {
  fields: PropTypes.array.isRequired,
  addButtonText: PropTypes.string.isRequired,
  maxRows: PropTypes.number,
}

InputTable.defaultProps = {
  fields: [],
  maxRows: 20,
}

export default InputTable
