/** @jsx jsx */
import { jsx } from "theme-ui"

const HusHomeIcon = ({ style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 162.55 162.55"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
  >
    <g id="Ebene_1-2" data-name="Ebene 1">
      <g>
        <path
          sx={style}
          d="m90.38,160.8c0,.43.07.84.21,1.22-1.89.22-3.8.37-5.73.46-1.19.05-2.39.08-3.59.08-3.15,0-6.26-.18-9.32-.53.14-.38.21-.79.21-1.22v-31.33h18.21v31.33Z"
        />
        <path
          sx={style}
          d="m81.27,0C36.38,0,0,36.38,0,81.27c0,11.34,2.32,22.14,6.51,31.94,1.67,3.89,3.63,7.64,5.87,11.2,3.16,5.05,6.87,9.71,11.03,13.93,3.2,3.24,6.66,6.22,10.37,8.89v-38.04h-9.03c-2.69,0-5.05-1.62-6.02-4.12-.97-2.51-.32-5.3,1.67-7.11l56.52-51.5c2.48-2.26,6.22-2.26,8.7,0l56.52,51.5c1.99,1.81,2.65,4.6,1.68,7.11-.98,2.5-3.34,4.12-6.03,4.12h-9.03v38.04c7.95-5.73,14.82-12.87,20.25-21.04,2.71-4.08,5.06-8.43,7.02-12.98,4.19-9.8,6.51-20.6,6.51-31.94C162.55,36.38,126.16,0,81.27,0Zm50.23,78.15l-28.58-25.99h24.88c2.04,0,3.7,1.66,3.7,3.71v22.29Z"
        />
      </g>
    </g>
  </svg>
)

export default HusHomeIcon
