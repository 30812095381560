/** @jsx jsx */
import { jsx } from "theme-ui"
import { createContext } from "react"
import useModal from "../hooks/useModel"
import Modal from "../components/modal"

let ModalContext
let { Provider } = (ModalContext = createContext())

let ModalProvider = ({ children }) => {
  let { modal, handleModal, modalContent } = useModal()
  return (
    <Provider value={{ modal, handleModal, modalContent }}>
      <Modal />
      {children}
    </Provider>
  )
}
export { ModalContext, ModalProvider }
