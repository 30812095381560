/** @jsx jsx */
import { jsx } from "theme-ui"

const Gutesiegel = ({ style = {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 436 511.9">
    <path
      d="M320 157.9L188.6 289.3l-49.2-49.2 21.7-21.7 27.4 27.4 109.7-109.7 21.8 21.8zm-158.8 21.7l-60.5 60.5 87.9 87.9 170.2-170.2-60.5-60.5L188.6 207l-27.4-27.4z"
      fill="#004787"
    />
    <defs>
      <path id="a" d="M29.4 0h379.5v511.9H29.4z" />
    </defs>
    <path
      d="M387.8 173.5c0 104.3-69.2 197.8-168.7 228.5-99.4-30.7-168.6-124.2-168.6-228.5V65.1h29.8c49.3 0 97-13.8 138.8-40.1 41.6 26.3 89.5 40.1 138.9 40.1h29.8v108.4zM362.4 430l-32.3-8.1-23.9 39.8-22.1-66.4c20.9-12.9 39.8-28.6 56.1-46.5l22.2 81.2zm-230.3 31.7L108.2 422l-32.3 8L98 348.9c16.4 17.9 35.2 33.6 56.1 46.5l-22 66.3zM408.9 44H358c-47.4 0-93.2-13.9-133-40.4L218.8 0 213 3.9c-24 16-50.6 26.8-78.1 33.8-8.2 2.1-21.1 4-21.1 4-11 1.5-22.2 2.4-33.4 2.4h-51v129.5s1.1 23.6 2.2 31.5c6.4 45.5 23.2 88.3 50 124.2l-35.5 130 52.1-13 39.4 65.7L173 405.9c13.7 6.9 28.2 12.8 43.3 17.3l3 .9 3-.9c15.1-4.5 29.5-10.3 43.3-17.3l35.4 106 39.4-65.7 52.1 13-35.5-130c33-44.1 52.2-98.4 52.2-155.7V44z"
      fill="#004787"
    />
  </svg>
)

export default Gutesiegel
