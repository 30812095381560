/** @jsx jsx */
import { jsx } from "theme-ui"

const HusJobIcon = ({ style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 162.55 162.55"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
  >
    <g id="Ebene_1-2" data-name="Ebene 1">
      <g>
        <path
          sx={style}
          d="m61.9,72.01c-1.36,0-2.46-1.11-2.46-2.46v-5.97c0-3.11,2.53-5.64,5.64-5.64h32.39c3.11.01,5.62,2.54,5.62,5.64v5.98c0,1.36-1.11,2.46-2.46,2.46h-38.73Z"
        />
        <path
          sx={style}
          d="m38.56,150.43h85.41c-4.27,2.64-8.79,4.9-13.54,6.72-7.99,3.08-16.59,4.92-25.58,5.31-1.19.05-2.39.08-3.59.08-13.21,0-25.68-3.15-36.7-8.74-2.05-1.04-4.06-2.17-6.01-3.37Z"
        />
        <path
          sx={style}
          d="m162.55,81.27c0,11.34-2.32,22.14-6.51,31.94-1.95,4.55-4.3,8.89-7.02,12.98-2.11,3.19-4.45,6.22-6.99,9.06v-56.18c-.01-6.61-5.36-11.97-11.97-12h-17.38v-3.5c0-8.39-6.8-15.18-15.19-15.18h-32.39c-8.39,0-15.19,6.79-15.19,15.18v3.5h-17.39c-6.62,0-11.99,5.38-12,12v56.18c-3.01-3.37-5.73-7-8.14-10.85-2.24-3.56-4.2-7.3-5.87-11.2-4.19-9.8-6.51-20.6-6.51-31.94C0,36.38,36.38,0,81.27,0s81.27,36.38,81.27,81.27Z"
        />
      </g>
    </g>
  </svg>
)

export default HusJobIcon
