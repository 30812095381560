/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import { useState, useRef, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"

import ShopContext from "../context/shop-context"
import Input from "./form/input"
import { ab } from "../theme"

const PasswordReset = ({ heading, subHeading, buttonText, redirectUrl }) => {
  const { handlePasswordReset } = useContext(ShopContext)

  const [message, setMessage] = useState("")
  const [animate, setAnimate] = useState(true)
  const [isCorrect, setIsCorrect] = useState(false)

  const refForm = useRef()
  const { rawColors: colors } = useThemeUI().theme

  const handleSubmit = (evt) => {
    const form = refForm.current.elements
    const { password } = form

    evt.preventDefault()

    handlePasswordReset(
      code.value,
      email.value,
      password.value,
      (correct, msg) => {
        setMessage(msg)
        setIsCorrect(correct)
        setAnimate(true)
      }
    )
    setAnimate(false)
  }
  useEffect(() => {
    if (isCorrect) {
      setTimeout(() => {
        navigate(redirectUrl)
      }, 3000)
    }
  }, [isCorrect])

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "90vh",
        position: "relative",
        pt: "25%",
        justifyContent: "space-between",
        bg: ab({ _: colors.modes["group"].primary, md: "transparent" }),
      }}
    >
      <div
        sx={{
          bg: ab({ _: "", md: colors.modes["group"].primary }),
          width: ab({ _: "100%", md: "550px" }),
          px: 4,
          py: 5,
          alignSelf: "center",
        }}
      >
        <h6
          sx={{
            m: 0,
            p: 0,
            fontSize: "14px",
            letterSpacing: 2,
            fontWeight: 1,
            textTransform: "uppercase",
            color: "background",
          }}
        >
          {subHeading}
        </h6>
        <h2
          sx={{
            fontSize: 4,
            my: 1,
            color: "background",
          }}
        >
          {heading}
        </h2>
        <div
          sx={{
            color: "background",
            textAlign: "center",
            padding: 2,
            mt: 3,
            display: message ? "block" : "none",
            variant: message
              ? isCorrect
                ? "styles.success"
                : "styles.failed"
              : "",
            animationName: animate ? "swing" : "",
            animationDuration: "600ms",
          }}
        >
          {message}
        </div>
        <form
          name="login"
          ref={refForm}
          onSubmit={handleSubmit}
          sx={{
            pt: 3,
            "&>div": {
              pb: 3,
            },
          }}
        >
          <div>
            <Input
              type="text"
              placeholder="Verification Code"
              required={true}
              name="code"
              label=""
            />
          </div>
          <div>
            <Input
              type="email"
              placeholder="Email"
              required={true}
              name="email"
              label=""
            />
          </div>
          <div>
            <Input
              type="password"
              placeholder="new password"
              required={true}
              name="password"
              label=""
            />
          </div>

          <div
            sx={{
              pt: 3,
            }}
          >
            <button
              type="submit"
              sx={{
                variant: "styles.ghostButton",
                color: "#fff",
                border: "solid 1px #fff",
                py: 2,
                px: 4,
                "&:hover": {
                  bg: "#fff",
                  color: colors.modes["group"].primary,
                },
              }}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

PasswordReset.propTypes = {
  hash: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
}

PasswordReset.defaultProps = {
  redirectUrl: "/gba-shop/",
}

export default PasswordReset
