/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import { useState } from "react"
import PropTypes from "prop-types"

import Link from "../link"
import Item from "./item"

const LayerMobile = ({
  parent,
  parentUrl,
  items,
  extras,
  language,
  level,
  mode,
  location,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [subLayer, setSubLayer] = useState(null)

  const isSelected = (index) => selectedIndex === index

  const { rawColors: colors } = useThemeUI().theme

  return (
    <div
      className={`mobile-navigation-layer level-${level}`}
      sx={{
        pb: "0px",
        ".toggleIcon": {
          mr: 0,
        },

        ".level-4  li": {
          borderLeft: "solid 1px #fff",
          ".parent-link": {
            display: "none",
          },

          "&:first-child": {
            pt: 0,
            mt: "40px",
          },
          "&:last-child": {
            pb: "3px",
          },
        },

        "& ul": {
          mx: 0,
          px: 0,
        },
        a: {
          fontSize: "15px",
          flexGrow: level === 1 ? 1 : "0",
        },
        "& li": {
          pl: "35px",
          "& a": {
            color: "#fff",
            textDecoration: "none",
          },
        },
        fontSize: 1,
      }}
    >
      <div
        sx={{
          overflow: "auto",
        }}
      >
        <ul
          sx={{
            margin: 0,
            padding: 0,
          }}
        >
          {level > 1 && (
            <li
              className="parent-link"
              sx={{
                pl: "1em",
                fontWeight: "bold",
              }}
            >
              <span
                sx={{
                  display: "inline-block",
                }}
              >
                <Link
                  to={parentUrl}
                  language={language}
                  activeStyle={{
                    fontWeight: "bold",
                  }}
                  activeClassName="current"
                  sx={{
                    variant: "navigation.hover",
                  }}
                >
                  {parent}
                </Link>
              </span>
            </li>
          )}
          {items &&
            items.length > 0 &&
            items.map((item, index) => (
              <li
                key={`layer-item-${index}`}
                sx={{
                  pl: "1em",
                  backgroundColor: isSelected(index)
                    ? colors.navigation[mode]
                    : "transparent",
                  "&:hover": {
                    backgroundColor: colors.navigation[mode],
                  },
                }}
                onClick={() => setSelectedIndex(index)}
              >
                <Item
                  item={item}
                  level={level + 1}
                  language={language}
                  mode={mode}
                  selected={isSelected(index)}
                  hasChild={!!(item.children && item.children.length > 0)}
                  location={location}
                  setSelected={() => setSelectedIndex(index)}
                  setSubLayer={setSubLayer}
                />
              </li>
            ))}
        </ul>
        {subLayer}
        {extras && extras.length > 0 && (
          <ul
            className="extra-navigation"
            sx={{
              mt: "2rem",
              pt: "2rem",
              "::before": {
                content: "''",
                position: "relative",
                left: "35px",
                zIndex: -1,
                transform: "translateY(-1.5rem)",
                height: "2px",
                width: "80px",
                bg: "#fff",
                display: "block",
              },
            }}
          >
            {extras.map((item, index) => (
              <li key={`layer-extra-item-${index}`}>
                <span
                  sx={{
                    display: "inline-block",
                  }}
                >
                  <Item
                    item={item}
                    level={level + 1}
                    language={language}
                    mode={mode}
                    selected={isSelected(index)}
                    hasChild={false}
                    location={location}
                    setSelected={() => setSelectedIndex(index)}
                    setSubLayer={setSubLayer}
                  />
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

LayerMobile.propTypes = {
  parent: PropTypes.string,
  parentUrl: PropTypes.string,
  items: PropTypes.array,
  extras: PropTypes.array,
  language: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  mode: PropTypes.oneOf([
    "group",
    "pharma",
    "food",
    "environment",
    "career",
    "sustainabilityServices",
  ]).isRequired,
  location: PropTypes.object.isRequired,
  closeSubNavi: PropTypes.func,
}

LayerMobile.defaultProps = {
  closeSubNavi: () => {},
}

export default LayerMobile
