/** @jsx jsx */
import { jsx } from "theme-ui"

const EnvironmentIcon = ({ style = {} }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 500 500"
  >
    <g>
      <path
        sx={style}
        d="M182.1,187.3c-0.7,0-1.3,0-1.9,0C180.8,187.2,181.4,187.2,182.1,187.3z"
      />
      <path
        sx={style}
        d="M225.4,241.9c2.3-2.9,5.6-4.3,8.9-4.2C231,237.6,227.7,239,225.4,241.9l-22,29.8c-12.2-54.7-12.9-68.9-12.9-71
   c0,2.2,0.7,16.3,12.9,71L225.4,241.9z"
      />
      <path
        sx={style}
        d="M175.6,188.9c0.5-0.3,1.1-0.6,1.7-0.9C176.7,188.2,176.2,188.5,175.6,188.9z"
      />
      <path
        sx={style}
        d="M190.5,198.3L190.5,198.3c0-3.8,0.1-7.7-4.6-10c-0.3-0.2-0.6-0.3-0.9-0.4c0.3,0.1,0.6,0.3,0.9,0.4
   C190.5,190.6,190.5,194.5,190.5,198.3z"
      />

      <polygon
        sx={style}
        points="239.8,239.5 239.8,239.5 239.8,239.5 239.8,239.5 	"
      />
      <path
        sx={style}
        d="M229.5,389.7c6.7,11.4,14.8,23.4,23.2,35.9c1,1.6,2.1,3.2,3.2,4.8c-4.4,1-8.9,1.7-13.5,2.1
   c4.6-0.4,9.1-1.1,13.6-2.1c-1.1-1.6-2.1-3.2-3.2-4.8C244.3,413,236.2,401.1,229.5,389.7z"
      />
      <path
        sx={style}
        d="M174.4,351.6l41.3,13l0,0L174.4,351.6c-5.4-1.4-10.9,1.6-12.4,6.7c-0.3,0.9-0.4,1.8-0.4,2.6
   c0-0.9,0.1-1.8,0.4-2.6C163.5,353.2,169,350.2,174.4,351.6z"
      />
      <path
        sx={style}
        d="M182.1,187.3c0.7,0,1.3,0.2,1.9,0.3C183.4,187.4,182.7,187.3,182.1,187.3z"
      />
      <path
        sx={style}
        d="M172,193.1L172,193.1c0,0-0.9,2-0.9,8.6C171.1,195.1,172,193.1,172,193.1L172,193.1z"
      />
      <path
        sx={style}
        d="M209.7,296.8c0-0.1-0.1-0.2-0.1-0.3l0,0C209.6,296.6,209.7,296.7,209.7,296.8z"
      />
      <path
        sx={style}
        d="M245,377C245,377,245,377,245,377C245,377,245,377,245,377l-0.5-1c0,0,0,0,0,0c0,0,0,0,0,0L245,377z"
      />
      <path
        sx={style}
        d="M347.3,108.7c-7.6-9.3-27.6-35-32.5-41.2c-0.3-0.4-0.7-0.5-1.1-0.5c0.4,0,0.8,0.2,1.1,0.5
   C319.7,73.8,339.8,99.5,347.3,108.7c9,11.3,17.5,23.7,17.5,39.3c0,0,0,0,0-0.1C364.8,132.2,356.3,119.9,347.3,108.7z"
      />
      <path
        sx={style}
        d="M287,100.3c9.3-11.8,22-28,25.7-32.8C309,72.2,296.3,88.4,287,100.3z"
      />
      <path
        sx={style}
        d="M241.8,253.1c1.7-2.1,2.4-4.6,2-7.2c0,0,0,0,0,0c0,0,0,0,0,0C244.1,248.5,243.4,251,241.8,253.1z"
      />
      <path
        sx={style}
        d="M310.2,199.8c-20.3-1.4-37.6-16.1-44.5-35.6C272.6,183.8,289.9,198.4,310.2,199.8c1.9,0.1,3.7,0.2,5.5,0.1
   C313.9,200,312.1,199.9,310.2,199.8z"
      />
      <path
        sx={style}
        d="M250.5,0.6C112.6,0.6,0.8,112.4,0.8,250.3S112.6,500,250.5,500c15.1,0,29.9-1.4,44.3-4
   c-10.1-22.5-24.6-44.6-38.8-65.6h0l0,0c-4.5,1-9,1.7-13.6,2.1c-19.3,1.9-39.2-1.1-57.7-8.3c-19.5-7.7-36.9-19.7-50.6-34.8
   c-15-16.4-25.4-36-30.5-57c-13.2-54,5.2-126,84.6-205.5c1.1-1.3,2.8-2,4.6-2c0,0,0,0,0.1,0c0,0,0,0,0,0c3.5,0,6.4,2.6,6.5,5.9
   c0.1,0,0.8,65.9,84.3,116.6c10.9,6.6,20.7,14.7,29,24.1c14.9,16.7,23.3,37.6,24,59.4c0.6,20.4-5.4,40.4-17.3,57.5
   c-5.9,8.5-13.2,16.2-21.5,22.6c-6.9,5.3-14.2,9.4-21.8,12.7c15.3,21.8,30.6,44,40.9,67.3c105.6-29.1,183.2-125.8,183.2-240.7
   C500.2,112.4,388.4,0.6,250.5,0.6z M364.8,148c0,27.1-20.4,49.3-46.4,51.7c-0.9,0.1-1.8,0.1-2.7,0.2c-1.8,0.1-3.7,0-5.5-0.1
   c-20.3-1.4-37.6-16-44.5-35.6c-8.5-24,2.8-40.6,14.7-55.6c1.8-2.2,4.1-5.1,6.6-8.3c9.4-11.9,22-28,25.7-32.8c0,0,0,0,0,0
   c0.3-0.4,0.6-0.5,1-0.5c0.4,0,0.8,0.2,1.1,0.5c4.9,6.2,24.9,31.9,32.5,41.2C356.3,119.9,364.8,132.2,364.8,148
   C364.8,147.9,364.8,147.9,364.8,148C364.8,148,364.8,148,364.8,148z"
      />
      <path
        sx={style}
        d="M318.4,199.7c26-2.4,46.4-24.6,46.4-51.7c0,0,0,0,0,0C364.8,175.1,344.4,197.3,318.4,199.7z"
      />
      <path
        sx={style}
        d="M313.7,67c-0.4,0-0.8,0.2-1,0.5c0,0,0,0,0,0c0,0,0,0,0,0C312.9,67.2,313.3,67,313.7,67z"
      />
      <path
        sx={style}
        d="M280.4,108.6c1.8-2.3,4.1-5.1,6.6-8.3C284.5,103.5,282.2,106.4,280.4,108.6z"
      />
      <path
        sx={style}
        d="M188.2,126.9c-79.4,79.5-97.8,151.5-84.6,205.5c5.1,21,15.5,40.6,30.5,57c13.7,15.1,31.1,27.1,50.6,34.8
   c18.6,7.3,38.4,10.2,57.7,8.3c-19.3,1.9-39-1.1-57.6-8.3c-19.5-7.7-36.9-19.7-50.6-34.8c-14.9-16.4-25.4-35.9-30.5-57
   c-13.3-54,5.2-126,84.6-205.5c1.2-1.2,2.9-2,4.6-2c0,0,0,0-0.1,0C191,124.9,189.3,125.6,188.2,126.9z"
      />
      <path
        sx={style}
        d="M256,430.4c14.2,21,28.7,43.1,38.8,65.6C284.7,473.4,270.2,451.3,256,430.4L256,430.4z"
      />
      <path
        sx={style}
        d="M245,377l-0.5-1c0,0,0,0,0,0c-14.6-25-26.3-51.7-34.8-79.2c0-0.1-0.1-0.2-0.1-0.3l0,0v0l32.2-43.4c0,0,0,0,0,0
   c1.6-2.1,2.3-4.6,2-7.2c0,0,0,0,0,0c-0.4-2.6-1.8-4.8-4-6.4c0,0,0,0,0,0c0,0,0,0,0,0c-1.7-1.2-3.6-1.8-5.5-1.8
   c-3.2-0.1-6.5,1.4-8.9,4.2l-22,29.8c-12.2-54.7-12.9-68.8-12.9-71v-2.4c0-3.8,0-7.7-4.6-10c-0.3-0.2-0.6-0.3-0.9-0.4
   c-0.3-0.1-0.6-0.2-1-0.3c-0.6-0.2-1.3-0.3-1.9-0.3c-0.7,0-1.3,0-1.9,0c-1,0.1-1.9,0.3-2.8,0.7c-0.6,0.2-1.2,0.5-1.7,0.9
   c-1.4,0.9-2.5,2-3.3,3.5c-0.2,0.4-0.3,0.7-0.3,0.7v0c0,0-0.9,2-0.9,8.6c0,11.8,2.7,38.3,17.8,94.9l0.3,1c6.7,23,15.6,45.5,26.5,67
   h0l0,0l-41.3-13c-5.4-1.4-10.9,1.6-12.4,6.7c-0.3,0.9-0.4,1.8-0.4,2.6c0,3.8,2.4,7.3,6.4,9l61.1,19.1c0.1,0.2,0.3,0.4,0.4,0.7
   c6.7,11.4,14.8,23.4,23.3,35.9c1.1,1.6,2.1,3.2,3.2,4.8h0c6.8-1.6,13.5-3.9,20-6.7c-0.7-1-1.3-2-2-2.9c0,0,0,0,0,0
   C263.4,405.7,253.3,391.5,245,377C245,377,245,377,245,377z"
      />
      <polygon points="256,430.4 256,430.4 256,430.4 256,430.4 	" />
      <path d="M172,193.1c0,0,0.1-0.3,0.3-0.7C172.1,192.7,172,193,172,193.1z" />
      <path d="M234.3,237.7c1.9,0.1,3.9,0.7,5.5,1.8C238.1,238.3,236.2,237.7,234.3,237.7z" />
      <path d="M209.7,296.8c8.5,27.5,20.2,54.2,34.8,79.2C229.8,350.9,218.2,324.3,209.7,296.8z" />
      <path d="M274,420.8c-10.6-15.1-20.6-29.4-29-43.8C253.3,391.5,263.4,405.7,274,420.8z" />
      <polygon points="209.6,296.5 209.6,296.5 241.8,253.1 241.8,253.1 241.8,253.1 	" />
      <path
        d="M168,369.9c-4-1.6-6.4-5.2-6.4-9C161.6,364.7,164,368.3,168,369.9l61.1,19.1c0.1,0.2,0.3,0.4,0.4,0.7
   c-0.1-0.2-0.3-0.4-0.4-0.7L168,369.9z"
      />
      <path d="M243.8,245.9c-0.4-2.6-1.8-4.9-4-6.4C242,241.1,243.4,243.3,243.8,245.9z" />
      <path
        d="M189.2,297.6l-0.3-1c-15.1-56.5-17.8-83.1-17.8-94.9c0,11.8,2.7,38.3,17.8,94.9L189.2,297.6
   c6.7,23,15.6,45.5,26.5,67h0C204.8,343.1,195.9,320.6,189.2,297.6z"
      />
      <path d="M256,430.4c6.9-1.5,13.5-3.8,20-6.7l0,0C269.5,426.5,262.8,428.8,256,430.4L256,430.4z" />
      <polygon points="256,430.4 256,430.4 256,430.4 	" />
    </g>
  </svg>
)

export default EnvironmentIcon
