import { useStaticQuery, graphql } from "gatsby"

export const useLinkData = (to, language) => {
  const { allStoryblokEntry, allStoryblokLink, allStoryblokTag } =
    useStaticQuery(graphql`
      query SeoLinkQuery {
        allStoryblokEntry {
          nodes {
            full_slug
            internalId
            field_seoURL_string
            lang
            uuid
          }
        }
        allStoryblokLink {
          nodes {
            real_path
            slug
            uuid
            alternates {
              lang
              path
            }
          }
        }
        allStoryblokTag {
          nodes {
            name
            taggings_count
          }
        }
      }
    `)
  let newSeoLinks = []
  const entries = allStoryblokEntry.nodes
  const links = allStoryblokLink.nodes
  const tags = allStoryblokTag.nodes
  const defaultLang = process.env.GATSBY_DEFAULT_LANG
    ? process.env.GATSBY_DEFAULT_LANG
    : "de"

  if (!entries || !links || !to || !language) {
    return newSeoLinks
  }

  language = language === defaultLang ? "default" : language
  links
    .filter((link) => {
      return (
        to.includes(link.slug) ||
        link.slug.includes(to) ||
        to.includes(link.uuid) ||
        link.uuid.includes(to)
      )
    })
    .forEach((linkEntry, index) => {
      let seoLinkElement = entries.find(
        (link) => linkEntry.uuid === link.uuid && link.lang === language
      )
      let link = linkEntry.slug === "home" ? "/" : linkEntry.slug
      if (seoLinkElement) {
        link = seoLinkElement.field_seoURL_string
          ? seoLinkElement.field_seoURL_string
          : link
      }
      newSeoLinks[linkEntry.slug] = link
      newSeoLinks[linkEntry.uuid] = link

      if (language !== "default" && linkEntry.alternates !== null) {
        let altLink = linkEntry.alternates.find(
          (linkElement) => linkElement.lang === language
        )
        if (typeof altLink !== "undefined") {
          seoLinkElement = entries.find(
            (link) => linkEntry.uuid === link.uuid && link.lang === altLink.lang
          )

          link =
            altLink.path === "home"
              ? altLink.lang + "/"
              : altLink.lang + "/" + altLink.path
          if (seoLinkElement) {
            link = seoLinkElement.field_seoURL_string
              ? seoLinkElement.field_seoURL_string
              : link
          }
          if (!link.startsWith(altLink.lang)) {
            link = altLink.lang + "/" + link
          }
          newSeoLinks[altLink.lang + "/" + linkEntry.slug] = link
          newSeoLinks[altLink.lang + "/" + linkEntry.uuid] = link
        }
        newSeoLinks[linkEntry.slug] = link
        newSeoLinks[linkEntry.uuid] = link
      }
    })

  tags
    .filter((tag) => {
      return to.includes(tag.name) || tag.name.includes(to)
    })
    .forEach((tag, i) => {
      if (language === "default") {
        newSeoLinks[tag.name] = "tags/" + tag.name.toLowerCase()
        newSeoLinks["tags/" + tag.name] = "tags/" + tag.name.toLowerCase()
      } else {
        newSeoLinks[tag.name] = language + "/tags/" + tag.name.toLowerCase()
        newSeoLinks[language + "/tags/" + tag.name] =
          language + "/tags/" + tag.name.toLowerCase()
        newSeoLinks["tags/" + tag.name] =
          language + "/tags/" + tag.name.toLowerCase()
      }
    })

  return newSeoLinks
}
