/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { SitesProvider } from "../../context/site-context"
import SitesParent from "./sites-parent"
import Markdown from "react-markdown"

const SiteWrapper = ({
  sites,
  children,
  currentLanguage,
  infoText,
  centerLng,
  ceneterLat,
  zoomLevel,
  apiKey,
  thirdPartyInfo,
  mapSearchRadius,
}) => {
  return (
    <SitesProvider>
      <div
        sx={{
          pt: "40px",
          px: 4,
          h3: {
            fontSize: "24px",
            fontWeight: "normal",
          },
        }}
        className="sites-wrapper"
      >
        <div
          sx={{
            "h2,h3,h4,p": {
              pt: 0,
              mt: 0,
              mb: 2,
            },
          }}
        >
          {infoText}
        </div>

        <Markdown
          urlTransform={(uri) => {
            return transformLinkUri(uri, "", blok.currentLanguage)
          }}
        >
          {infoText}
        </Markdown>
        <SitesParent
          childern={children}
          sites={sites}
          currentLanguage={currentLanguage}
          centerLng={centerLng}
          ceneterLat={ceneterLat}
          zoomLevel={zoomLevel}
          apiKey={apiKey}
          thirdPartyInfo={thirdPartyInfo}
          mapSearchRadius={mapSearchRadius}
        >
          {children}
        </SitesParent>
      </div>
    </SitesProvider>
  )
}

SiteWrapper.propTypes = {
  children: PropTypes.node,
}

export default SiteWrapper
