/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { ab } from "../../theme"
import {
  Children,
  useState,
  cloneElement,
  useEffect,
  useRef,
  useContext,
} from "react"
import { FormName } from "./formContext"
import FieldGroupCheckbox from "./field-group-checkbox"

const FieldGroup = ({
  children,
  inputs,
  heading,
  displayAsCheckbox,
  checkboxName,
  checkboxValue,
  keepFlexOnMobile,
  visible,
}) => {
  const [toggle, setToggle] = useState(!heading)
  const [isFilled, setIsFilled] = useState(false)
  const groupEle = useRef(null)
  const formName = useContext(FormName)
  const [form] = useState(
    typeof document !== "undefined" && document.getElementsByName
      ? document.getElementsByName(formName)
      : null
  )

  useEffect(() => {
    let currentDiv = groupEle.current
    let inputs = currentDiv.querySelectorAll("input[type=text]")
    setInterval(() => {
      for (let i = 0; i < inputs.length; i++) {
        if (!inputs[i].name.startsWith("label----") && inputs[i].value !== "") {
          setIsFilled(true)
          break
        }
      }
    }, 5000)
  }, [form])

  let headingElement = ""
  if (heading) {
    if (!displayAsCheckbox) {
      headingElement = (
        <h4
          sx={{
            borderColor: "text",
            py: 2,
            m: 0,
            marginTop: 3,
            fontSize: 1,
            paddingLeft: 20,
            cursor: "pointer",
            position: "relative",
          }}
          role="button"
          tabIndex="0"
          onKeyPress={() => setToggle(!toggle)}
          onClick={() => setToggle(!toggle)}
        >
          <span
            className="form-arrow"
            sx={{
              variant: "form.arrow",
              transform: toggle ? "rotate(225deg)" : null,
              left: 0,
              width: "8px",
              height: "8px",
              marginTop: toggle ? "1px" : "3px",
              borderWidth: "1px",
            }}
          ></span>
          {heading}
        </h4>
      )
    } else {
      headingElement = (
        <div
          sx={{
            borderColor: "text",
            m: 0,
            my: 2,
            fontSize: 1,
            cursor: "pointer",
          }}
          role="button"
          tabIndex="0"
          onKeyPress={() => setToggle(!toggle)}
          onClick={() => setToggle(!toggle)}
        >
          <FieldGroupCheckbox
            label={heading}
            name={checkboxName}
            value={checkboxValue}
            checked={toggle}
          />
        </div>
      )
    }
  }

  let elements = Children.toArray(children)
  let newElements = []
  if (elements.length === 1) {
    newElements = cloneElement(elements[0], { visible: toggle && visible })
  } else if (elements.length > 0) {
    for (let i = 0; i < elements.length; i++) {
      newElements.push(
        cloneElement(elements[i], { visible: toggle && visible })
      )
    }
  }

  return (
    <div
      ref={groupEle}
      sx={{
        borderBottom: displayAsCheckbox ? "1px solid" : "0",
        lineHeight: 1,
        flex: 1,
      }}
    >
      {heading && (
        <input
          type="hidden"
          name={"toggle----" + heading}
          value={isFilled ? checkboxName || heading : ""}
        />
      )}
      {headingElement}
      <div
        sx={{
          display: toggle
            ? inputs
              ? ab({ _: keepFlexOnMobile ? "flex" : "block", md: "flex" })
              : "block"
            : "none",
          flexWrap: inputs ? "wrap" : "nowrap",
          pb: displayAsCheckbox ? 4 : "0",
          "& > div": {
            borderRadius: "20px",
            minWidth: 0,
            marginRight: inputs
              ? ab({ _: keepFlexOnMobile ? "24px" : "0", md: "24px" })
              : 0,
            "&:last-child": {
              border: "0 none",
              marginRight: 0,
            },
          },
        }}
      >
        {newElements}
      </div>
    </div>
  )
}

FieldGroup.defaultProps = {
  visible: true,
}

FieldGroup.propTypes = {
  children: PropTypes.node,
  displayAsCheckbox: PropTypes.bool,
  checkboxName: PropTypes.string,
  checkboxValue: PropTypes.string,
  keepFlexOnMobile: PropTypes.bool,
  inputs: PropTypes.bool,
  heading: PropTypes.string,
  flex: PropTypes.string,
  mdFlex: PropTypes.string,
  visible: PropTypes.bool,
}

export default FieldGroup
