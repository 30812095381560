/** @jsx jsx */
import { jsx } from "theme-ui"

const DownloadIcon = ({ style = {} }) => (
  <svg
    version="1.1"
    id="Capa_1"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style={{ fill: "#004787" }}
  >
    <g id="Ebene_2" data-name="Ebene 2">
      <g id="Ebene_1-2" data-name="Ebene 1">
        <rect x="119.14" width="76.56" height="219.09" rx="10" />
        <path d="M.59 180.22l-.59.53L149.48 376a10 10 0 0015.87 0l149.48-195.25-.59-.53z" />
        <path d="M268.73 358.65v76.59a10 10 0 01-10 10H56.1a10 10 0 01-10-10v-76.59a10 10 0 00-10-10H10.17a10 10 0 00-10 10V480a10 10 0 0010 10h294.5a10 10 0 0010-10V358.65a10 10 0 00-10-10h-25.95a10 10 0 00-9.99 10z" />
      </g>
    </g>
  </svg>
)

export default DownloadIcon
