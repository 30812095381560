/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"

const Steps = ({ steps, children, nextBtnText, prevBtnText }) => {
  const [stepIndex, setStepIndex] = useState(0)
  const [child, setChild] = useState(children)
  useEffect(() => {
    setChild(children)
  }, [children])

  if (!steps) {
    steps = []
  }
  const totalSteps = child ? child.length : 0
  const stepElement = useRef(null)
  const currentStepElement = useRef(null)

  const addActiveClass = (i) => {
    const getId = stepElement.current.getElementsByClassName("step-" + i)[0]
    getId.parentElement
      .querySelectorAll(".active")
      .forEach((e) => e.classList.remove("active"))
    getId.classList.add("active")
  }
  const nextStep = () => {
    if (stepIndex < totalSteps && validateSubForm(stepIndex)) {
      let i = stepIndex + 1
      setStepIndex(i)
      addActiveClass(i)
    } else {
      return
    }
  }
  const prevStep = () => {
    if (stepIndex < 1) {
      return
    } else {
      let i = stepIndex - 1
      setStepIndex(i)
      addActiveClass(i)
    }
  }

  const validateSubForm = (currentStep) => {
    let valid = true
    let inputs = currentStepElement.current.querySelectorAll("input")
    let selects = currentStepElement.current.querySelectorAll("select")
    let textareas = currentStepElement.current.querySelectorAll("textarea")
    for (let i = 0; i < inputs.length; i++) {
      valid = inputs[i].checkValidity() && valid
    }
    for (let i = 0; i < textareas.length; i++) {
      valid = textareas[i].checkValidity() && valid
    }
    for (let i = 0; i < selects.length; i++) {
      valid = selects[i].checkValidity() && valid
    }
    return valid
  }
  const getStep = (k) => {
    let steps = []
    for (var i = 0; i < child.length; i++) {
      let step
      if (i === k) {
        step = (
          <div ref={currentStepElement} key={i} sx={{ display: "block" }}>
            {child[i]}
          </div>
        )
      } else {
        step = (
          <div key={i} sx={{ display: "none" }}>
            {child[i]}
          </div>
        )
      }
      steps.push(step)
    }
    return steps
  }

  return (
    <div
      sx={{
        py: 3,
        my: 3,
      }}
    >
      <ol
        className="form-steps"
        ref={stepElement}
        sx={{
          display: "inline-grid",
          gridTemplateColumns: `repeat(${steps.length}, calc(100% / ${steps.length}))`,
          m: 0,
          p: 0,
          textAlign: "center",
          listStyle: "none",
          color: "#ccc",
          width: "100%",
          "& li": {
            border: "solid 1px",
            flexGrow: 1,
            m: 0,
            py: 3,
            px: 2,
            alignSelf: "center",
            // cursor: "pointer",
            height: "100%",
            overflowWrap: "break-word",
            wordWrap: "break-word",
            hyphens: "auto",
          },
          "& li:first-of-type": {
            borderRadius: "20px 0 0 20px",
          },
          "& li:last-of-type": {
            borderRadius: "0 20px 20px 0",
          },
          "& .active": {
            color: "text",
          },
        }}
      >
        {steps.map((step, idx) => (
          <li
            className={"step-" + idx + " " + (idx === 0 ? "active" : "")}
            // onClick={e => {
            //   setStepIndex(idx)
            //   addActiveClass(idx)
            // }}
            key={idx}
          >
            {idx + 1 + ". " + step.name}
          </li>
        ))}
      </ol>
      {getStep(stepIndex)}
      <div
        sx={{
          display: "flex",
          marginTop: 4,
          "& div": {
            lineHeight: "18px",
          },
        }}
      >
        <div
          role="button"
          tabIndex="0"
          onKeyPress={prevStep}
          onClick={prevStep}
          sx={{
            display: stepIndex === 0 ? "none" : "block",
            cursor: "pointer",
            textDecoration: "underline",
            lineHeight: "18px",
          }}
        >
          {prevBtnText}
        </div>
        <div
          sx={{
            textAlign: "center",
            flexGrow: 1,
          }}
        >
          {stepIndex + 1}/{totalSteps}
        </div>
        <div
          role="button"
          tabIndex="0"
          onKeyPress={nextStep}
          onClick={nextStep}
          sx={{
            display: stepIndex + 1 === totalSteps ? "none" : "block",
            textAlign: "right",
            cursor: "pointer",
            textDecoration: "underline",
            lineHeight: "18px",
          }}
        >
          {nextBtnText}
        </div>
      </div>
    </div>
  )
}

Steps.propTypes = {
  steps: PropTypes.array.isRequired,
}
export default Steps
