/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

import { render } from "storyblok-rich-text-react-renderer"

import Image from "./image"

import { ab } from "../theme"

const Sdgs = ({ logo, info, items }) => {
  const [sdgIndex, setSdgIndex] = useState(0)
  const [lineLength, setLineLength] = useState(0)
  const activeSdg = useRef(null)

  const computeLineLength = (sdg) => {
    if (!sdg) {
      return
    }

    return sdgIndex * sdg.offsetWidth + sdg.offsetWidth / 2
  }

  useEffect(() => {
    setLineLength(computeLineLength(activeSdg.current))
  }, [sdgIndex])

  const renderToolbar = () => {
    return (
      <ul
        sx={{
          display: "flex",
          listStyle: "none",
          flexWrap: ab({ _: "wrap", sm: "nowrap" }),
          justifyContent: ab({ _: "space-between", sm: "flex-start" }),
          position: "relative",
          px: ab({ _: 3, md: 5 }),
          pt: 5,
          mt: 0,
        }}
      >
        {items.map((item, index) => (
          <li
            key={`toolbar-${index}`}
            ref={sdgIndex === index ? activeSdg : null}
            sx={{
              transform: ab({
                sm: sdgIndex === index ? "scale(1.2)" : "scale(1.0)",
              }),
              "&:first-child button": {
                ml: 0,
              },
              "&:last-child button": {
                mr: 0,
              },
            }}
          >
            <button
              onClick={(e) => {
                setSdgIndex(index)
              }}
              sx={{
                all: "unset",
                display: "inline-block",
                px: ab({ _: "4px", sm: "8px", md: "12px" }),
              }}
            >
              <div
                sx={{
                  background: "#fff",
                  py: ab({ _: sdgIndex === index ? "2px" : 0, sm: 0 }),
                  minWidth: "70px",
                  maxWidth: "110px",
                }}
              >
                <Image
                  image={item.image.filename}
                  alt={item.image.alt}
                  sizes={["20vw", "20vw", "20vw", "20vw"]}
                  style={{
                    display: "block",
                    width: ab({
                      _: sdgIndex === index ? "calc(100% - 4px)" : "100%",
                      sm: "100%",
                    }),
                    marginLeft: ab({
                      _: sdgIndex === index ? "2px" : "0",
                      sm: "0",
                    }),
                    marginRight: ab({
                      _: sdgIndex === index ? "-2px" : "0",
                      sm: "0",
                    }),
                  }}
                />
              </div>
            </button>
          </li>
        ))}
      </ul>
    )
  }

  const renderInfoBlock = (richText) => render(richText)

  return (
    <section
      className="sdgs-container"
      sx={{
        backgroundImage: `url(${items[sdgIndex].backgroundImage.filename}/m/)`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        my: 5,
      }}
    >
      <div
        className="top"
        sx={{
          color: "#fff",
          backgroundColor: "rgba(40, 121, 134, .8)",
          display: ab({ md: "flex" }),
          justifyContent: "space-between",
          height: "35%",
          position: "relative",
          "& h2": {
            fontWeight: "bold",
            pt: 0,
            mt: 0,
          },
          "&::after": {
            content: "''",
            display: "block",
            position: "absolute",
            left: 0,
            right: 0,
            bottom: "-19px",
            backgroundImage:
              "url(https://a.storyblok.com/f/69940/2722x53/5dfbe721e3/divider.png/m/)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "40px",
            backgroundSize: "contain",
          },
        }}
      >
        <div
          className="logo"
          sx={{
            flex: 1,
            py: 5,
            px: ab({ _: 4, md: 5 }),
          }}
        >
          <Image
            image={logo.filename}
            alt={logo.alt}
            sizes={["100vw", "100vw", "50vw", "50vw"]}
            style={{
              width: "100%",
              maxWidth: ab({ md: "310px" }),
            }}
          />
        </div>
        <div
          className="info"
          sx={{
            flex: 1,
            py: ab({ _: 4, md: 5 }),
            px: ab({ _: 4, md: 5 }),
            "& h2": {
              fontSize: 4,
            },
          }}
        >
          {render(info)}
        </div>
      </div>
      <div
        className="bottom"
        sx={{
          backgroundColor: "rgba(2, 71, 135, .8)",
          height: "65%",
        }}
      >
        <div className="toolbar">{renderToolbar()}</div>
        <div
          className="lines"
          sx={{
            display: ab({ _: "none", sm: "block" }),
            px: ab({ _: 3, md: 5 }),
          }}
        >
          <svg
            sx={{
              width: "100%",
              height: "60px",
            }}
          >
            <line
              x1={lineLength}
              y1="0"
              x2={lineLength}
              y2="30"
              stroke="white"
              strokeWidth="1"
            ></line>
            <line
              x1="20"
              y1="30"
              x2={lineLength}
              y2="30"
              stroke="white"
              strokeWidth="1"
            ></line>
            <line
              x1="20"
              y1="30"
              x2="20"
              y2="60"
              stroke="white"
              strokeWidth="1"
            ></line>
          </svg>
        </div>
        <div
          className="info-block"
          sx={{
            color: "#fff",
            px: ab({ _: 4, md: 5 }),
            pb: 5,
            "& h3": {
              fontSize: 5,
              m: 0,
              textTransform: "uppercase",
            },
          }}
        >
          {renderInfoBlock(items[sdgIndex].text)}
        </div>
      </div>
    </section>
  )
}

Sdgs.propTypes = {
  logo: PropTypes.object.isRequired,
  info: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
}

export default Sdgs
