/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Link from "./link"
import { ab } from "../theme"

const SmallBlogTeasers = ({
  heading,
  buttonLink,
  buttonText,
  linkTarget,
  children,
  currentLanguage,
  hideEvents,
}) => (
  <div
    className="small-blog-teasers"
    sx={{
      variant: "alternatingSpacingContainer",
      backgroundColor: "secondaryBackground",
      mb: "64px",
      display: hideEvents ? "none" : "block",
    }}
  >
    <div>
      <h2
        sx={{
          fontWeight: 1,
          m: 0,
          py: ab({ _: 4, md: 0 }),
          pl: ab({ _: 3, md: 4 }),

          color: "text",
        }}
      >
        {heading}
      </h2>
    </div>
    <div
      sx={{
        display: ab({ _: "block", md: "flex" }),
        justifyContent: "space-between",
        "& > div:first-of-type": {
          pr: ab({ md: "0 !important" }),
        },
        "& > div:last-child": {
          pl: ab({ md: "0 !important" }),
        },
      }}
    >
      {children}
    </div>
    <div
      sx={{
        textAlign: "center",
        display: buttonText ? "block" : "none",
      }}
    >
      <Link to={buttonLink} target={linkTarget} language={currentLanguage}>
        <button
          sx={{
            variant: "styles.ghostButton",
            px: 4,
            py: 2,
          }}
        >
          {buttonText}
        </button>
      </Link>
    </div>
  </div>
)

SmallBlogTeasers.propTypes = {
  heading: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  linkTarget: PropTypes.string,
  children: PropTypes.node,
}

export default SmallBlogTeasers
