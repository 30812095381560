/** @jsx jsx */
import { useContext } from "react"
import { jsx, useThemeUI } from "theme-ui"
import PropTypes from "prop-types"

import ModeContext from "../context/mode-context"
import Image from "./image"
import Link from "./link"
import { ab } from "../theme"

const LocationTeaser = ({
  headline,
  infoHeadline,
  infoSubHeadline,
  link,
  linkText,
  image,
  imageAlt,
  mode,
  currentLanguage,
  children,
}) => {
  const { mode: globalMode } = useContext(ModeContext)
  const { rawColors: colors } = useThemeUI().theme

  const modeKey = mode ? mode : globalMode

  const renderInfoSubHeadline = () =>
    !infoSubHeadline ? null : (
      <h6
        sx={{
          fontSize: "14px",
          letterSpacing: 2,
          my: 1,
          textTransform: "uppercase",
          fontWeight: mode === "gutesiegel" ? "bold" : 1,
        }}
      >
        {infoSubHeadline}
      </h6>
    )

  return (
    <div
      className="location-teaser-container"
      sx={{
        variant: "spacingContainer",
        px: ab({ _: 0, md: mode === "gutesiegel" ? 4 : 0 }),
      }}
    >
      <h2
        sx={{
          pl: mode === "gutesiegel" ? 2 : ab({ _: 3, md: 4 }),
          variant: "styles.h2",
        }}
      >
        {headline}
      </h2>
      <div
        className="info-image-container"
        sx={{
          display: ab({ md: "flex" }),
          flexDirection: ab({ md: "row-reverse" }),
        }}
      >
        <div
          className="image-container"
          sx={{
            flex: 2,
            img: {
              objectFit: ab({ _: "contain", md: "cover" }),
              height: "auto",
              minHeight: "100%",
            },
          }}
        >
          <Image
            image={image}
            style={{ height: "100%" }}
            alt={imageAlt}
            sizes={["100vw", "100vw", "70vw", "70vw"]}
          />
        </div>
        <div
          className="info-container"
          sx={{
            flex: 1,
            flexGrow: 0,
            flexBasis: ab({ _: "", md: "36%" }),
            color: "#fff",
            mt: ab({ _: -2, md: "0" }),
            bg:
              modeKey && modeKey !== "gutesiegel"
                ? colors.modes[modeKey].primary
                : colors.modes["group"].primary,
            p: mode === "gutesiegel" ? 5 : 4,
            pr: mode === "gutesiegel" ? 6 : 4,
          }}
        >
          {renderInfoSubHeadline()}
          <h3 sx={{ variant: "styles.h2" }}>{infoHeadline}</h3>
          {children}
          <div className="action" sx={{ mt: 4 }}>
            <Link
              to={link}
              language={currentLanguage}
              sx={{
                variant: "styles.ghostButton",
                color: "#fff",
                border: "solid 1px #fff",
                py: 2,
                px: 4,
                "&:hover": {
                  bg: "#fff",
                  color:
                    modeKey && modeKey !== "gutesiegel"
                      ? colors.modes[modeKey].primary
                      : colors.modes["group"].primary,
                },
              }}
            >
              {linkText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

LocationTeaser.propTypes = {
  headline: PropTypes.string.isRequired,
  infoHeadline: PropTypes.string.isRequired,
  infoSubHeadline: PropTypes.string,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  imageAlt: PropTypes.string,
  mode: PropTypes.oneOf([
    "",
    "pharma",
    "food",
    "environment",
    "career",
    "construction",
    "medical",
    "lifeScience",
    "agroChemical",
    "sustainabilityServices",
    "none",
  ]),
  children: PropTypes.node,
}

LocationTeaser.defaultProps = {
  mode: "",
}

export default LocationTeaser
