/** @jsx jsx */
import { jsx } from "theme-ui"

import {
  FoodIcon,
  GroupIcon,
  PharmaIcon,
  ContactIcon,
  ContactBgIcon,
  EnvironmentIcon,
  CosmeticsIcon,
  VeterinaryIcon,
  CareerIcon,
  ArrowIcon,
  SearchIcon,
  CloseIcon,
  LeftIcon,
  RightIcon,
  Pin,
  Gutesiegel,
  Hersteller,
  Praparate,
  DownloadIcon,
  Phone,
  Envelope,
  Departments,
  Water,
  Construction,
  Application,
  MedicalIcon,
  LifeScienceIcon,
  AgroChemicalsIcon,
  SustainabilityServicesIcon,
  Cts,
  Cls,
  HusAboutIcon,
  HusContactIcon,
  HusServiceIcon,
  HusHomeIcon,
  HusNewsIcon,
  HusJobIcon,
} from "../assets/icons"

const Icon = ({ icon, style = {} }) => {
  switch (icon) {
    case "food":
      return <FoodIcon style={style} />
    case "contact":
      return <ContactIcon style={style} />
    case "contactBg":
      return <ContactBgIcon style={style} />
    case "pharma":
      return <PharmaIcon style={style} />
    case "group":
      return <GroupIcon style={style} />
    case "environment":
      return <EnvironmentIcon style={style} />
    case "cosmetics":
      return <CosmeticsIcon style={style} />
    case "veterinary":
      return <VeterinaryIcon style={style} />
    case "career":
      return <CareerIcon style={style} />
    case "arrow":
      return <ArrowIcon style={style} />
    case "search":
      return <SearchIcon style={style} />
    case "close":
      return <CloseIcon style={style} />
    case "left":
      return <LeftIcon style={style} />
    case "right":
      return <RightIcon style={style} />
    case "pin":
      return <Pin style={style} />
    case "gutesiegel":
      return <Gutesiegel style={style} />
    case "hersteller":
      return <Hersteller style={style} />
    case "praparate":
      return <Praparate style={style} />
    case "download":
      return <DownloadIcon style={style} />
    case "phone":
      return <Phone style={style} />
    case "envelope":
      return <Envelope style={style} />
    case "wasser":
      return <Water style={style} />
    case "construction":
      return <Construction style={style} />
    case "departments":
      return <Departments style={style} />
    case "application":
      return <Application style={style} />
    case "medical":
      return <MedicalIcon style={style} />
    case "lifeScience":
      return <LifeScienceIcon style={style} />
    case "agroChemical":
      return <AgroChemicalsIcon style={style} />
    case "sustainabilityServices":
      return <SustainabilityServicesIcon style={style} />
    case "cts":
      return <Cts style={style} />
    case "cls":
      return <Cls style={style} />
    case "husJob":
      return <HusJobIcon style={style} />
    case "husNews":
      return <HusNewsIcon style={style} />
    case "husHome":
      return <HusHomeIcon style={style} />
    case "husAbout":
      return <HusAboutIcon style={style} />
    case "husContact":
      return <HusContactIcon style={style} />
    case "husService":
      return <HusServiceIcon style={style} />
    case "husWater":
      return <HusWaterIcon style={style} />
    default:
      return <GroupIcon style={style} />
  }
}

export default Icon
