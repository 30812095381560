/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

const GlossarItem = ({ colspan, width, children }) => {
  const itemWwidth = parseInt(colspan) * width
  const height = width

  return (
    <div
      className="glossar-item"
      sx={{
        bg: "#fff",
        boxSizing: "border-box",
        width: `${itemWwidth}px`,
        height: `${height}px`,
        position: "relative",
        zIndex: 10,
        p: "1px",
        boxShadow:
          "1px 0 0 0 #000, 0 1px 0 0 #000, 1px 1px 0 0 #000, 1px 0 0 0 #000 inset, 0 1px 0 0 #000 inset",
        "&:not(:last-child)::after": {
          content: "''",
          display: "block",
          position: "absolute",
          top: 0,
          left: "100%",
          width: `${width}px`,
          height: `${height}px`,
          bg: "#F2F2F2",
          boxShadow:
            "1px 0 0 0 #000, 0 1px 0 0 #000, 1px 1px 0 0 #000, 1px 0 0 0 #000 inset, 0 1px 0 0 #000 inset",
        },
      }}
    >
      {children}
    </div>
  )
}

GlossarItem.propTypes = {
  children: PropTypes.node.isRequired,
  colspan: PropTypes.string,
  width: PropTypes.number,
}

GlossarItem.defaultProps = {
  colspan: "1",
  width: 175,
}

export default GlossarItem
