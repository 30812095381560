/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { useState, useEffect } from "react"
import Image from "./image"

const FullwidthBanner = ({ desktopImage, mobileImage, imageAlt }) => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  })

  const [mobileDisplay, setMobileDisplay] = useState(false)
  const [image, setImage] = useState(desktopImage)

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: document.body.clientWidth,
      })
    }
    handleResize()

    if (windowSize.width < 825) {
      setMobileDisplay(true)
    } else {
      setMobileDisplay(false)
    }

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [windowSize.width])

  useEffect(() => {
    if (mobileDisplay) {
      setImage(mobileImage)
    } else {
      setImage(desktopImage)
    }
  }, [mobileDisplay])

  return (
    <div>
      <Image
        image={image}
        alt={imageAlt}
        style={{
          height: "auto",
          width: "100%",
        }}
      />
    </div>
  )
}

FullwidthBanner.prototype = {
  desktopImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  mobileImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  imageAlt: PropTypes.string,
}

export default FullwidthBanner
