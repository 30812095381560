import React from "react"
import ThemeContext from "../context/theme-context"

import SearchTrigger from "./search-trigger"

const SearchTriggerConsumer = () => (
  <ThemeContext.Consumer>
    {(theme) => (
      <SearchTrigger
        searchVisible={theme.searchVisible}
        toggleSearchDialog={theme.toggleSearchVisibility}
      />
    )}
  </ThemeContext.Consumer>
)

export default SearchTriggerConsumer
