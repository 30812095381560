/** @jsx jsx */
import { jsx } from "theme-ui"

const VeterinaryIcon = ({ style = {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68.62 68.62">
    <g data-name="Ebene 2">
      <g data-name="Ebene 1">
        <g data-name="Gruppe 29">
          <path
            sx={style}
            data-name="Pfad 9"
            d="M57.09 58.51a5.74 5.74 0 0 1 .43 1.06l.42-.39c-.1-.36-.19-.6-.3-.67a.68.68 0 0 0-.56 0m-26 9.95c1.07.1 2.14.16 3.23.16a34.07 34.07 0 0 0 18.45-5.39c0-.33-.08-.65-.1-1a11.88 11.88 0 0 0-1-2.59c-.27-.72-.14-.86-.27-.86s-.14 0-.14.3 0 1.15-.42 1.15-.27-1.3-.55-1.44a16.31 16.31 0 0 1-1.66-.72c-.42-.29-1.39-1.29-1.66-1.44a3.68 3.68 0 0 0-2.08.14c-1.11.29-1.38.72-3.19.44s-5.4-1.58-6.92-1.72a8.47 8.47 0 0 0-3.46.43c-.15.12-.14 4.16-.28 4.6a6 6 0 0 0-.42 2.3 34.35 34.35 0 0 0 .42 4.74 1.46 1.46 0 0 1 0 .9m-3.67-.54c.33.07.67.12 1 .18a1 1 0 0 0 0-.25 5.12 5.12 0 0 1 .28-2.16 6.14 6.14 0 0 0 .18-2.69 5.9 5.9 0 0 0-.95-1.89 3 3 0 0 0-.55 1.73c0 .72.27 4.45.14 4.89a1.66 1.66 0 0 0-.06.21m41.22-33.63a34.31 34.31 0 1 0-43.75 33q0-.21.09-.45a12.81 12.81 0 0 0 .42-3.6c-.14-.43-.69-1.86-1-3s0-3-.28-3-1.66.58-3-2c-.69-1.15-1.39-2.59-1.25-3.31 0-.3-1-4.74-1.11-5.32a6.85 6.85 0 0 0-.41-1.15 33.33 33.33 0 0 0-2.22-3.73c-1-1.3-1.52-2.73-2.63-3s-3.18-1.3-3.87-1.44-.84.3-1.81 0-1.25-1-1.66-1.58-.28-1.73 0-1.87a13 13 0 0 0 1.38-.43c.56-.29 3.6-2.3 4.15-2.59s.7-.86 1.11-1a25 25 0 0 0 3-.8 5.28 5.28 0 0 0-1.57-2c-.94-.68-1.59-1.39.16-.9a5.52 5.52 0 0 1 2.87 2.27.91.91 0 0 1 .42.14c.69.43 1 1.15 1.5 1.72a26 26 0 0 0 4.05 2.28c1.12.58 1.81 1.44 3.88 1.58s6.5 1 8 1.29 7.48.14 9.7.14 6.09.3 6.92.3a28.27 28.27 0 0 1 3.29-.2c.4.16 1.11.72 1.81.58s1-.15 2.21.43a6.31 6.31 0 0 1 2.91 3.16 5.72 5.72 0 0 1 .69 2.59c0 1.22-.41 4.6-.41 5.9 0 .8-.06 3.74-.06 6a34.22 34.22 0 0 0 6.46-20.06"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default VeterinaryIcon
