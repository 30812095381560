/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { ab } from "../theme"

const TwoColumn = ({
  headline,
  headlineName,
  headlinePadding,
  left,
  right,
  mobileLeftBackground,
  mobileRightBackground,
}) => (
  <div
    id={headlineName}
    sx={{
      variant: "spacingContainer",
    }}
  >
    {headline && (
      <div
        sx={{
          variant: "innerSpacingContainer",
          px: ab({ md: 0 }),
          py: headlinePadding ? headlinePadding : "",
          h2: {
            pb: headlinePadding ? "0 !important" : "",
          },
        }}
      >
        <h2
          sx={{
            fontWeight: 1,
            color: "text",
          }}
        >
          {headline}
        </h2>
      </div>
    )}
    <div
      sx={{
        display: ab({ md: "flex" }),
        color: "text",
      }}
    >
      <div
        sx={{
          flex: 1,
          backgroundColor: mobileLeftBackground
            ? ab({
                _: "secondaryBackground",
                md: "transparent !important",
              })
            : "transparent !important",
        }}
      >
        <div
          sx={{
            variant: ab({ _: "innerSpacingContainer", md: "" }),
            py: ab({ _: 1, md: 0 }),
            px: ab({ md: 0 }),
            paddingRight: ab({ md: 4 }),
            my: 0,
          }}
        >
          {left}
        </div>
      </div>
      <div
        sx={{
          flex: 1,
          backgroundColor: mobileRightBackground
            ? ab({
                _: "secondaryBackground",
                md: "transparent !important",
              })
            : "transparent !important",
        }}
      >
        <div
          sx={{
            variant: ab({ _: "innerSpacingContainer", md: "" }),
            py: ab({ _: 1, md: 0 }),
            px: ab({ md: 0 }),
            my: 0,
          }}
        >
          {right}
        </div>
      </div>
    </div>
  </div>
)

TwoColumn.propTypes = {
  headline: PropTypes.string,
  left: PropTypes.node,
  right: PropTypes.node,
  mobileLeftBackground: PropTypes.bool,
  mobileRightBackground: PropTypes.bool,
}

export default TwoColumn
