/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { ab } from "../theme"

const TwoColumnText = ({ heading, children, disableHyphens, topMargin }) => {
  let hyphensStyling = {}
  if (disableHyphens) {
    hyphensStyling = {
      hyphens: "none",
      wordWrap: "normal",
    }
  }
  return (
    <div
      sx={{
        variant: "spacingContainer",
      }}
    >
      <div
        sx={{
          display: ab({ md: "flex" }),
          color: "text",
          mt: topMargin ? ab({ _: 0, md: 6 }) : 0,
        }}
      >
        <h2
          sx={{
            flex: 1,
            my: ab({ _: 2, md: 0 }),
            py: ab({ md: 3 }),
            paddingRight: ab({ md: 4 }),
            variant: "styles.h2",
            ...hyphensStyling,
          }}
        >
          {heading}
        </h2>
        <div
          sx={{
            flex: 1,
            my: 0,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

TwoColumnText.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.node,
  disableHyphens: PropTypes.bool,
}

TwoColumnText.defaultProps = {
  disableHyphens: false,
}

export default TwoColumnText
