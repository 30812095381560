/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import { useContext } from "react"
import PropTypes from "prop-types"

import ShopContext from "../context/shop-context"
import { ab } from "../theme"
import { useEffect } from "react"

const LoginDetails = ({ redirectUrl, reverse }) => {
  const { isLoggedIn, getUser, logout } = useContext(ShopContext)
  const { rawColors: colors } = useThemeUI().theme

  const user = getUser()

  let showLogout

  if (isLoggedIn) {
    showLogout = (
      <a
        sx={{
          textDecoration: "none",
          color: reverse ? colors.modes.group.primary : "#fff",
        }}
        href="#"
        onClick={(event) => {
          event.preventDefault()
          logout()
        }}
      >
        logout
      </a>
    )
  }

  return (
    <div>
      <div
        sx={{
          pl: 4,
          pr: 4,
          py: 3,
          mb: 5,
          backgroundColor: reverse ? colors.modes.group.primary : "#fff",
          color: reverse ? "#fff" : colors.modes.group.text,
          display: "flex",
          flexDirection: ab({ _: "column", md: "row" }),
          justifyContent: "space-between",
          alignItems: ab({ _: "start", md: "center" }),
        }}
      >
        <div>Angemeldet als: {user ? user.email : ""}</div>
        <div
          sx={{
            backgroundColor: reverse ? "#fff" : colors.modes.group.primary,
            px: ab({ _: 3, md: 4 }),
            py: 0,
            hyphens: "none",
            mt: ab({ _: 2, md: 0 }),
          }}
        >
          {showLogout}
        </div>
      </div>
    </div>
  )
}

LoginDetails.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
}

LoginDetails.defaultProps = {
  redirectUrl: "/gba-shop/login/",
}

export default LoginDetails
