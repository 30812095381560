/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import SitesContext from "../../context/site-context"
import { useEffect, useContext, useState } from "react"
import { colors, ab } from "../../theme"

const SitesFilter = ({
  heading,
  countryText,
  industryText,
  lessText,
  moreText,
  plzOrtPlaceholder,
  companyText,
  plzOrtText,
  plzOrtNotice,
  industriesNames,
}) => {
  const { getFilters, setSerach } = useContext(SitesContext)
  const [countries, setCountries] = useState([""])
  const [industries, setIndustries] = useState([""])
  const [companys, setCompanys] = useState([""])
  const [checkedValues, setCheckedValues] = useState({})
  const [radioValue, setRadioValue] = useState({})
  const [showAllIndusty, setShowAllIndusty] = useState(true)
  const [showAllCountry, setShowAllCountry] = useState(true)
  const [showAllCompanyNames, setShowAllCompanyNames] = useState(false)
  const [inputValue, setInputValue] = useState("")

  const [query, setQuery] = useState({
    countries: [],
    industries: [],
    companys: [],
  })

  const handleInputChange = (event) => {
    setQuery({
      countries: [],
      industries: [],
      companys: [],
    })
    setRadioValue()
    setInputValue(event.target.value)
    if (event.target.name === "ortPlz") {
      setQuery((prevQuery) => ({
        ...prevQuery,
        ortPlz: event.target.value,
      }))
    }
  }

  const handleCheckboxChange = (name) => {
    let newCheckedValues

    newCheckedValues = { ...checkedValues }
    newCheckedValues[name] = !newCheckedValues[name]

    setCheckedValues(newCheckedValues)
  }

  const handleRadioChange = (value) => {
    if (radioValue === value) {
      setRadioValue("")
      setQuery((prevQuery) => ({
        ...prevQuery,
        countries: [],
      }))
    } else {
      setRadioValue(value)
      setQuery((prevQuery) => ({
        ...prevQuery,
        countries: [value],
      }))
    }
  }

  const handleChange = (e) => {
    const { value, checked, name } = e.target
    const values = value.split("__")

    if (checked) {
      if (values[0] === "industries") {
        setQuery((prevQuery) => ({
          ...prevQuery,
          industries: [...prevQuery.industries, values[1]],
        }))
      }
      if (values[0] === "companys") {
        setQuery((prevQuery) => ({
          ...prevQuery,
          companys: [...prevQuery.companys, values[1]],
        }))
      }
    } else {
      if (values[0] === "industries") {
        setQuery((prevQuery) => {
          return {
            ...prevQuery,
            industries: prevQuery.industries.filter(
              (country) => country !== values[1]
            ),
          }
        })
      }

      if (values[0] === "companys") {
        setQuery((prevQuery) => {
          return {
            ...prevQuery,
            companys: prevQuery.companys.filter(
              (country) => country !== values[1]
            ),
          }
        })
      }
    }
    // query.push(value)
    handleCheckboxChange(name)
  }

  useEffect(() => {
    let filters = getFilters()
    if (typeof filters[0] !== "undefined") {
      setCountries(Array.from(filters[0]))
    }

    if (typeof filters[1] !== "undefined") {
      setIndustries(Array.from(filters[1]))
    }

    if (typeof filters[2] !== "undefined") {
      setCompanys(Array.from(filters[2]))
    }
  }, [getFilters(), checkedValues])

  useEffect(() => {
    if (typeof radioValue === "object") {
      setQuery((prevQuery) => ({
        ...prevQuery,
        countries: [],
      }))
    }
  }, [radioValue])

  useEffect(() => {
    setSerach(query)
  }, [query, checkedValues])

  return (
    <div
      className="site-filters"
      sx={{
        pr: ab({ md: "10px" }),
        pt: "25px",
        minWidth: ab({ _: "100%", md: "320px" }),
      }}
    >
      <h3
        sx={{
          mt: 0,
          mb: 0,
          pb: "6px",
          borderBottom: "solid 1px",
        }}
      >
        {heading}
      </h3>

      <from
        className="form"
        sx={{
          lable: {
            textTransform: "capitalize",
          },
        }}
      >
        <div
          sx={{
            ul: {
              m: 0,
              p: 0,
              display: "flex",
              maxWidth: " 100%",
              flexWrap: "wrap",
              transition: "ease-in-out all 500ms",
              overflow: "hidden",
              position: "relative",
            },
            h4: {
              mb: "5px",
              mt: 3,
            },
            "> div": {
              display: "grid",
              borderBottom: "solid 1px",
              li: {
                listStyle: "none",
                width: "50%",
              },
              button: {
                pt: "15px",
                border: 0,
                bg: "transparent",
                fontSize: "12px",
                cursor: "pointer",
              },
              'input[type="checkbox"], input[type="radio"]': {
                display: "none",
              },
              'input[type="text"]': {
                border: "0 none ",
                background: "#f5f5f5",
                borderRadius: "10px",
                padding: " 10px",
                mb: 1,
              },
              "input::placeholder": {
                color: "#bab7b7",
              },
              strong: {
                fontWeight: "bold",
              },

              ".btn": {
                textAlign: ab({ _: "center", md: "right" }),
                margin: ab({ _: "5px 0 ", md: "0" }),
                button: {
                  display: ab({ _: "none", md: "inline-block" }),
                  cursor: "pointer",
                },
                span: {
                  display: ab({ _: "inline-block", md: "none" }),
                  height: "25px",
                  width: "25px",
                  borderLeft: "solid 1px",
                  borderBottom: "solid 1px",
                  cursor: "pointer",
                },
              },

              label: {
                display: "flex",
                cursor: "pointer",
                textTransform: "capitalize",
                fontSize: "15px",
                span: {
                  mt: "7px",
                  display: "inline-block",
                  height: "25px",
                  width: "25px",
                  borderRadius: "20px",
                  border: "2px  solid #f5f5f5",
                  bg: "#f5f5f5",
                  position: "absolute",
                },
                "span.checked": {
                  bg: colors.modes["group"].primary,
                },
                em: {
                  lineHeight: "18px",
                  ml: "30px",
                  fontStyle: "normal",
                  padding: "10px 0 ",
                  display: "inline-block",
                },
              },
            },
          }}
        >
          <h4>{countryText}</h4>
          <div>
            <ul
              sx={{
                maxHeight: ab({
                  _: showAllCountry ? "" : "0",
                  md: showAllCountry ? "320px" : "45px",
                }),
                overflowY: ab({
                  _: showAllCountry ? "visible !important" : "hidden",
                  md: showAllCountry ? "" : "45px",
                }),
                "li:nth-child(odd)": {
                  maxWidth: "115px",
                },
              }}
            >
              {countries.sort().map((el, i) => (
                <li>
                  <label for={el} onClick={(e) => handleRadioChange(el)}>
                    {" "}
                    <span
                      className={radioValue === el ? "checked" : "not-checked"}
                    ></span>
                    <em>{el} </em>
                  </label>
                  <input
                    className={radioValue ? "added" : "not-added"}
                    type="radio"
                    name={`country`}
                    id={el}
                    checked={radioValue === el}
                    value={el}
                    onChange={(e) => handleRadioChange(e)}
                  />
                </li>
              ))}
            </ul>
            <div className="btn">
              <button onClick={() => setShowAllCountry(!showAllCountry)}>
                {showAllCountry ? lessText : moreText}
              </button>
              <span
                role="button"
                onClick={() => setShowAllCountry(!showAllCountry)}
                sx={{
                  transform: showAllCountry
                    ? "rotate(135deg)"
                    : "rotate(-45deg)",
                }}
              ></span>
            </div>
          </div>

          <div
            sx={{
              pb: "20px",
            }}
          >
            <h4>{plzOrtText}</h4>
            <input
              type="text"
              name="ortPlz"
              placeholder={plzOrtPlaceholder}
              value={inputValue}
              onChange={handleInputChange}
            />
            <span
              sx={{
                fontSize: "10px",
                fontStyle: "italic",
              }}
            >
              {plzOrtNotice}
            </span>
          </div>

          <div>
            <h4>{industryText} </h4>
            <div>
              <ul
                sx={{
                  maxHeight: ab({
                    _: showAllIndusty ? "" : "0",
                    md: showAllIndusty ? "800px" : "45px",
                  }),
                  "li:nth-child(odd)": {
                    maxWidth: "115px",
                  },
                }}
              >
                {industries.sort().map((el, i) => {
                  return (
                    <li>
                      <label for={el}>
                        <span
                          className={
                            checkedValues[`industries__${el}`]
                              ? "checked"
                              : "not-checked"
                          }
                        ></span>
                        <em>
                          {industriesNames[el] !== undefined
                            ? industriesNames[el]
                            : el}
                        </em>{" "}
                      </label>
                      <input
                        type="checkbox"
                        checked={checkedValues[`industries__${el}`]}
                        id={el}
                        name={`industries__${el}`}
                        value={`industries__${el}`}
                        onChange={(e) => handleChange(e)}
                      />
                    </li>
                  )
                })}
              </ul>
              <div className="btn">
                <button onClick={() => setShowAllIndusty(!showAllIndusty)}>
                  {showAllIndusty ? lessText : moreText}
                </button>
                <span
                  role="button"
                  onClick={() => setShowAllIndusty(!showAllIndusty)}
                  sx={{
                    transform: showAllIndusty
                      ? "rotate(135deg)"
                      : "rotate(-45deg)",
                  }}
                ></span>
              </div>
            </div>
          </div>

          <div>
            <h4>{companyText} </h4>
            <div>
              <ul
                sx={{
                  maxHeight: ab({
                    _: showAllCompanyNames ? "" : "0",
                    md: showAllCompanyNames ? "1300px" : "190px",
                  }),
                  ">li ": {
                    width: "100% !important",
                  },
                }}
              >
                {companys.sort().map((el, i) => (
                  <li>
                    <label for={el}>
                      <span
                        className={
                          checkedValues[`companys__${el}`]
                            ? "checked"
                            : "not-checked"
                        }
                      ></span>
                      <em sx={{ textTransform: "none" }}>{el}</em>{" "}
                    </label>
                    <input
                      type="checkbox"
                      checked={checkedValues[`companys__${el}`]}
                      id={el}
                      name={`companys__${el}`}
                      value={`companys__${el}`}
                      onChange={(e) => handleChange(e)}
                    />
                  </li>
                ))}
              </ul>
              <div className="btn">
                <button
                  onClick={() => setShowAllCompanyNames(!showAllCompanyNames)}
                >
                  {showAllCompanyNames ? lessText : moreText}
                </button>
                <span
                  role="button"
                  onClick={() => setShowAllCompanyNames(!showAllCompanyNames)}
                  sx={{
                    transform: showAllCompanyNames
                      ? "rotate(135deg)"
                      : "rotate(-45deg)",
                  }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      </from>
    </div>
  )
}

SitesFilter.propTypes = {}

export default SitesFilter
