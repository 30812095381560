/** @jsx jsx */
import { jsx } from "theme-ui"

const Hersteller = ({ style = {} }) => (
  <svg
    version="1.1"
    id="Capa_1"
    x="0"
    y="0"
    viewBox="0 0 512 512"
    style={{ fill: "#004787" }}
  >
    <path d="M25.9 164.7v335.2c0 6.7 5.4 12.1 12.1 12.1h436c6.7 0 12.1-5.4 12.1-12.1V164.7c0-6.7-5.4-12.1-12.1-12.1h-96.9V12.1C377.1 5.4 371.7 0 365 0H183.1C176.4 0 171 5.4 171 12.1s5.4 12.1 12.1 12.1h169.8v463.6h-48.4v-60.5c0-6.7-5.4-12.1-12.1-12.1h-72.7c-6.7 0-12.1 5.4-12.1 12.1v60.5h-48.4V24.2h42.4c6.7 0 12.1-5.4 12.1-12.1S208.2 0 201.5 0H147c-6.7 0-12.1 5.4-12.1 12.1v140.5H38c-6.7 0-12.1 5.4-12.1 12.1zm351.2 12.1h84.8v311h-84.8v-311zM231.8 439.3h48.4v48.4h-48.4v-48.4zM50.1 176.8h84.8v311H50.1v-311z" />
    <path d="M292.3 335.2h-72.7c-6.7 0-12.1 5.4-12.1 12.1 0 6.7 5.4 12.1 12.1 12.1h72.7c6.7 0 12.1-5.4 12.1-12.1 0-6.7-5.4-12.1-12.1-12.1zM292.3 262.5h-72.7c-6.7 0-12.1 5.4-12.1 12.1 0 6.7 5.4 12.1 12.1 12.1h72.7c6.7 0 12.1-5.4 12.1-12.1 0-6.6-5.4-12.1-12.1-12.1zM292.3 189.9h-72.7c-6.7 0-12.1 5.4-12.1 12.1 0 6.7 5.4 12.1 12.1 12.1h72.7c6.7 0 12.1-5.4 12.1-12.1s-5.4-12.1-12.1-12.1zM292.3 116.6h-72.7c-6.7 0-12.1 5.4-12.1 12.1 0 6.7 5.4 12.1 12.1 12.1h72.7c6.7 0 12.1-5.4 12.1-12.1 0-6.6-5.4-12.1-12.1-12.1zM292.3 44h-72.7c-6.7 0-12.1 5.4-12.1 12.1 0 6.7 5.4 12.1 12.1 12.1h72.7c6.7 0 12.1-5.4 12.1-12.1S299 44 292.3 44z" />
  </svg>
)

export default Hersteller
