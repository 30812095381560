/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Link from "./link"
import { ab } from "../theme"

const BigBlogTeasers = ({
  heading,
  buttonLink,
  buttonText,
  linkTarget,
  children,
  currentLanguage,
}) => (
  <div
    className="big-blog-teasers"
    sx={{
      variant: "alternatingSpacingContainer",
      backgroundColor: "secondaryBackground",
      color: "text",
    }}
  >
    <h2
      sx={{
        variant: "bigblogteaser.h2",
      }}
    >
      {heading}
    </h2>
    <div>{children}</div>
    <div
      sx={{
        mt: ab({ _: 2, md: 4 }),
        textAlign: ab({ _: "center", md: "left" }),
        display: buttonText ? "block" : "none",
      }}
    >
      <Link language={currentLanguage} to={buttonLink} target={linkTarget}>
        <button
          sx={{
            variant: "styles.ghostButton",
            px: 4,
            py: 2,
            "&:first-letter": {
              textTransform: "uppercase",
            },
          }}
        >
          {buttonText}
        </button>
      </Link>
    </div>
  </div>
)

BigBlogTeasers.propTypes = {
  heading: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  linkTarget: PropTypes.string,
  children: PropTypes.node,
}

export default BigBlogTeasers
