/** @jsx jsx */
import { useContext } from "react"
import { jsx, useThemeUI } from "theme-ui"
import PropTypes from "prop-types"

import ModeContext from "../context/mode-context"
import Link from "./link"
import Image from "./image"
import { ab } from "../theme"

const BigBlogTeaser = ({
  date,
  image,
  imageAlt,
  blogHeading,
  blogLink,
  blogLinkText,
  mode,
  linkTarget,
  children,
  currentLanguage,
  tagList,
}) => {
  const { mode: globalMode } = useContext(ModeContext)
  const { rawColors: colors } = useThemeUI().theme

  const modeKey = mode ? mode : globalMode

  const convertToUSDate = (germanDate) => {
    const [day, month, year] = germanDate.split(".")
    const usDate = `${month}/${day}/${year}`
    return usDate
  }
  if (process.env.GATSBY_CHANGE_DATE_FORMAT_US === "true") {
    date = convertToUSDate(date)
  }

  let img
  if (image) {
    img = (
      <Image
        image={image}
        alt={imageAlt ? imageAlt : blogHeading}
        style={{ width: "100%", height: "auto" }}
        sizes={["100vw", "100vw", "50vw", "50vw"]}
      />
    )
  }

  let setLink = (content) => {
    if (blogLink) {
      return (
        <Link
          language={currentLanguage}
          to={blogLink}
          target={linkTarget}
          sx={{
            color: "inherit",
            textDecoration: "none",
          }}
        >
          {content}
        </Link>
      )
    }
  }

  let tagListing = []
  if (tagList.length > 0) {
    for (let i = 0; i < tagList.length; i++) {
      let tag = tagList[i]
      tagListing.push(
        <div
          key={i}
          sx={{
            float: "left",
            mr: 2,
          }}
        >
          <Link
            to={`tags/${tag.toLowerCase()}`}
            language={currentLanguage}
            sx={{
              color: modeKey
                ? colors.modes[modeKey].primary
                : colors.modes["group"].primary,
              textDecoration: "none",
            }}
          >
            {tag}
            {!(i === tagList.length - 1) && ","}
          </Link>
        </div>
      )
    }
  }

  let teaserContentDateAndImage = setLink(
    <div>
      <span
        className="blog-date"
        data-mode={mode}
        sx={{
          display: date ? "block" : "none",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 10,
          bg: modeKey
            ? colors.modes[modeKey].primary
            : colors.modes["group"].primary,
          color: "background",
          py: 2,
          px: ab({ _: 2, md: 3 }),
          ml: ab({ _: 0, md: -3 }),
          transform: "translateY(-50%)",
          fontSize: 1,
          letterSpacing: 1,
        }}
      >
        {date}
      </span>
      {img}
    </div>
  )

  let teaserContentHeadline = setLink(
    <h2
      sx={{
        variant: "bigblogteaser.h2",
        my: ab({ _: 2, md: 0 }),
      }}
    >
      {blogHeading}
    </h2>
  )

  let teaserContentChildrenAndLink = setLink(
    <div>
      {children}
      {blogLink && (
        <div
          sx={{
            my: ab({ _: 2, md: 0 }),
            float: "right",
            variant: "styles.linkbtmbrd",
          }}
        >
          {blogLinkText}
        </div>
      )}
    </div>
  )

  let teaserContent = (
    <article
      sx={{
        display: "flex",
        flexDirection: ab({ _: "column", md: "row" }),
      }}
    >
      <div
        className="image"
        sx={{
          flex: 1,
          textAlign: "center",
          position: "relative",
        }}
      >
        {teaserContentDateAndImage}
      </div>
      <div
        sx={{
          flex: 1,
          paddingLeft: ab({ md: 5 }),
          position: "relative",
        }}
      >
        {teaserContentHeadline}
        {tagListing.length > 0 && (
          <div
            sx={{
              "&:after": {
                content: "''",
                width: "100%",
                display: "table",
                clear: "both",
                height: "0",
              },
            }}
          >
            {tagListing}
          </div>
        )}
        {teaserContentChildrenAndLink}
      </div>
    </article>
  )

  return (
    <div
      className="big-blog-teaser"
      data-mode={mode}
      sx={{
        my: 5,
        "&:last-of-type:not(:first-of-type)": {
          my: 0,
        },
      }}
    >
      {teaserContent}
    </div>
  )
}

BigBlogTeaser.propTypes = {
  date: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  imageAlt: PropTypes.string,
  blogHeading: PropTypes.string.isRequired,
  blogLink: PropTypes.string,
  blogLinkText: PropTypes.string,
  linkTarget: PropTypes.string,
  children: PropTypes.node,
  mode: PropTypes.oneOf([
    "",
    "pharma",
    "food",
    "environment",
    "career",
    "construction",
    "medical",
    "lifeScience",
    "agroChemical",
    "sustainabilityServices",
    "none",
  ]),
  tagList: PropTypes.array,
}

BigBlogTeaser.defaultProps = {
  mode: "",
  tagList: [],
}

export default BigBlogTeaser
