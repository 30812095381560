/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

const OneColumn = ({ headline, headlineName, anchorName, children }) => (
  <div
    id={anchorName ? anchorName : ""}
    sx={{
      variant: "spacingContainer",
    }}
  >
    <div
      sx={{
        variant: "oneColumn",
        h4: {
          fontSize: "24px",
          m: 0,
        },
      }}
    >
      {headline && (
        <div sx={{}}>
          <h2
            id={headlineName}
            sx={{
              fontWeight: 1,
              color: "text",
            }}
          >
            {headline}
          </h2>
        </div>
      )}

      <div>{children}</div>
    </div>
  </div>
)

OneColumn.propTypes = {
  headline: PropTypes.string,
  headlineName: PropTypes.string,
  children: PropTypes.node,
}

export default OneColumn
