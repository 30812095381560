/** @jsx jsx */
import { useContext } from "react"
import { jsx, useThemeUI } from "theme-ui"
import PropTypes from "prop-types"

import ModeContext from "../context/mode-context"
import Image from "./image"
import { ab } from "../theme"
import Button from "./button"

const ImageWithTeaserBox = ({
  image,
  imageAlt,
  subHeading,
  heading,
  headlineName,
  buttonText,
  link,
  linkTarget,
  download,
  orientation,
  currentLanguage,
  mode,
  disableHyphens,
}) => {
  const { mode: globalMode } = useContext(ModeContext)
  const { rawColors: colors } = useThemeUI().theme

  const modeKey = mode ? mode : globalMode

  let hyphensStyling = {}
  if (disableHyphens) {
    hyphensStyling = {
      hyphens: "none",
      wordWrap: "normal",
    }
  }
  let getButton = (buttonText, link) => {
    if (buttonText && link) {
      return (
        <Button
          language={currentLanguage}
          buttonText={buttonText}
          link={link}
          linkTarget={linkTarget}
          download={download}
          style={{
            float: orientation === "right" ? ab({ md: "right" }) : "none",
          }}
        />
      )
    } else {
      return ""
    }
  }

  let button = () => {
    if (mode === "gutesiegel") {
      return (
        <div
          sx={{
            flex: 1,
            p: 4,
            "& button": {
              color: colors.primary,
              borderColor: colors.primary,
              ":hover": {
                bg: colors.primary,
              },
            },
          }}
        >
          {getButton(buttonText, link)}
        </div>
      )
    } else {
      return <div sx={{ flex: 1, p: 4 }}>{getButton(buttonText, link)}</div>
    }
  }
  return (
    <div className="image-with-teaser-box" sx={{ variant: "spacingContainer" }}>
      <div
        sx={{
          display: ab({ md: "flex" }),
          flexDirection: orientation === "right" ? "row" : "row-reverse",
        }}
      >
        <div
          sx={{
            width: ab({ _: "100%", md: "70%" }),
            overflow: "hidden",
          }}
        >
          <Image
            image={image}
            alt={imageAlt ? imageAlt : heading}
            sizes={["100vw", "100vw", "70vw", "70vw"]}
          />
        </div>
        <div
          sx={{
            width: ab({ md: "40%" }),
            marginRight: orientation === "right" ? 0 : ab({ md: "-10%" }),
            marginLeft: orientation === "right" ? ab({ md: "-10%" }) : 0,
            display: ab({ md: "flex" }),
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
            zIndex: 2,
          }}
        >
          <div
            sx={{
              flex: 1,
              p: 4,
              display: ab({ _: "none", md: "block" }),
            }}
          ></div>
          <div
            sx={{
              bg: modeKey
                ? colors.modes[modeKey].primary
                : colors.modes["group"].primary,
              p: 4,
              color: mode === "gutesiegel" ? colors.primary : "#fff",
            }}
          >
            <div
              sx={{
                textTransform: "uppercase",
                letterSpacing: 2,
                fontSize: 2,
              }}
            >
              {subHeading}
            </div>
            <h2
              id={headlineName}
              sx={{
                variant: "styles.h2",
                ...hyphensStyling,
                maxWidth: ab({ lg: "80%" }),
              }}
            >
              {heading}
            </h2>
          </div>
          {button()}
        </div>
      </div>
    </div>
  )
}

ImageWithTeaserBox.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  imageAlt: PropTypes.string,
  subHeading: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  headlineName: PropTypes.string,
  buttonText: PropTypes.string,
  link: PropTypes.string,
  linkTarget: PropTypes.string,
  download: PropTypes.bool,
  orientation: PropTypes.oneOf(["right", "left"]).isRequired,
  mode: PropTypes.oneOf([
    "",
    "pharma",
    "food",
    "environment",
    "career",
    "construction",
    "medical",
    "lifeScience",
    "agroChemical",
    "sustainabilityServices",
    "none",
  ]),
  disableHyphens: PropTypes.bool,
}

ImageWithTeaserBox.defaultProps = {
  orientation: "left",
  download: false,
  disableHyphens: false,
}

export default ImageWithTeaserBox
