/** @jsx jsx */
import { jsx } from "theme-ui"

const WaterIcon = ({ style = {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 199.12">
    <g data-name="Ebene 2">
      <g data-name="Ebene 1">
        <g data-name="Gruppe 154">
          <path
            sx={style}
            data-name="Pfad 43"
            d="M100.38 0a99.56 99.56 0 0 0-1.64 199.12 44.49 44.49 0 0 1-15.06-20.66c-6.82-19.26 2.25-32.58 11.79-44.61 1.45-1.76 3.29-4.09 5.3-6.66 7.54-9.54 17.65-22.45 20.61-26.31a.91.91 0 0 1 .81-.4 1.29 1.29 0 0 1 .88.4c3.93 5 20 25.59 26.07 33.05 7.22 9 14 18.86 14 31.53a41.33 41.33 0 0 1-2 13A99.56 99.56 0 0 0 100.38 0M71.45 108.31c-.41 0-.83 0-1.25.09a23.55 23.55 0 0 1-2.52-.05A24 24 0 0 1 47.18 92c-3.92-11.06 1.29-18.7 6.76-25.61.84-1 1.89-2.34 3.05-3.82 4.32-5.48 10.13-12.89 11.83-15.11a.53.53 0 0 1 .46-.23.78.78 0 0 1 .51.23c2.25 2.87 11.48 14.7 15 19a29 29 0 0 1 8 18.1 23.75 23.75 0 0 1-21.37 23.81"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default WaterIcon
