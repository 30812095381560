/** @jsx jsx */
import { jsx } from "theme-ui"

const SearchIcon = ({ style = { fill: "#646464" } }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 500 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
  >
    <path
      sx={style}
      d="M497.05,482.78L328.58,314.32c-0.08-0.08-0.16-0.14-0.24-0.22c30.01-33.37,48.3-77.49,48.3-125.79
  	c0-103.83-84.47-188.29-188.31-188.29S0,84.48,0,188.3c0,103.84,84.48,188.32,188.32,188.32c48.31,0,92.41-18.29,125.78-48.3
  	c0.08,0.08,0.14,0.17,0.22,0.24l168.46,168.46c3.94,3.94,10.32,3.94,14.26,0C500.98,493.1,500.98,486.72,497.05,482.78z
  	 M188.32,356.46c-92.72,0-168.16-75.43-168.16-168.16c0-92.71,75.44-168.13,168.16-168.13S356.47,95.59,356.47,188.3
  	C356.47,281.03,281.04,356.46,188.32,356.46z"
    />
  </svg>
)

export default SearchIcon
