/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { SearchIcon } from "../assets/icons"
import { ab } from "../theme"

const SearchTrigger = ({ searchVisible, toggleSearchDialog }) => (
  <div
    role="button"
    tabIndex="0"
    onKeyPress={() => toggleSearchDialog(true)}
    onClick={() => toggleSearchDialog(true)}
    sx={{
      display: ab({
        _: searchVisible ? "none" : "inline-block",
        md: "inline-block",
      }),
      height: 20,
      width: 20,
      margin: "5px",
      marginRight: ab({ _: "20px", md: "5px" }),
      marginBottom: "10px",
      cursor: "pointer",
    }}
  >
    <SearchIcon />
  </div>
)

SearchTrigger.propTypes = {
  toggleSearchDialog: PropTypes.func.isRequired,
}

export default SearchTrigger
