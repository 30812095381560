/** @jsx jsx */
import { jsx } from "theme-ui"

const SustainabilityServicesIcon = ({ style = {} }) => (
  <svg
    id="Ebene_2"
    data-name="Ebene 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 250 250"
  >
    <g id="Ebene_1-2" data-name="Ebene 1">
      <g>
        <path
          sx={style}
          d="m76.49,147.57c-1.07,2.1-1.07,4.59,0,6.71,1.44,2.89.87,6.38-1.41,8.67l-.22.22c-2.44,2.43-6.21,2.9-9.16,1.13l-13.08-7.87c-1.53-.92-2.69-2.36-3.25-4.06l-3.22-9.66c-.15,0-.25-.04-.39-.05,6.56-19.68,20.65-35.96,39.18-45.25l4.66,3.23c1.03.72,1.87,1.68,2.43,2.81l1.17,2.34c1.27,2.54,3.87,4.15,6.71,4.15h8.33c4.14,0,7.5,3.35,7.5,7.5v.15c0,2.84-1.61,5.45-4.15,6.72l-19.99,10.01c-.28.13-.55.29-.81.46l-10.23,6.83c-1.09.73-1.96,1.72-2.55,2.89l-1.52,3.06Z"
        />
        <path
          sx={style}
          d="m147.86,179.62c5.42-3.44,11.06-6.51,16.91-9.2-3.89,2.97-7.47,5.93-10.76,8.84-36.11,31.98-38.01,59.9-37.06,70.74-4.07-.26-8.08-.72-12.04-1.36,0-2.95.19-5.91.57-8.84,2.06-16.52,9.76-32.55,22.53-44.93.69-.67,1.39-1.33,2.1-1.97,5.57-4.89,11.51-9.33,17.75-13.28Z"
        />
        <path
          sx={style}
          d="m250,125.13c0,53.6-33.68,99.34-81,117.16,11.33-6.37,20.68-14.8,28.03-25.3,32.53-46.49,13.56-118.23,13.37-118.93-.21-.84-.96-1.43-1.83-1.44h-.04c-.85,0-1.6.55-1.86,1.36-.05.19-3.43,10.81-14.97,22.03-1.79,1.73-3.77,3.48-5.97,5.22-1.52,1.2-3.14,2.38-4.87,3.55-6.99,4.69-15.74,9.03-26.66,12.19-19.47,5.38-37.28,15.44-51.91,29.23-2.64,2.48-5.17,5.09-7.59,7.81-3.31,3.84-6.04,8.18-8.08,12.83-1.35,3.09-2.47,6.17-3.38,9.2-4.64,15.36-3.9,29.86-.16,42.99-2.7-.95-5.35-2-7.96-3.13-3.51-15.88-2.48-31.73,3.12-46.96l-2.59-3.89c-.55-.83-.85-1.8-.85-2.8v-3.52c.01-1.91,1.09-3.66,2.8-4.52l3.88-1.96c.98-.49,1.77-1.28,2.26-2.26l1.95-3.88c.86-1.71,2.61-2.79,4.52-2.8h5.03c15.87-15.73,35.46-27.09,57.1-33.07,7.59-2.2,14.47-5.1,20.62-8.72h-3.6c-1.09,0-2.16.25-3.13.74l-6.88,3.44c-.97.49-2.04.74-3.13.73h-1.24c-3.83,0-6.94-3.11-6.94-6.96,0-.91.18-1.8.53-2.64h.03c.35-.85.86-1.62,1.5-2.26l7-7.01c.53-.52,1.14-.96,1.8-1.3l7.35-3.67c.64-.31,1.32-.51,2.03-.61,5.02,3.96,9.53,8.52,13.42,13.55,2.07-1.65,4.03-3.4,5.87-5.26-15.74-20.09-40.21-33-67.69-33-47.47,0-85.95,38.52-85.95,86.04,0,29.2,14.54,55,36.74,70.55C28.84,217.61,0,174.74,0,125.13,0,56.02,55.96,0,125,0s125,56.02,125,125.13Z"
        />
      </g>
    </g>
  </svg>
)

export default SustainabilityServicesIcon
