/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { ab } from "../theme"
import Image from "./image"
import Link from "./link"

const LogoGalleryItem = ({ image, alt, link, linkTarget, currentLanguage }) => (
  <div
    sx={{
      width: ab({ _: "50%", md: "25%" }),
      display: "inline-block",
      verticalAlign: "middle",
      p: 3,
    }}
  >
    <Link to={link} target={linkTarget} language={currentLanguage}>
      <Image image={image} alt={alt} sizes={["50vw", "50vw", "25vw", "25vw"]} />
    </Link>
  </div>
)

LogoGalleryItem.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  link: PropTypes.string.isRequired,
  linkTarget: PropTypes.string,
  alt: PropTypes.string,
}

export default LogoGalleryItem
