/** @jsx jsx */
import { jsx } from "theme-ui"

const MedicalIcon = ({ style = {} }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
    <g>
      <path
        sx={style}
        d="M7.53 52.01c.66 0 1.3-.21 1.85-.62l3.42-2.63-2.54-2.57a3.114 3.114 0 00-4.27-.12l-.55.49c-.65.58-1.02 1.37-1.05 2.24-.04 1.2.61 2.29 1.67 2.85.46.24.96.36 1.46.36z"
      />

      <path
        sx={style}
        d="M36 0C16.12 0 0 16.12 0 36c0 3.26.44 6.41 1.25 9.42.37-.64.84-1.22 1.4-1.71l.66-.59c2.77-2.46 6.99-2.33 9.62.29l2.48 2.52c3.51-2.69 6.12-4.7 8.08-6.21 5.41-4.16 5.86-4.51 6.14-4.59l6.04-4.53 5.19-9.99c1.02-2.13 2.72-3.73 4.66-4.4 1.66-.57 3.41-.44 5.04.38a2.231 2.231 0 01-.29 4.11c-.57.19-1.17.15-1.7-.12-.55-.27-1.1-.32-1.63-.13-.8.28-1.55 1.05-2.06 2.12s-.03.06-.04.09l-4.68 9 1.16 1.16L50 28.31c.16-.08.3-.16.42-.2 1.07-.52 1.84-1.27 2.12-2.07.18-.54.14-1.09-.13-1.63-.55-1.1-.1-2.44 1-2.99 1.1-.55 2.44-.1 2.99 1 .82 1.63.95 3.38.38 5.04-.66 1.94-2.27 3.64-4.4 4.66l-9.99 5.19c-.87 1.17-1.56 2.08-2.12 2.83q-2.72 3.63-8.2 10.77L27 57.51c2.03 2 2.45 2.41 2.51 2.47l.02.02.42.42-.02.02a7.015 7.015 0 01-.12 9.17l-.59.66c-.3.33-.62.63-.97.9 2.5.55 5.11.85 7.78.85 19.88 0 36-16.12 36-36S55.88 0 36 0z"
      />
      <path
        sx={style}
        d="M18.34 61.41s.01-.02.02-.03l.01-.01c.1-.12.59-.76 2.66-3.46l-.81-.8c-.42-.42-.66-.98-.66-1.57a2.219 2.219 0 012.21-2.24h.02c.59 0 1.15.23 1.57.64l.42.41 8.94-11.65-2.5-2.5-11.65 8.94.47.48c.86.88.85 2.29-.02 3.15-.88.86-2.29.85-3.15-.02l-.86-.88-3.47 2.67c-1.18.91-2.68 1.41-4.21 1.41-.47 0-.92-.04-1.37-.13a36.2 36.2 0 0011.1 10.8c-.26-1.79.17-3.66 1.3-5.19zM24.13 60.1l-2.62 3.42a3.13 3.13 0 00.29 4.06c.59.59 1.37.91 2.2.91h.09c.85-.03 1.67-.41 2.24-1.04l.49-.55a3.124 3.124 0 00-.12-4.27l-2.57-2.53z"
      />
    </g>
  </svg>
)

export default MedicalIcon
