/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import xss from "xss"
import { ab } from "../theme"

const Headline = ({
  headline,
  headlineName,
  children,
  disableHyphens,
  topMargin,
}) => {
  let textElement
  if (children) {
    textElement = (
      <div
        sx={{
          marginBottom: 5,
        }}
      >
        {children}
      </div>
    )
  }

  let hyphensStyling = {}
  if (disableHyphens) {
    hyphensStyling = {
      hyphens: "none",
      wordWrap: "normal",
    }
  }

  return (
    <div
      sx={{
        my: 0,
        py: 0,
        px: ab({ _: 3, md: 4 }),
      }}
    >
      <h1
        id={headlineName}
        sx={{
          mt: topMargin ? ab({ _: 4, md: 6 }) : "0.67em",
          variant: "styles.h1",
          paddingLeft: "0px !important",
          ...hyphensStyling,
        }}
        dangerouslySetInnerHTML={{
          __html: xss(headline, { whiteList: { strong: [] } }),
        }}
      />
      {textElement}
    </div>
  )
}
Headline.propTypes = {
  headline: PropTypes.string.isRequired,
  children: PropTypes.node,
  disableHyphens: PropTypes.bool,
  topMargin: PropTypes.bool,
}

Headline.defaultProps = {
  disableHyphens: false,
  topMargin: false,
}

export default Headline
