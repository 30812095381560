/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import ContactViaMail from "./contact-via-mail"

const ContactViaMailContainer = ({ heading, label, mailListing, required }) => {
  return (
    <div
      sx={{
        variant: "innerSpacingContainer",
        maxWidth: "1100px",
      }}
    >
      <ContactViaMail
        heading={heading}
        label={label}
        mailListing={mailListing}
        required={required}
      />
    </div>
  )
}

ContactViaMailContainer.propTypes = {
  heading: PropTypes.string,
  label: PropTypes.string.isRequired,
  mailListing: PropTypes.array.isRequired,
  required: PropTypes.bool,
}

export default ContactViaMailContainer
