/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { ab } from "../theme"

const PageOverview = ({ heading, children }) => (
  <div
    sx={{
      variant: "alternatingSpacingContainer",
      backgroundColor: "secondaryBackground",
      color: "text",
    }}
  >
    <h2
      sx={{
        variant: "bigblogteaser.h2",
        mt: ab({ md: 0 }),
      }}
    >
      {heading}
    </h2>
    <div>{children}</div>
  </div>
)

PageOverview.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default PageOverview
