/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { useState, useEffect } from "react"
import Image from "./image"

const slideImage = ({ image, mobileImage, alt }) => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  })

  const [mobileDisplay, setMobileDisplay] = useState(false)
  const [imageRender, setImageRender] = useState(image)

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: document.body.clientWidth,
      })
    }
    handleResize()

    if (windowSize.width < 825) {
      setMobileDisplay(true)
    } else {
      setMobileDisplay(false)
    }

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [windowSize.width])

  useEffect(() => {
    if (mobileDisplay) {
      setImageRender(mobileImage)
    } else {
      setImageRender(image)
    }
  }, [mobileDisplay])

  return (
    <Image
      style={{ position: "absolute !important", height: "100%", width: "100%" }}
      image={imageRender}
      alt={alt}
    />
  )
}

export default slideImage
