/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { ab } from "../theme"
import Link from "./link"

const BannerNewsTeaser = ({
  heading,
  date,
  link,
  currentLanguage,
  children,
}) => {
  const convertToUSDate = (germanDate) => {
    const [year, month, day] = germanDate.split("-")
    const usDate = `${month}/${day}/${year}`
    return usDate
  }
  if (process.env.GATSBY_CHANGE_DATE_FORMAT_US === "true") {
    date = convertToUSDate(date)
  }

  return (
    <div
      sx={{
        mb: ab({ _: "50px", md: 4 }),
        color: "text",
      }}
      className="banner-news-teaser"
    >
      <span
        sx={{
          display: "inline",
          fontSize: ab({ _: "12px", md: "14px" }),
        }}
      >
        {date}
      </span>
      <div>
        <h3
          sx={{
            m: 0,
            lineHeight: 1.4,
            fontSize: "16px",
            mb: 2,
            fontWeight: "600",
          }}
        >
          <Link
            language={currentLanguage}
            to={link}
            sx={{
              color: "text",
              textDecoration: "none",
              textOverflow: "ellipsis ",
            }}
          >
            {heading}
          </Link>
        </h3>
        <div
          sx={{
            fontSize: ab({ _: "12px", md: "14px" }),
            "-webkit-line-clamp": ab({ _: "2", md: "3" }),
            "-webkit-box-orient": "vertical",
            lineHeight: 1.3,
            overflow: "hidden",
            textOverflow: "ellipsis ",
            display: "-webkit-box",
            maxWidth: "98%",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

BannerNewsTeaser.prototype = {
  heading: PropTypes.string.isRequired,
  date: PropTypes.date,
  newsLink: PropTypes.string,
  linkTarget: PropTypes.string,
  children: PropTypes.object,
}

export default BannerNewsTeaser
