/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import { useRef, useState, useContext } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

import ShopContext from "../context/shop-context"
import EditorContext from "../context/editor-context"
import Input from "./form/input"
import { ab } from "../theme"

const Login = ({
  redirectUrl,
  heading,
  subHeading,
  buttonText,
  type,
  passwordForgottenText,
}) => {
  const isEditor = useContext(EditorContext)

  const [message, setMessage] = useState("")
  const [success, setSuccess] = useState(true)
  const [animate, setAnimate] = useState(true)
  const refForm = useRef()

  const { requestPasswordRecovery, handleLogin, isLoggedIn } =
    useContext(ShopContext)

  const { rawColors: colors } = useThemeUI().theme

  const handleSubmit = (evt) => {
    const form = refForm.current.elements
    const { email, password } = form

    evt.preventDefault()
    setSuccess(false)

    if (type === "recover") {
      requestPasswordRecovery(email.value, (correct, msg) => {
        if (msg) {
          setMessage(msg)
        }
        setSuccess(correct)
        setAnimate(true)
      })
    } else {
      handleLogin(email.value, password.value, (correct, msg) => {
        if (msg) {
          setMessage(msg)
        }
        setSuccess(correct)
        setAnimate(true)

        if (correct) {
          navigate(redirectUrl)
        }
      })
    }
    setAnimate(false)
  }

  if (
    typeof window !== `undefined` &&
    !isEditor &&
    type === "login" &&
    isLoggedIn
  ) {
    navigate(redirectUrl)
  }

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "90vh",
        position: "relative",
        pt: "23vh",
        justifyContent: "space-between",
        bg: ab({ _: colors.modes["group"].primary, md: "transparent" }),
      }}
    >
      <div
        sx={{
          bg: ab({ _: "", md: colors.modes["group"].primary }),
          width: ab({ _: "100%", md: "550px" }),
          px: 4,
          py: 5,
          alignSelf: "center",
        }}
      >
        <h6
          sx={{
            m: 0,
            p: 0,
            fontSize: "14px",
            letterSpacing: 2,
            fontWeight: 1,
            textTransform: "uppercase",
            color: "background",
          }}
        >
          {subHeading}
        </h6>
        <h2
          sx={{
            fontSize: 4,
            my: 1,
            color: "background",
          }}
        >
          {heading}
        </h2>
        <div
          sx={{
            color: "background",
            textAlign: "center",
            padding: 2,
            mt: 3,
            display: message ? "block" : "none",
            variant: message
              ? success
                ? "styles.success"
                : "styles.failed"
              : "",
            animationName: animate ? "swing" : "",
            animationDuration: "600ms",
          }}
        >
          {message}
        </div>
        <form
          name="login"
          ref={refForm}
          onSubmit={handleSubmit}
          sx={{
            pt: 3,
            "&>div": {
              pb: 3,
            },
          }}
        >
          <div>
            <Input
              type="email"
              placeholder="Email"
              required={true}
              name="email"
              label=""
            />
          </div>
          <div
            sx={{
              display: type === "recover" ? "none" : "block",
            }}
          >
            <Input
              type="password"
              placeholder="password"
              required={type === "recover" ? false : true}
              name="password"
              label=""
            />
          </div>
          <div
            sx={{
              display: type === "login" ? "block" : "none",
            }}
          >
            <a
              href="/gba-shop/recover"
              sx={{
                textDecoration: "none",
                color: "#fff",
              }}
            >
              {passwordForgottenText || "Passwort vergessen?"}
            </a>
          </div>
          <div
            sx={{
              pt: 3,
            }}
          >
            <button
              type="submit"
              sx={{
                variant: "styles.ghostButton",
                color: "#fff",
                border: "solid 1px #fff",
                py: 2,
                px: 4,
                "&:hover": {
                  bg: "#fff",
                  color: colors.modes["group"].primary,
                },
              }}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

Login.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["login", "signup", "recover"]),
}

Login.defaultProps = {
  type: "login",
  redirectUrl: "/gba-shop/",
}

export default Login
