/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

const PharmaMailchimp = ({ children }) => {
  return (
    <div sx={{}}>
      <div>
        <form
          name="mc-embedded-subscribe-form"
          action="https://gba-pharma.us20.list-manage.com/subscribe/post?u=1a7ee826c7f80557a0d0b5db5&amp;id=3edae16adb"
          method="post"
        >
          {children}

          <div
            sx={{
              position: "absolute",
              left: "-50000px",
            }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_1a7ee826c7f80557a0d0b5db5_3edae16adb"
              tabindex="-1"
              value=""
            />
          </div>
        </form>
      </div>
    </div>
  )
}

PharmaMailchimp.propTypes = {
  children: PropTypes.node,
}

export default PharmaMailchimp
