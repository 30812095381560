/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"

const SwitchColorMode = ({ mode }) => {
  const [colorMode, setColorMode] = useColorMode()

  if (mode !== colorMode) {
    setColorMode(mode)
  }

  return <div> </div>
}

export default SwitchColorMode
