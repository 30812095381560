/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import PropTypes from "prop-types"

import Input from "./input"

const DepartmentSelect = ({
  departments,
  required = true,
  onChangeHandler = () => {},
}) => {
  const [departmentId, setDepartmentId] = useState("")
  const [error, setError] = useState(false)

  return (
    <div
      sx={{
        variant: "form.formField",
        border: error ? "solid #ff3454 2px" : "",
      }}
    >
      <select
        sx={{
          variant: "form.input",
        }}
        required={required}
        onChange={(e) => {
          onChangeHandler(e.target.value)
          setDepartmentId(e.target.value)
          setError(false)
        }}
        name="department-selection"
        onInvalid={() => setError(true)}
      >
        {departments.map((item, index) => (
          <option key={index} value={item.id || ""}>
            {item.department}
          </option>
        ))}
      </select>
      <div
        sx={{
          variant: "form.arrow",
        }}
      ></div>
      <Input
        type="hidden"
        label=""
        name="recipient"
        required={true}
        value={departmentId}
      />
    </div>
  )
}

DepartmentSelect.propTypes = {
  departments: PropTypes.array.isRequired,
  required: PropTypes.bool,
  onChangeHandler: PropTypes.func,
  valueField: PropTypes.string,
}

export default DepartmentSelect
