/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

const Arrow = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <path
      fill={color}
      d="M500 243.05H26.62l135.63-135.63-9.83-9.83L0 250.01l152.42 152.4 9.83-9.83L26.61 256.95H500z"
    />
  </svg>
)

Arrow.propTypes = {
  color: PropTypes.string,
}

export default Arrow
