/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Image from "../image"

const ImageSingle = ({ jumpTo, filename, alt }) => {
  return (
    <div
      id={jumpTo}
      sx={{
        height: "100%",
      }}
    >
      <Image
        image={filename}
        alt={alt}
        objectFit="cover"
        style={{
          display: "block",
          height: "100%",
        }}
      />
    </div>
  )
}

ImageSingle.propTypes = {
  jumpTo: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

export default ImageSingle
