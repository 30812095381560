/** @jsx jsx */
import { jsx } from "theme-ui"

const CloseIcon = ({ style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 500 500"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
  >
    <polygon
      sx={style}
      points="431.7,78.1 421.9,68.3 250,240.2 78.1,68.3 68.3,78.1 240.2,250 68.3,421.9 78.1,431.7 250,259.8 421.9,431.7
    	431.7,421.9 259.8,250 "
    />
  </svg>
)

export default CloseIcon
