/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import PropTypes from "prop-types"

import { useLinkTable } from "../hooks/use-link-table"
import Link, { TransformLinkUri } from "./link"

const Breadcrumb = ({ language, languages, mode, location }) => {
  const { rawColors: colors } = useThemeUI().theme
  const items = location.pathname
    .split("/")
    .filter((item) => item && !languages.includes(item))

  if (items && items.length === 0) {
    return null
  }

  let slug = ""
  const defaultLang = process.env.GATSBY_DEFAULT_LANG
    ? process.env.GATSBY_DEFAULT_LANG
    : "de"

  return (
    <div
      className="breadcrumb"
      sx={{
        variant: "spacingContainer",
        fontSize: 0,
        my: 0,
        "& > span:not(:last-child)::after": {
          content: '">"',
          px: 2,
        },
        "& a": {
          color: colors.text,
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      }}
    >
      {items.map((item, index) => {
        slug = index === 0 ? item : `${slug}/${item}`
        let newSlug = ""

        if (item === "events") {
          const eventsPattern = /.\/events$/

          if (eventsPattern.test(slug)) {
            newSlug = slug
            newSlug = newSlug.split("/").reverse().join("/")
          }
        }
        let checkSlug = newSlug ? newSlug : slug

        const link = useLinkTable(checkSlug)

        if (!link) {
          return null
        }

        let alternate = { name: null, lang: null }

        if (process.env.GATSBY_SINGLE_LANGUAGE !== "true") {
          alternate = link.alternates.find(
            (alternate) => alternate.lang === language
          )
        }

        const name =
          language === defaultLang || !alternate.name
            ? link.name
            : alternate.name
        return (
          <span className={`level-${index}`} key={link.uuid}>
            {items.length - 1 === index ? (
              <span
                sx={{
                  color: colors.modes[mode].primary,
                }}
              >
                {name}
              </span>
            ) : (
              <Link to={TransformLinkUri(link.uuid, null, language)}>
                {name}
              </Link>
            )}
          </span>
        )
      })}
    </div>
  )
}

Breadcrumb.propTypes = {
  language: PropTypes.string.isRequired,
  languages: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
}

export default Breadcrumb
