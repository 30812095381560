/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

const Letter = ({ char }) => {
  return (
    <div
      id={char}
      sx={{
        fontWeight: "bold",
        fontSize: "100px",
        color: "text",
        bg: "#F2F2F2",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {char}
    </div>
  )
}

Letter.propTypes = {
  char: PropTypes.string.isRequired,
}

export default Letter
