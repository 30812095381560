/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { ResponsivePie } from "@nivo/pie"

import { ab } from "../theme"

const PieChart = ({ data, children }) => {
  const getPieDescriptions = (data) => {
    let descrptions = []

    for (var i = 0; i < data.length; i++) {
      let descText = ""
      if (data[i].descrption) {
        descText = <div sx={{ paddingTop: 2 }}>{data[i].descrption}</div>
      }

      descrptions.push(
        <div
          key={i}
          sx={{ position: "relative", paddingLeft: 40, marginTop: 20 }}
        >
          <div
            sx={{
              position: "absolute",
              backgroundColor: data[i].color,
              height: 10,
              width: 30,
              borderRadius: 5,
              top: "5px",
              left: 0,
            }}
          ></div>
          <div>{data[i].label}</div>
          {descText}
        </div>
      )
    }
    return descrptions
  }

  const getColors = (data) => {
    let colors = []
    for (var i = 0; i < data.length; i++) {
      colors.push(data[i].color)
    }
    return colors
  }

  const addIds = (data) => {
    for (var i = 0; i < data.length; i++) {
      data[i]["id"] = "slice_" + i
    }
    return data
  }

  return (
    <div
      sx={{
        variant: "spacingContainer",
      }}
    >
      <div
        sx={{
          display: ab({ _: "block", md: "flex" }),
          color: "text",
        }}
      >
        <div
          sx={{
            fontSize: 1,
            display: ab({ _: "block", md: "none" }),
            paddingBottom: 2,
          }}
        >
          {children}
        </div>
        <div
          sx={{
            flex: 1,
            my: ab({ _: 2, md: 0 }),
            py: ab({ _: 0, md: 3 }),
            position: "relative",
            minHeight: ab({ _: "300px", md: "none" }),
          }}
        >
          <div
            sx={{
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
          >
            <ResponsivePie
              data={addIds(data)}
              margin={{ top: 0, right: 40, bottom: 40, left: 40 }}
              innerRadius={0.9}
              colors={getColors(data)}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              enableRadialLabels={false}
              radialLabel="value"
              radialLabelsSkipAngle={0}
              radialLabelsTextXOffset={6}
              radialLabelsTextColor="#333333"
              radialLabelsLinkOffset={0}
              radialLabelsLinkDiagonalLength={15}
              radialLabelsLinkHorizontalLength={22}
              radialLabelsLinkStrokeWidth={1}
              radialLabelsLinkColor={{ from: "color", modifiers: [] }}
              enableSlicesLabels={false}
              slicesLabelsSkipAngle={10}
              slicesLabelsTextColor="#333333"
              animate={true}
              motionStiffness={90}
              motionDamping={15}
            />
          </div>
        </div>
        <div
          sx={{
            fontSize: 1,
            flex: 1,
            my: 0,
          }}
        >
          <div sx={{ display: ab({ _: "none", md: "block" }) }}>{children}</div>
          {getPieDescriptions(data)}
        </div>
      </div>
    </div>
  )
}

PieChart.propTypes = {
  data: PropTypes.array.isRequired,
  children: PropTypes.node,
}

PieChart.defaultProps = {
  data: [],
}

export default PieChart
