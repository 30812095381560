/** @jsx jsx */
import { jsx } from "theme-ui"

const HUSNewsIcon = ({ style = {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 162.55 162.55"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
  >
    <g id="Ebene_1-2" data-name="Ebene 1">
      <path
        sx={style}
        d="m81.27,0C36.38,0,0,36.38,0,81.27c0,11.34,2.32,22.14,6.51,31.94,1.67,3.89,3.63,7.64,5.87,11.2,3.16,5.05,6.87,9.71,11.03,13.93,6.14,6.23,13.28,11.47,21.16,15.46,1.55.79,3.13,1.52,4.73,2.21l-17.58-38.91h26.99l11.63,44.71c3.58.49,7.22.73,10.93.73,1.2,0,2.4-.03,3.59-.08,8.99-.39,17.59-2.24,25.58-5.31,15.85-6.1,29.31-17.02,38.58-30.96,2.71-4.08,5.06-8.43,7.02-12.98,4.19-9.8,6.51-20.6,6.51-31.94C162.55,36.38,126.16,0,81.27,0Zm-37.24,112.48h-20.49c-3.81,0-6.91-3.13-6.91-6.95v-34.03c0-3.82,3.1-6.92,6.91-6.92h20.49v47.9Zm82.64,12.61c.2,5.81-7.24,9.08-11.51,5.23-14.28-11.54-32.13-17.94-50.32-17.84h-16.19v-47.9h16.19c18.1.06,36.14-6.3,50.19-17.78,2.1-1.84,5.11-2.36,7.66-1.1,2.46,1.16,3.98,3.56,3.98,6.27v73.11Zm4.62-18.43v-36.29c19.49,4.43,19.49,31.87,0,36.29Z"
      />
    </g>
  </svg>
)

export default HUSNewsIcon
