/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, useEffect } from "react"
import PropTypes from "prop-types"

const FieldGroupCheckbox = ({
  label,
  name,
  value,
  checked,
  required,
  options,
}) => {
  const [isChecked, setIsChecked] = useState(!!checked)
  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  useEffect(() => {
    setIsChecked(isChecked)
  }, [isChecked])

  const checkbox = () => (
    <div>
      <input
        sx={{
          variant: "checkbox",
        }}
        type="checkbox"
        value={value}
        name={name}
        id={name}
        checked={isChecked ? "checked" : ""}
        required={required}
        {...options}
        onChange={() => setIsChecked(!isChecked)}
      />
      <span
        className="mark"
        sx={{
          variant: "form.mark",
          top: "16px",
        }}
      ></span>
      <span
        sx={{
          variant: "form.checkmark",
          display: "none",
          top: "19px",
        }}
      ></span>
    </div>
  )

  const labelValueInput = (
    <input type="hidden" name={"label----" + name} value={label} />
  )

  return (
    <div
      sx={{
        position: "relative",
        py: 2,
      }}
    >
      {labelValueInput}
      {checkbox()}

      <label
        htmlFor={name}
        sx={{
          variant: "checkbox.fieldGroupLabel",
          display: "block",
        }}
      >
        {label}
        {required ? " * " : ""}
      </label>
    </div>
  )
}

FieldGroupCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.object,
}

export default FieldGroupCheckbox
