/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui"
import { useContext, useState, useEffect } from "react"

import NavigationContext from "../../context/navigation-context"

import MainNavigationItem from "./main-item"
import LanguageSwitcher from "../language-switcher"
import SearchTriggerConsumer from "../search-trigger-consumer"

import { ab, navigationDesktopWidth } from "../../theme"

const NavigationContainer = ({
  theme,
  items,
  language,
  currentURL,
  languages,
  location,
}) => {
  const { rawColors: colors } = useThemeUI().theme

  const { level } = useContext(NavigationContext)

  const layerWidth = 300
  const layerWidthMd = 225

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(true)
  }, [])

  return (
    <div
      sx={{
        position: ab({ md: "absolute" }),
        top: 0,
        right: 0,
        width: ab({ md: navigationDesktopWidth }),
        variant: "guc.navigation",
      }}
    >
      <nav
        sx={{
          variant: "navigation.nav",

          backgroundColor:
            theme === "gutesiegel"
              ? colors.modes[theme].navigationBackground
              : colors.navigation.background,
          transition: "transform .2s linear",
          transform: ab({
            md: `translate(-${level * layerWidthMd}px)`,
            lg: `translate(-${level * layerWidth}px)`,
          }),
        }}
        className="navigation-container"
      >
        <div
          sx={{
            variant: "navigation.searchTrigger",
            flexGrow: 2,
            flexShrink: 1,
            display:
              theme === "gutesiegel" || theme === "guc"
                ? "none"
                : ab({ _: "none", md: "flex" }),
          }}
          className="search-trigger-container"
        >
          <SearchTriggerConsumer />
        </div>
        <ul
          sx={{
            variant: "navigation.unorderedList",
          }}
        >
          {items[language] &&
            items[language].children.map((item, index) => (
              <li
                key={`main-item-${index}`}
                sx={{
                  flex: 1,
                  position: "relative",
                  maxHeight: ab({ _: "100%", md: "100px" }),
                }}
                className="main-navigation-item"
              >
                {isVisible ? (
                  <MainNavigationItem
                    text={item.displayName}
                    currentPage={item.currentPage}
                    link={item.url.id ? item.url.id : item.url.url}
                    language={language}
                    icon={item.icon}
                    mode={item.mode}
                    index={index}
                    children={item.children}
                    extras={item.extras}
                    location={location}
                    hideParentLink={item.hideParentLink}
                    theme={theme}
                  />
                ) : null}
              </li>
            ))}
        </ul>
        <div
          sx={{
            variant: "navigation.languageSwitcher",
          }}
          className="language-switcher"
        >
          <LanguageSwitcher
            locationText={items[language] && items[language].LocationText}
            locationLink={items[language] && items[language].LocationLink}
            currentLanguage={language}
            allLanguage={languages}
            currentURL={currentURL}
          />
        </div>
      </nav>
    </div>
  )
}

export default NavigationContainer
